import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import { Link } from "react-router-dom";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import AddFeaturesModal from "../Modals/AddFeaturesModal";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import { toast } from "react-toastify";

export default function FeaturesList() {
  const [showFeatureModal, setShowFeatureModal] = useState(false);
  const baseUrl = process.env.REACT_APP_DEV_API_KEY_NEW;
  const user = JSON.parse(localStorage.getItem("profile"));
  const user_id = user?.response?.userDetails?.id;
  const getToken = user?.response?.token;
  const bearer_token = `Bearer ${getToken}`;
  const config = { headers: { Authorization: bearer_token } };
  const [featuresList, setFeatureList] = useState([]);
  const [editableFeature, setEditableFeature] = useState(false);
  const [permissionId, setPermissionId] = useState(null);
  const [loader, setLoader] = useState(true);
  const [dropList, setDropList] = useState([]);


  function getFeatures() {
    setLoader(true);
    let url = baseUrl + "permissionfeaturelist";
    let data = { user_id };
    axios
      .post(url, data, config)
      .then((response) => {
        setLoader(false);
        setFeatureList(response?.data?.response?.FeatureList);
      })
      .catch((error) => {
        setLoader(false);
        toast.error("Something went wrong");
      });
    setEditableFeature(false);
  }

  function getDropDown(value) { 
    setLoader(true);
    let url = baseUrl + "departmentdropdown";
    let data = {...value, user_id }; 
    axios.post(url, data, config)
      .then((response) => {
        setLoader(false);
        if (response.status === 200) {
          setDropList(response?.data?.response?.departmentDropDown); 
        }
      })
      .catch((error) => {
        setLoader(false);
      });
  }

  function editFeature(id) {
    let editData = featuresList.find((e) => e.id === id);
    setPermissionId(editData.id);
    setEditableFeature(editData);
    setShowFeatureModal(true);
  }

  function handleClose() {
    setShowFeatureModal(false);
    setEditableFeature(false);
    setPermissionId(null);
  }
  useEffect(() => {
    getFeatures(); 
    getDropDown({user_id});
  }, []);

  return (
    <>
      
      <div className="page-wrapper">
        <div className="page-content-tab">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div className="page-title-box">
                  <div className="float-end">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        {/* <Link to="#!">Analytics /</Link> */}
                      </li>
                      <li className="breadcrumb-item active">Features</li>
                    </ol>
                  </div>
                  <h4 className="page-title">Manage Features</h4>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 text-end">
                <div className="text-end">
                  <ul className="list-inline">
                    <li className="list-inline-item">
                      <button
                        type="button"
                        className="btn btn-primary btn-sm"
                        onClick={() => setShowFeatureModal(true)}
                      >
                        + Add Features
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-body">
                {loader ? (
                  <div
                    className="d-flex align-items-center justify-content-center"
                    style={{ height: "70vh" }}
                  >
                    <Spinner animation="grow" variant="danger" />
                    <Spinner animation="grow" variant="danger" />
                    <Spinner animation="grow" variant="danger" />
                  </div>
                ) : (
                  <div className="row">
                    <div className="table-responsive">
                      <table className="table table-striped">
                        <thead className="thead-light">
                          <tr>
                            <th>S. No.</th>
                            <th>Permission Group</th>
                            <th>Department</th>
                            <th>Feature</th>
                            <th>Short Code</th>
                            {/* <th>Icon</th> */}
                            <th>Url</th>

                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {featuresList?.map((e, i) => {
                            return (
                              <tr key={e.id}>
                                <td>{i + 1}</td>
                                <td
                                  className="text-capitalize"
                                  style={{ fontWeight: "600" }}
                                >
                                  {e.permissionGroupName}
                                </td> 
                                <td className="text-capitalize">{e.departmentName}</td>
                                <td className="text-capitalize">{e.feature}</td>
                                <td className="">{e.short_code}</td>

                                <td className="">{e.url}</td>
                                <td>
                                  <i
                                    className="las la-pen"
                                    style={{
                                      fontSize: "1.5rem",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => editFeature(e.id)}
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title="Edit Feature"
                                  ></i>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <AddFeaturesModal
        show={showFeatureModal}
        handleClose={handleClose}
        getFeatures={getFeatures}
        editableFeature={editableFeature}
        permissionId={permissionId}
        dropList={dropList}
      />
    </>
  );
}
