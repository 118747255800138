import React, { useEffect, useRef, useState } from "react";
import html2pdf from "html2pdf.js";
import acmelogo from "../../../Assets/acme-logo.png";
import sign from "../../../Assets/Sign.png";
import PurchaseOrder from "./PurchaseOrder";
import { getPurchaseOrderPdfDetail } from "../../purchaseApi";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import numberToWords from "number-to-words";
import { formatDate } from "../../../constant";
const Invoice = () => {
  const [pdfData, setPdfData] = useState();
  const user = JSON.parse(localStorage.getItem("profile"));
  const user_id = user?.response?.userDetails?.id;

  const { POId } = useParams();

  const componentPdf = useRef();

  function PurchaseOrderPdfDetail() {
    getPurchaseOrderPdfDetail({
      user_id,
      purchase_order_id: POId,
    })
      .then((res) => {
        setPdfData(res.data.response.PurchaseOrderDetails);
      })
      .catch((err) => toast.error("Something went wrong"));
  }
  useEffect(() => {
    PurchaseOrderPdfDetail();
  }, []);
  const generatePdf = () => {
    const element = componentPdf.current;
    const address =
      "2nd floor, Plot no.34, Near Aakash Hospital, Sector-3, Dwarka, New Delhi-110075\nEmail : purchase@acmeindia.co Phone No. :91-11-45179113"; // Define your address
    const borderColor = "#000000";
    const reservedHeight = 28;

    html2pdf()
      .from(element)
      .set({
        pagebreak: {
          mode: ["css", "legacy"],
          before: ".beforeClass",
          after: ["#after1", "#after2"],
          avoid: "img",
        },
        margin: [10, 10, 28, 10],
        filename: "invoice.pdf",
        jsPDF: { unit: "mm", format: "a3", orientation: "portrait" },
        html2canvas: { scale: 2, useCORS: true },
      })
      .toPdf()
      .get("pdf")
      .then((pdf) => {
        const totalPages = pdf.internal.getNumberOfPages();

        // Add border and address to each page
        for (let i = 1; i <= totalPages; i++) {
          pdf.setPage(i);
          const pageWidth = pdf.internal.pageSize.getWidth();
          const pageHeight = pdf.internal.pageSize.getHeight();

          // Draw border around the entire page

          pdf.setDrawColor(borderColor); // Set border color
          pdf.rect(10, 10, pageWidth - 20, pageHeight - 15, "S"); // 'S' indicates stroke (border)

          // Add address at the bottom
          pdf.setFontSize(14);
          //  pdf.setTextColor(borderColor); // Set text color to match border
          pdf.text(address, pageWidth / 2, pageHeight - reservedHeight / 2, {
            align: "center",
          });

          console.log("Border and address added to page", i);
        }

        console.log("Border and address added to each page.");
        // Get the Blob URL and open it in a new tab
        const blobUrl = pdf.output("bloburl");
        window.open(blobUrl, "_blank");
      })
      .catch((error) => {
        console.error("Error generating PDF:", error);
      });
    // .save();
  };

  const convertNumberToWordsIndian = (number) => {
    const words = [];

    if (number >= 10000000) {
      const crore = Math.floor(number / 10000000);
      words.push(numberToWords.toWords(crore), "crore");
      number %= 10000000;
    }

    if (number >= 100000) {
      const lakh = Math.floor(number / 100000);
      words.push(numberToWords.toWords(lakh), "lakh");
      number %= 100000;
    }

    if (number >= 1000) {
      const thousand = Math.floor(number / 1000);
      words.push(numberToWords.toWords(thousand), "thousand");
      number %= 1000;
    }

    if (number >= 100) {
      const hundred = Math.floor(number / 100);
      words.push(numberToWords.toWords(hundred), "hundred");
      number %= 100;
    }

    if (number > 0) {
      words.push(numberToWords.toWords(number));
    }

    return words.join(" ");
  };
  const convertNumberToWordsWithCurrency = (number, currency) => {
    if (!isNaN(number) && number > 0) {
      const words = convertNumberToWordsIndian(number);
      const capitalizedWords = words.charAt(0).toUpperCase() + words.slice(1);
      return `${capitalizedWords} ${currency}`;
    } else {
      return "Invalid number";
    }
  };
  return (
    <>
      <div
        style={{
          margin: "0 auto",
          maxWidth: "70rem",
          width: "100%",
          background: "white",
          padding: "0",
        }}
        id="testReportDiv"
        ref={componentPdf}
      >
        <div style={{ marginBottom: "30px" }}>
          <div className="invoic m-0 p-0" id="invoice-content">
            <div className="row g-0">
              <div className="col-12">
                <div
                  className="row g-0"
                  style={{ height: "7.3rem", border: "1px solid black" }}
                >
                  <div className="col-md-4 img-fluid d-flex justify-content-center align-items-center">
                    <img
                      src={acmelogo}
                      style={{ height: "4rem", width: "15rem" }}
                      alt="ACME Logo"
                    />
                  </div>
                  <div className="col-md-8 d-flex justify-content-center align-items-center">
                    <h2
                      className="fw-bold"
                      style={{ textAlign: "center", fontSize: "2.5rem" }}
                    >
                      ACME INDIA INDUSTRIES LTD.
                    </h2>
                  </div>
                </div>
              </div>

              <div
                className="col-12 d-flex justify-content-around align-items-center"
                style={{ border: "1px solid black" }}
              >
                <h5>
                  <strong>PO No :</strong>{" "}
                  <span>{pdfData?.purchase_order_no}</span> &
                  <strong> Dated :</strong>{" "}
                  <span>{formatDate(pdfData?.order_date)}</span>
                </h5>
                <h2 className="fw-bold">PURCHASE ORDER</h2>
              </div>
              <div className="col-7 p-3" style={{ border: "1px solid black" }}>
                <div className="d-flex">
                  <div className="d-flex flex-column">
                    <p className="mb-1 d-block">
                      <strong
                        style={{ display: "inline-block", width: "7rem" }}
                      >
                        Party Code
                      </strong>{" "}
                      <span> : </span>
                      <span>{pdfData?.party_details?.party_code}</span>
                    </p>
                    <p className="mb-1 d-block">
                      <strong
                        style={{ display: "inline-block", width: "7rem" }}
                      >
                        {" "}
                        Party Name
                      </strong>{" "}
                      <span> : </span>
                      <span>{pdfData?.party_details?.party_name}</span>
                    </p>
                    <p className="mb-1 d-block">
                      <strong
                        style={{ display: "inline-block", width: "7rem" }}
                      >
                        {" "}
                        Address
                      </strong>{" "}
                      <span> : </span>
                      <span>{pdfData?.party_details?.address}</span>
                    </p>
                    <p className="mb-1 d-block">
                      <strong
                        style={{ display: "inline-block", width: "7rem" }}
                      >
                        {" "}
                        GST No
                      </strong>{" "}
                      <span> : </span>
                      <span>{pdfData?.party_details?.gst_no}</span>
                    </p>
                    <p className="mb-1 d-block">
                      <strong
                        style={{ display: "inline-block", width: "7rem" }}
                      >
                        Contact Person{" "}
                      </strong>{" "}
                      <span> : </span>
                      <span>{pdfData?.party_details?.contact_person}</span>
                    </p>
                    <p className="mb-1 d-block">
                      <strong
                        style={{ display: "inline-block", width: "7rem" }}
                      >
                        {" "}
                        Mobile No{" "}
                      </strong>{" "}
                      <span> : </span>
                      <span>{pdfData?.party_details?.contact_mobile}</span>
                    </p>
                    <p className="mb-1 d-block">
                      <strong
                        style={{ display: "inline-block", width: "7rem" }}
                      >
                        {" "}
                        MSME{" "}
                      </strong>{" "}
                      <span> : </span>{" "}
                      <span>{pdfData?.party_details?.msme_no} </span>
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-5 p-3" style={{ border: "1px solid black" }}>
                <div className="d-flex gap-3">
                  <div className="d-flex flex-column">
                    <p className="mb-1 d-block">
                      <strong
                        style={{ display: "inline-block", width: "6rem" }}
                      >
                        {" "}
                        Prepared by{" "}
                      </strong>{" "}
                      <span> : </span>{" "}
                      <span>{pdfData?.user_details?.name}</span>
                    </p>
                    <p className="mb-1 d-block">
                      <strong
                        style={{ display: "inline-block", width: "6rem" }}
                      >
                        Insp. Agency{" "}
                      </strong>{" "}
                      <span> : </span>
                      <span> {pdfData?.inspection_agency}</span>
                    </p>
                    {pdfData?.inspection_agency != "Consignee" && (
                      <>
                        <p className="mb-1 d-block">
                          <strong
                            style={{ display: "inline-block", width: "6rem" }}
                          >
                            Railway PO.No{" "}
                          </strong>{" "}
                          <span> : </span>
                          <span> {pdfData?.railway_po_no}</span>
                        </p>
                      </>
                    )}
                    <p className="mb-1 d-block">
                      <strong
                        style={{ display: "inline-block", width: "6rem" }}
                      >
                        Remarks
                      </strong>{" "}
                      <span> : </span> <span> {pdfData?.remarks} </span>
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-7 p-3" style={{ border: "1px solid black" }}>
                <strong className="mb-1">Bill To :</strong>
                {pdfData?.bill_to_details?.branch_name && (
                  <p className="mb-0">
                    {pdfData?.bill_to_details?.branch_name}
                  </p>
                )}
                {pdfData?.bill_to_details?.address && (
                  <p className="mb-0">
                    {pdfData?.bill_to_details?.address}
                    {pdfData?.bill_to_details?.Zip}
                  </p>
                )}
                <p className="mb-0">
                  GSTIN NUMBER : {pdfData?.bill_to_details?.GSTNO}
                </p>
              </div>
              <div className="col-5 p-3" style={{ border: "1px solid black" }}>
                <strong>Ship To :</strong>
                {pdfData?.store_details?.store_name && (
                  <p className="mb-1">{pdfData?.store_details?.store_name}</p>
                )}
                {pdfData?.store_details?.store_address && (
                  <p className="mb-1">
                    {pdfData?.store_details?.store_address}
                  </p>
                )}
                <p className="mb-1">
                  Contact Person- {pdfData?.store_details?.keeper_name},
                  ContactNo- {pdfData?.store_details?.contact_no}
                </p>
              </div>
              <div
                style={{
                  marginTop: "5px",
                  padding: "0px",
                  marginBottom: "0px",
                }}
              >
                <table
                  style={{
                    borderCollapse: "collapse",
                    width: "100%",
                    marginBottom: "0px",
                  }}
                >
                  <thead>
                    <tr style={{ pageBreakInside: "avoid" }}>
                      <th
                        style={{
                          border: "1px solid black",
                          padding: "0 10px",
                          fontSize: "1rem",
                          width: "2.6rem",
                        }}
                      >
                        S.No.
                      </th>
                      <th
                        style={{
                          border: "1px solid black",
                          padding: "0 10px",
                          fontSize: "1rem",
                          width: "4.8rem",
                        }}
                      >
                        Item Code
                      </th>
                      <th
                        style={{
                          border: "1px solid black",
                          width: "18rem",
                          padding: "0 10px",
                          fontSize: "1rem",
                          width: "22rem",
                        }}
                      >
                        Item Name
                      </th>
                      <th
                        style={{
                          border: "1px solid black",
                          padding: "0 10px",
                          fontSize: "1rem",
                          width: "5rem",
                        }}
                      >
                        Qty/ Unit
                      </th>
                      <th
                        style={{
                          border: "1px solid black",
                          padding: "0 10px",
                          fontSize: "1rem",
                          width: "6rem",
                        }}
                      >
                        Second Qty
                      </th>
                      <th
                        style={{
                          border: "1px solid black",
                          padding: "0 10px",
                          fontSize: "1rem",
                          width: "5rem",
                        }}
                      >
                        Rate
                      </th>
                      <th
                        style={{
                          border: "1px solid black",
                          padding: "0 10px",
                          fontSize: "1rem",
                          width: "5rem",
                        }}
                      >
                        Total
                      </th>
                      {/* <th
                        style={{
                          border: "1px solid black",
                          padding: "0 10px",
                          fontSize: "1rem",
                          width: "10rem",
                        }}
                      >
                        Delivery Schedule
                      </th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {pdfData?.item_details.map((element, i) => (
                      <tr key={i}>
                        <td style={{ border: "1px solid black" }}>{i + 1}</td>
                        <td
                          style={{
                            border: "1px solid black",
                            overflowWrap: "break-word",
                            padding: "0 8px",
                          }}
                        >
                          {element.item_code}
                        </td>
                        <td
                          style={{
                            border: "1px solid black",
                            overflowWrap: "break-word",
                          }}
                        >
                          {element?.item_master_details?.product_name}{" "}
                          {element?.item_description &&
                            `,${element?.item_description}`}{" "}
                          {element?.specification &&
                            `,${element?.specification}`}
                        </td>
                        <td
                          style={{
                            border: "1px solid black",
                            overflowWrap: "break-word",
                          }}
                        >
                          {element?.unit_details?.unit_name
                            ? `${element.qty} / ${element?.unit_details?.unit_name}`
                            : element.qty}
                        </td>
                        <td
                          style={{
                            border: "1px solid black",
                            overflowWrap: "break-word",
                          }}
                        >
                          {element?.unit_second_details?.unit_name
                            ? `${element.second_qty} / ${element?.unit_second_details?.unit_name}`
                            : element.second_qty}
                        </td>
                        <td
                          style={{
                            border: "1px solid black",
                            overflowWrap: "break-word",
                          }}
                        >
                          {element.rate}
                        </td>
                        <td
                          style={{
                            border: "1px solid black",
                            overflowWrap: "break-word",
                          }}
                        >
                          {Number(element.qty) * Number(element.rate)}
                        </td>
                        {/* <td
                          style={{
                            border: "1px solid black",
                            overflowWrap: "break-word",
                          }}
                        >
                          {element?.schedule_data?.map((element) => {
                            return (
                              <>
                                <p className="mb-0"
                                  style={{
                                    fontWeight: "700",
                                    fontSize: ".8rem",
                                    color: " black",
                                  }}
                                >
                                  {formatDate(element?.schedule_date)}
                                </p>
                                <p className="mb-0"
                                  style={{
                                    fontWeight: "700",
                                    fontSize: ".8rem",
                                    color: " black",
                                  }}>
                                  Qty - {element?.quantity}
                                </p>
                              </>
                            );
                          })}
                        </td> */}
                      </tr>
                    ))}
                  </tbody>
                </table>
                <table
                  border="0"
                  cellpadding="0"
                  cellspacing="0"
                  align="center"
                  style={{ marginBottom: "0" }}
                >
                  <tbody>
                    <tr>
                      <td
                        style={{
                          fontSize: "1rem",
                          fontFamily: "'Open Sans', sans-serif",
                          color: "black",
                          lineHeight: "22px",
                          verticalAlign: "top",
                          textAlign: "right",
                          borderBottom: "1px solid gray", // Add bottom border
                          borderRight: "1px solid gray", // Add right border
                          fontWeight: 700,
                        }}
                      >
                        Sub Total
                      </td>
                      <td
                        style={{
                          fontSize: "1rem",
                          fontFamily: "'Open Sans', sans-serif",
                          color: "black",
                          lineHeight: "22px",
                          verticalAlign: "top",
                          textAlign: "right",
                          whiteSpace: "nowrap",
                          borderBottom: "1px solid gray", // Add bottom border
                          fontWeight: 700,
                        }}
                        width="208"
                      >
                        ₹
                        {pdfData?.item_details.reduce(
                          (accumulator, element) =>
                            Number(accumulator) +
                            Number(element.qty * element.rate),
                          0
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          fontSize: "1rem",
                          fontFamily: "'Open Sans', sans-serif",
                          color: "black",
                          lineHeight: "22px",
                          verticalAlign: "top",
                          textAlign: "right",
                          borderBottom: "1px solid gray", // Add bottom border
                          borderRight: "1px solid gray", // Add right border
                          fontWeight: 700,
                        }}
                      >
                        Discount
                      </td>
                      <td
                        style={{
                          fontSize: "1rem",
                          fontFamily: "'Open Sans', sans-serif",
                          color: "black",
                          lineHeight: "22px",
                          verticalAlign: "top",
                          textAlign: "right",
                          borderBottom: "1px solid gray", // Add bottom border
                          fontWeight: 700,
                        }}
                      >
                        -(₹
                        {pdfData?.item_details.reduce(
                          (accumulator, element) =>
                            Number(accumulator) + Number(element.disc_amt),
                          0
                        )}
                        )
                      </td>
                    </tr>
                    {pdfData?.gst_grouped_totals?.map((gst, gstIndex) => (
                      <tr>
                        <td
                          style={{
                            fontSize: "1rem",
                            fontFamily: "'Open Sans', sans-serif",
                            color: "black",
                            lineHeight: "22px",
                            verticalAlign: "top",
                            textAlign: "right",
                            borderBottom: "1px solid gray", // Add bottom border
                            borderRight: "1px solid gray", // Add right border
                            fontWeight: 700,
                          }}
                        >
                          GST @{gst?.gst} %
                        </td>
                        <td
                          style={{
                            fontSize: "1rem",
                            fontFamily: "'Open Sans', sans-serif",
                            color: "black",
                            lineHeight: "22px",
                            verticalAlign: "top",
                            textAlign: "right",
                            borderBottom: "1px solid gray", // Add bottom border
                            fontWeight: 700,
                          }}
                        >
                          {gst?.total_amount}
                        </td>
                      </tr>
                    ))}

                    <tr>
                      <td
                        style={{
                          fontSize: "1rem",
                          fontFamily: "'Open Sans', sans-serif",
                          color: "black",
                          lineHeight: "22px",
                          verticalAlign: "top",
                          textAlign: "right",
                          borderBottom: "1px solid gray", // Add bottom border
                          borderRight: "1px solid gray", // Add right border
                          fontWeight: 700,
                        }}
                      >
                        <strong>Grand Total (Incl.Tax)</strong>
                      </td>
                      <td
                        style={{
                          fontSize: "1rem",
                          fontFamily: "'Open Sans', sans-serif",
                          color: "#000",
                          lineHeight: "22px",
                          verticalAlign: "top",
                          textAlign: "right",
                          borderBottom: "1px solid gray", // Add bottom border
                          fontWeight: 700,
                        }}
                      >
                        <strong>
                          ₹
                          {Number(
                            pdfData?.item_details.reduce(
                              (accumulator, element) =>
                                Number(accumulator) +
                                Number(element.qty * element.rate),
                              0
                            )
                          ) +
                            Number(
                              pdfData?.item_details.reduce(
                                (accumulator, element) =>
                                  Number(accumulator) + Number(element.gst_amt),
                                0
                              ) -
                                Number(
                                  pdfData?.item_details.reduce(
                                    (accumulator, element) =>
                                      Number(accumulator) +
                                      Number(element.disc_amt),
                                    0
                                  )
                                )
                            )}
                        </strong>
                      </td>
                    </tr>

                    <tr>
                      <td
                        style={{
                          fontSize: "1rem",
                          fontFamily: "'Open Sans', sans-serif",
                          color: "black",
                          lineHeight: "22px",
                          verticalAlign: "top",
                          textAlign: "left",
                          borderBottom: "1px solid #gray", // Add bottom border
                          fontWeight: 700,
                        }}
                      >
                        <strong>
                          Amount In Words Rupees:
                          {convertNumberToWordsWithCurrency(
                            Number(
                              pdfData?.item_details.reduce(
                                (accumulator, element) =>
                                  Number(accumulator) +
                                  Number(element.qty * element.rate),
                                0
                              )
                            ) +
                              Number(
                                pdfData?.item_details.reduce(
                                  (accumulator, element) =>
                                    Number(accumulator) +
                                    Number(element.gst_amt),
                                  0
                                ) -
                                  Number(
                                    pdfData?.item_details.reduce(
                                      (accumulator, element) =>
                                        Number(accumulator) +
                                        Number(element.disc_amt),
                                      0
                                    )
                                  )
                              ),
                            "rupees"
                          )}
                        </strong>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <table
                  style={{
                    borderCollapse: "collapse",
                    width: "100%",
                    marginBottom: "0",
                  }}
                >
                  <thead>
                    <tr style={{ pageBreakInside: "avoid" }}>
                      <th colSpan="5" className="text-center">
                        {" "}
                        <h4 className="m-0">Delivery&nbsp;Schedule</h4>{" "}
                      </th>
                    </tr>
                  </thead>
                </table>
                <table
                  style={{
                    borderCollapse: "collapse",
                    width: "100%",
                    marginBottom: "0",
                  }}
                >
                  <thead>
                    <tr style={{ pageBreakInside: "avoid" }}>
                      <th
                        style={{
                          border: "1px solid black",
                          padding: "0 10px",
                          fontSize: "1rem",
                          width: "3.5rem",
                        }}
                      >
                        S.No.
                      </th>
                      <th
                        style={{
                          border: "1px solid black",
                          padding: "0 10px",
                          fontSize: "1rem",
                          width: "8rem",
                        }}
                      >
                        Order Qty
                      </th>
                      <th
                        style={{
                          border: "1px solid black",
                          padding: "0 10px",
                          fontSize: "1rem",
                          width: "9rem",
                        }}
                      >
                        SCHEDULE QTY
                      </th>
                      <th
                        style={{
                          border: "1px solid black",
                          padding: "0 10px",
                          fontSize: "1rem",
                          width: "10rem",
                        }}
                      >
                        SCHEDULE DATE
                      </th>
                      <th
                        style={{
                          border: "1px solid black",
                          padding: "0 10px",
                          fontSize: "1rem",
                        }}
                      >
                        Remarks
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {pdfData?.item_details.map((element, i) => (
                      <tr key={i}>
                        <td
                          style={{ border: "1px solid black", width: "2rem" }}
                        >
                          {i + 1}
                        </td>
                        <td
                          style={{
                            border: "1px solid black",
                            overflowWrap: "break-word",
                          }}
                        >
                          {element?.unit_details?.unit_name
                            ? `${element.qty} / ${element?.unit_details?.unit_name}`
                            : element.qty}
                        </td>
                        <td
                          style={{
                            border: "1px solid black",
                            overflowWrap: "break-word",
                          }}
                        >
                          {element?.schedule_data?.map((element) => {
                            return (
                              <>
                                <p
                                  className="m-0 fs-6"
                                  style={{ color: "black" }}
                                >
                                  Qty - {element?.quantity}
                                </p>
                              </>
                            );
                          })}
                        </td>
                        <td
                          style={{
                            border: "1px solid black",
                            overflowWrap: "break-word",
                          }}
                        >
                          {element?.schedule_data?.map((element) => {
                            return (
                              <>
                                <p
                                  className="m-0 fs-6"
                                  style={{ color: "black" }}
                                >
                                  {formatDate(element?.schedule_date)}
                                </p>
                              </>
                            );
                          })}
                        </td>
                        <td
                          style={{
                            border: "1px solid black",
                            overflowWrap: "break-word",
                          }}
                        >
                          {element?.schedule_data?.map((element) => {
                            return (
                              <>
                                <p
                                  className="m-0 fs-6"
                                  style={{ color: "black" }}
                                >
                                  {element?.remarks}
                                </p>
                              </>
                            );
                          })}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="d-flex align-items-end">
                <div
                  style={{ marginTop: 0 }}
                  className="w-75 border-end border-dark px-2"
                >
                  <h4 className="fw-bold">Terms And Conditions:</h4>
                  {pdfData?.terms_and_conditions_names?.map((element, i) => (
                    <p className="mb-0" style={{ color: "black" }}>
                      {i + 1}. {element?.title}: {element?.description}
                    </p>
                  ))}
                </div>
                <div className="d-flex justify-content-end ps-2">
                  <div className="mt-3 text-center">
                    <h4 className="fw-bold">ACME INDIA INDUSTRIES LTD.</h4>
                    <img
                      className="my-4 text-center"
                      src={sign}
                      style={{
                        height: "4rem",
                        width: "13rem",
                        float: "center",
                        marginLeft: "8rem",
                      }}
                      alt="Sign"
                    />
                    <h4 className="text-center">Authorised Signatory</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="text-center mt-3 mb-4">
        <button className="btn-danger btn" type="button" onClick={generatePdf}>
          Generate PDF
        </button>
      </div>
    </>
  );
};

export default Invoice;
