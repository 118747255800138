import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import { errorsMessage } from "../../../constant";
import { getSupplierPaymentApi } from "../../purchaseApi";
import CommonTable from "../../Common/CommonTable";
import CommonTd from "../../../Tender/Common/CommonTd";
import MakePaymentModal from "./MakePaymentModal";
import QuaterFilter from "../../../common/QuaterFilter";
import { getCurrentQuarter } from "../../../services/constant-variable";
import PaymentLogsModal from "./PaymentLogsModal";
import { IconButton } from "@mui/material";
import { IoChevronForwardSharp } from "react-icons/io5";

function SupplierPaymentDetailPage() {
  const [loading, setLoading] = useState(false);
  const [supplierPaymentData, setSupplierPaymentData] = useState([]);
  const [show, setShow] = useState(false);
  const [showLogsModal, setShowLogsModal] = useState(false);
  const userProfile = JSON.parse(localStorage.getItem("profile"));
  const user_id = userProfile?.response?.userDetails?.id;
  const [dueAmount, setDueAmount] = useState(0);
  const [paymentId, setPaymentId] = useState(null);

  const [filters, setFilters] = useState({
    startDate: "",
    endDate: "",
    selectedYear: "",
    selectedQuarter: "",
  });

  function supplierPaymentList() {
    setLoading(true);
    getSupplierPaymentApi({
      user_id,
      startDate: filters?.startDate,
      endDate: filters?.endDate,
    })
      .then((res) => {
        setSupplierPaymentData(res?.data?.response?.listSupplierPayment);
      })
      .catch((err) => {
        errorsMessage(err?.response?.status);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  const tableHeading = [
    {
      name: "S.No.",
      width: "10rem",
    },
    {
      name: "GRN Date",
      width: "10rem",
    },
    {
      name: "GRN No.",
      width: "10rem",
    },

    {
      name: "PO Number",
      width: "10rem",
    },

    {
      name: "GRN Amount",
      width: "10rem",
    },
    {
      name: "Due Amount",
      width: "10rem",
    },
    {
      name: "Days Remaining",
      width: "10rem",
    },
    {
      name: "Days Past",
      width: "10rem",
    },
    {
      name: "Status",
      width: "10rem",
    },
  ];
  useEffect(() => {
    supplierPaymentList();
  }, [filters]);

  useEffect(() => {
    const currentDate = new Date();
    const currentQuarter = getCurrentQuarter(currentDate);
    setFilters({
      ...filters,
      selectedYear: currentDate.getFullYear(),
      selectedQuarter: currentQuarter,
    });
  }, []);

  return (
    <div>
      <div className="page-wrapper">
        <div className="page-content-tab responsive-tab">
          <div className="container-fluid">
            <div className="row mx-3 my-3">
              <div className="col-md-3">
                <h3>SUPPLIER PAYMENT DETAIL</h3>
              </div>
            </div>

            {loading ? (
              <div
                className="d-flex align-items-center justify-content-center pb-5"
                style={{ height: "70vh" }}
              >
                <Spinner animation="grow" variant="danger" />
                <Spinner animation="grow" variant="danger" />
                <Spinner animation="grow" variant="danger" />
              </div>
            ) : (
              <>
                <div class="col-md-12">
                  <div className="card">
                    <div className="card-body">
                      <div className="table-responsive">
                        <div className="table">
                          <CommonTable
                            tableHeading={tableHeading}
                          ></CommonTable>

                          <tbody>
                            {supplierPaymentData?.map((element, index) => (
                              <tr key={index}>
                                <CommonTd
                                  width="10rem"
                                  value={index + 1}
                                ></CommonTd>
                                <CommonTd
                                  width="10rem"
                                  value={element?.party_name}
                                ></CommonTd>
                                <CommonTd
                                  width="10rem"
                                  value={element?.party_code}
                                ></CommonTd>
                                <CommonTd
                                  width="10rem"
                                  value={element?.payable_amount}
                                ></CommonTd>

                                <td>
                                  <h6
                                    style={{
                                      minWidth: "10rem",
                                      maxWidth: "10rem",
                                      cursor: "pointer",
                                      color:
                                        element.days_past < 25
                                          ? "black" // Default color if less than 25
                                          : element.days_past <= 45
                                          ? "orange" // Orange if between 25 and 45
                                          : "red", // Red if greater than 45
                                    }}
                                  >
                                    {element.days_past}
                                  </h6>
                                </td>
                                <td>
                                  {Number(element.total_due_amount) < 1 ? (
                                    <h6
                                      style={{
                                        minWidth: "10rem",
                                        maxWidth: "10rem",
                                      }}
                                    >
                                      Payment Completed
                                    </h6>
                                  ) : (
                                    <h6
                                      style={{
                                        minWidth: "10rem",
                                        maxWidth: "10rem",
                                        cursor: "pointer",
                                        color: "blue",
                                      }}
                                      onClick={() => {
                                        setShow(true);
                                        setDueAmount(element?.total_due_amount);
                                        setPaymentId(element?.payment_id);
                                      }}
                                    >
                                      Make Payment
                                    </h6>
                                  )}
                                </td>
                                <td>
                                  <h6
                                    style={{
                                      minWidth: "10rem",
                                      maxWidth: "10rem",
                                      cursor: "pointer",
                                      color: "blue",
                                    }}
                                    onClick={() => {
                                      setShowLogsModal(true);
                                      setPaymentId(element?.payment_id);
                                    }}
                                  >
                                    View Logs
                                  </h6>
                                </td>
                                <CommonTd
                                  width="10rem"
                                  value={element?.is_msme_check}
                                ></CommonTd>
                                <CommonTd
                                  width="10rem"
                                  value={element?.msme_no}
                                ></CommonTd>
                              </tr>
                            ))}
                          </tbody>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
            <div className="text-end mt-2">
              {/* <BasicPagination
              handlePaginateChange={handlePaginateChange}
              page={page}
              pageCount={pageCount}
            /> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SupplierPaymentDetailPage;
