import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { EditprojectDetailSchema } from "../../../schemas/newProjectSchema";
import {
  selectDropdowns,
  getdropdownError,
} from "../../../redux/features/allDropdownSlice";
import { useSelector } from "react-redux";
import DpExtensionLogsModal from "./DpExtensionLogsModal";
import LogsTableOnHover from "./LogsTableOnHover";
import OffcanvasComponent from "./OffCanvas";
import UpdateLotsLogs from "./UpdateLotsLogs";
import { CiShare1 } from "react-icons/ci";

function EditProjectDetailForm({ changeKey }) {
  const apiurl = process.env.REACT_APP_DEV_API_KEY_NEW;
  const user = JSON.parse(localStorage.getItem("profile"));
  const user_id = user?.response?.userDetails?.id;
  const param = useParams();
  const getToken = JSON.parse(localStorage.getItem("profile"))?.response?.token;
  const bearer_token = `Bearer ${getToken}`;
  const [tenderDoc, setTenderDoc] = useState("");
  const [loaDoc, setLoaDoc] = useState("");
  const [subdivisionwithzone, setsubdivisionwithzone] = useState([]);
  const [spinner, setSpinner] = useState(false);
  const attachmentBaseUrl = process.env.REACT_APP_BASE_URL;
  const [validationError, setValidationError] = useState({});
  const [tenderDocName, setTenderDocName] = useState("");
  const [loaDocName, setLoaDocName] = useState("");
  const [completionCertificateDocName, setCompletionCertificateDocName] =
    useState("");
  const [completionCertificateDoc, setCompletionCertificateDoc] = useState("");
  const [nocDocName, setNocDocName] = useState("");
  const [nocDoc, setNocDoc] = useState("");
  const [open, setOpen] = React.useState(false);
  const [logsData, setLogsData] = useState({ data: [], index: 1 });
  const [pd_totalOrderValue, setPd_totalOrderValue] = useState("");
  const [pd_totalOrderQty, setPd_totalOrderQty] = useState("");
  const [projectData, setProjectData] = useState("");
  const [show, setShow] = useState(false);
  const [type, setType] = useState("correction");
  const [attachmentName, setAttachmentName] = useState({
    amended_documents: [],
  });

  const navigate = useNavigate("/");

  // Get DropDowns Value
  const dropdowns = useSelector(selectDropdowns);
  const dropdownErrors = useSelector(getdropdownError);

  const [pd_poloaQuantityMutipleData, setPd_poloaQuantityMutipleData] =
    useState([
      {
        poLoaQty: "",
        unit: "",
        startDate: "",
        completionDate: "",
        dpExtension: "",
      },
    ]);

  const initialValues = {
    pd_fileNo: "",
    pd_railwayZone: "",
    pd_subDivision: "",
    pd_workStatus: "",
    pd_workStatusRemark: "",
    pd_tenderNo: "",
    pd_tenderDate: "",
    pd_tenderQuantity: "",
    pd_tenderDocs: "",
    pd_poloaNo: "",
    pd_poloaDate: "",
    pd_totalOrderValue: "",
    pd_poloaDocs: "",
    pd_projectTitle: "",
    pd_projectItemDescription: "",
    pd_InspectionAgency: "",
    pd_totalOrderQty: "",
    pd_projectManager: "",
    pd_purchaseManager: "",
    pd_endDate: "",
    pd_startDate: "",
    pd_poloaQuantityMutipleData: pd_poloaQuantityMutipleData,
    pd_completionCertificate: "",
    pd_completionDate: "",
    pd_noc: "",
    amended_documents: [],
  };

  function getProjectDetail() {
    let url = apiurl + "getuntiprojectfirst";
    let data = { user_id, project_id: param.id };
    axios
      .post(url, data, {
        headers: {
          Authorization: bearer_token,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then(async (response) => {
        if (response.status === 200) {
          if (
            response?.data?.response?.projectFirst.pd_poloaQuantityMutipleData
              .length
          ) {
            setPd_poloaQuantityMutipleData(
              response?.data?.response?.projectFirst.pd_poloaQuantityMutipleData
            );
          }
          let data = await response?.data?.response?.projectFirst;
          setProjectData(data);
          setValues({
            ...data,
            pd_completionCertificate: "",
            pd_noc: "",
            amended_documents: [],
          });
          setPd_totalOrderValue(data.pd_totalOrderValue);
          setPd_totalOrderQty(data.pd_totalOrderQty);
        }
      })
      .catch((error) => {
        toast.error("Something went wrong, Please try again after sometime");
        navigate("/Projectsproject");
      });
  }

  const POLOADocsChange = (e) => {
    const data = new FileReader();
    data.addEventListener("load", () => {
      setLoaDoc(data.result);
      setFieldValue("pd_poloaDocs", data.result);
    });
    data.readAsDataURL(e.target.files[0]);
    setLoaDocName(e.target.files[0].name);
  };

  const CompletionCertificateDocsChange = (e) => {
    const data = new FileReader();
    data.addEventListener("load", () => {
      setCompletionCertificateDoc(data.result);
      setFieldValue("pd_completionCertificate", data.result);
    });
    data.readAsDataURL(e.target.files[0]);
    setCompletionCertificateDocName(e.target.files[0].name);
  };

  const NOCDocsChange = (e) => {
    const data = new FileReader();
    data.addEventListener("load", () => {
      setNocDoc(data.result);
      setFieldValue("pd_noc", data.result);
    });
    data.readAsDataURL(e.target.files[0]);
    setNocDocName(e.target.files[0].name);
  };

  const TenderDocsChange = (e) => {
    const data = new FileReader();
    data.addEventListener("load", () => {
      setTenderDoc(data.result);
      setFieldValue("pd_tenderDocs", data.result);
    });
    data.readAsDataURL(e.target.files[0]);
    setTenderDocName(e.target.files[0].name);
  };

  const handleMultDelete = (i) => {
    const deleteVal = [...pd_poloaQuantityMutipleData];
    deleteVal.splice(i, 1);
    setPd_poloaQuantityMutipleData(deleteVal);
    setValues({ ...values, pd_poloaQuantityMutipleData: deleteVal });
  };

  const handleMltClick = () => {
    setPd_poloaQuantityMutipleData([
      ...pd_poloaQuantityMutipleData,
      {
        poLoaQty: "",
        unit: "",
        startDate: "",
        completionDate: "",
        dpExtension: "",
        project_id: param.id,
      },
    ]);
    setValues({
      ...values,
      pd_poloaQuantityMutipleData: [
        ...values.pd_poloaQuantityMutipleData,
        {
          poLoaQty: "",
          unit: "",
          startDate: "",
          completionDate: "",
          dpExtension: "",
          id: "",
          project_id: param.id,
        },
      ],
    });
  };

  const submitProjectDetails = (value) => {
    setSpinner(true);
    values.pd_poloaDocs = loaDoc
      ? loaDoc
      : values.pd_poloaDocs.startsWith("http")
      ? values.pd_poloaDocs
      : attachmentBaseUrl + values.pd_poloaDocs;

    const data = {
      ...value,
      user_id,
      project_id: param.id,
      pd_tenderDocs: "",
    };
    const getToken = user.response.token;
    const bearer_token = `Bearer ${getToken}`;
    axios
      .post(apiurl + "updateuntiprojectfirst", data, {
        headers: {
          Authorization: bearer_token,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setSpinner(false);
        if (response.data.success) {
          toast.success("Project Details Updated Successfully");
          changeKey("1");
          getProjectDetail();
        }
      })
      .catch((error) => {
        setSpinner(false);
        if (error.response.data.message === "Error validation") {
          setValidationError(error.response.data.response);
        }
        toast.error(
          "Something Went wrong , Please try again after few moments"
        );
      });
  };

  const amendedField = (name, value, type) => {
    setSpinner(true);
    const data = {
      [name]: value,
      project_id: param.id,
      type,
    };
    const getToken = user.response.token;
    const bearer_token = `Bearer ${getToken}`;
    axios
      .post(apiurl + "update-unti-project-first-amendment", data, {
        headers: {
          Authorization: bearer_token,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setSpinner(false);
        if (response.data.success) {
          toast.success("Details Updated Successfully");
          getProjectDetail();
          if (name === "pd_totalOrderQty") {
            setShow(true);
            setType(type);
          }
        }
      })
      .catch((error) => {
        setSpinner(false);
        if (error.response.data.message === "Error validation") {
          setValidationError(error.response.data.response);
        }
        toast.error(
          "Something Went wrong , Please try again after few moments"
        );
      });
  };

  const amendedLogs = (type) => {
    setSpinner(true);
    const data = {
      updated_lot_data: values.pd_poloaQuantityMutipleData,
      project_id: param.id,
      type,
    };
    const getToken = user.response.token;
    const bearer_token = `Bearer ${getToken}`;
    axios
      .post(apiurl + "add-lot-amendment-logs", data, {
        headers: {
          Authorization: bearer_token,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setSpinner(false);
        if (response.data.success) {
          toast.success("Details Updated Successfully");
          getProjectDetail();
        }
      })
      .catch((error) => {
        setSpinner(false);
        if (error.response.data.message === "Error validation") {
          setValidationError(error.response.data.response);
        }
        toast.error(
          "Something Went wrong , Please try again after few moments"
        );
      });
  };

  //multiple attachent upload Function

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);

    const filePromises = files.map((file) => {
      return readFileAsBase64(file).then((base64) => ({
        name: file.name,
        data: base64,
      }));
    });

    Promise.all(filePromises).then((filePairs) => {
      const existingPairs = values.amended_documents || [];
      const updatedPairs = [...existingPairs, ...filePairs];

      const updatedAttachmentList = updatedPairs.map(({ data }) => data);
      const updatedAttachmentNameList = updatedPairs.map(({ name }) => name);
      setFieldValue("amended_documents", updatedPairs);
      setAttachmentName({
        name: updatedAttachmentNameList,
        data: updatedAttachmentList,
      });
    });
  };

  // Helper function to read files as Base64
  const readFileAsBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  };

  function removeAttachment(i, key) {
    const updatedNameList = { ...attachmentName };
    updatedNameList[key] = updatedNameList[key].filter(
      (_, index) => index !== i
    );
    setAttachmentName(updatedNameList);
    const updatedvalues = { ...values };
    updatedvalues[key] = updatedvalues[key].filter((_, index) => index !== i);
    setValues(updatedvalues);
  }

  const ZoneChange = () => {
    const filtersubDivisionList = dropdowns?.subDivisionList?.filter(
      (subDivision) =>
        Number(subDivision.railway_zone) === Number(values.pd_railwayZone)
    );
    setsubdivisionwithzone(filtersubDivisionList);
  };

  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
    resetForm,
    setFieldTouched,
    setValues,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: EditprojectDetailSchema,
    onSubmit: (values) => {
      submitProjectDetails(values);
    },
  });
  useEffect(() => {
    getProjectDetail();
  }, []);

  useEffect(() => {
    if (values.pd_railwayZone) {
      ZoneChange();
    }
  }, [values.pd_railwayZone, dropdowns]);

  useEffect(() => {
    if (dropdownErrors) {
      toast.error("Unable to load the dropdown. Please try again.");
    }
  }, [dropdownErrors]);

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-md-3 col-sm-6">
            <div className="form-group mb-3">
              <label htmlFor="" className="form-label fw-bold">
                Tender No<span className="text-danger">*</span>
              </label>
              <input
                type="text"
                className="form-control border-secondary "
                id="pd_tenderNo"
                name="pd_tenderNo"
                placeholder="Enter tender no."
                value={values.pd_tenderNo}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled
              />
              <p className="text-danger">
                {errors.pd_tenderNo && touched.pd_tenderNo
                  ? errors.pd_tenderNo
                  : validationError?.pd_tenderNo}
              </p>
            </div>
          </div>
          <div className="col-md-3 col-sm-6">
            <div className="form-group mb-3">
              <label htmlFor="" className="form-label fw-bold">
                File No<span className="text-danger">*</span>
              </label>
              <input
                type="text"
                className="form-control border-secondary "
                id="pd_fileNo"
                name="pd_fileNo"
                placeholder="Enter file no."
                value={values.pd_fileNo}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <p className="text-danger">
                {errors.pd_fileNo && touched.pd_fileNo
                  ? errors.pd_fileNo
                  : validationError?.pd_fileNo}
              </p>
            </div>
          </div>
          <div className="col-md-3 col-sm-6">
            <div className="form-group mb-3">
              <label htmlFor="" className="form-label fw-bold">
                Railway Zone<span className="text-danger">*</span>
              </label>
              <select
                className="form-control border-secondary "
                id="pd_railwayZone"
                name="pd_railwayZone"
                value={values.pd_railwayZone}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled
              >
                <option value="">Select</option>
                {dropdowns?.railWayZoneList?.map((item, i) => {
                  return (
                    <option value={item.id} key={i}>
                      {item.name}
                    </option>
                  );
                })}
              </select>
              <p className="text-danger">
                {errors.pd_railwayZone && touched.pd_railwayZone
                  ? errors.pd_railwayZone
                  : validationError?.pd_railwayZone}
              </p>
            </div>
          </div>

          <div className="col-md-3 col-sm-6">
            <div className="form-group mb-3">
              <label htmlFor="" className="form-label fw-bold">
                Sub Division<span className="text-danger">*</span>
              </label>
              <select
                className="form-control border-secondary "
                id="pd_subDivision"
                name="pd_subDivision"
                value={values.pd_subDivision}
                onChange={handleChange}
                onBlur={handleBlur}
              >
                <option value="">Select</option>
                {subdivisionwithzone?.map((item, i) => {
                  return (
                    <option value={item.id} key={i}>
                      {item.name}
                    </option>
                  );
                })}
              </select>
              <p className="text-danger">
                {errors.pd_subDivision && touched.pd_subDivision
                  ? errors.pd_subDivision
                  : validationError?.pd_subDivision}
              </p>
            </div>
          </div>
          <div className="col-md-12 col-sm-6">
            <div className="form-group mb-3">
              <label htmlFor="" className="form-label fw-bold">
                Work Status Remark
              </label>
              <input
                className="form-control border-secondary "
                id="pd_workStatusRemark"
                name="pd_workStatusRemark"
                placeholder="Enter work status remark"
                value={values.pd_workStatusRemark}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <p className="text-danger">
                {errors.pd_workStatusRemark && touched.pd_workStatusRemark
                  ? errors.pd_workStatusRemark
                  : validationError?.pd_workStatusRemark}
              </p>
            </div>
          </div>
          <div className="col-md-3 col-sm-6">
            <div className="form-group mb-3">
              <label htmlFor="" className="form-label fw-bold">
                Work Status<span className="text-danger">*</span>
              </label>
              <select
                className="form-control border-secondary "
                id="pd_workStatus"
                name="pd_workStatus"
                value={values.pd_workStatus}
                onChange={handleChange}
                onBlur={handleBlur}
              >
                <option value="">Select</option>

                {dropdowns?.workStatus?.map((item, i) => {
                  return (
                    <option value={item.id} key={i}>
                      {item.name}
                    </option>
                  );
                })}
              </select>
              <p className="text-danger">
                {errors.pd_workStatus && touched.pd_workStatus
                  ? errors.pd_workStatus
                  : validationError?.pd_workStatus}
              </p>
            </div>
          </div>

          <div className="col-md-3 col-sm-6">
            <div className="form-group mb-3">
              <label htmlFor="" className="form-label fw-bold">
                Tender Date<span className="text-danger">*</span>
              </label>
              <input
                type="date"
                className="form-control border-secondary "
                id="pd_tenderDate"
                name="pd_tenderDate"
                placeholder=""
                value={values.pd_tenderDate}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled
              />
              <p className="text-danger">
                {errors.pd_tenderDate && touched.pd_tenderDate
                  ? errors.pd_tenderDate
                  : validationError?.pd_tenderDate}
              </p>
            </div>
          </div>
          <div className="col-md-3 col-sm-6">
            <div className="form-group mb-3">
              <div>
                <label htmlFor="" className="form-label fw-bold">
                  Tender Quantity
                </label>
              </div>
              <input
                type="text"
                className="form-control border-secondary "
                id="pd_tenderQuantity"
                name="pd_tenderQuantity"
                placeholder="Enter Tender Quantity"
                value={values.pd_tenderQuantity}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled
              />
              <p className="text-danger">
                {errors.pd_tenderQuantity && touched.pd_tenderQuantity
                  ? errors.pd_tenderQuantity
                  : validationError?.pd_tenderQuantity}
              </p>
            </div>
          </div>

          <div className="col-md-3">
            <div className="d-flex align-items-center gap-3">
              <div className="form-group w-100">
                <label htmlFor="pd_tenderDocs" className="form-label fw-bold">
                  Tender Docs
                </label>
                <input
                  type="file"
                  className="form-control border-secondary "
                  accept="application/pdf,image/*"
                  id="pd_tenderDocs"
                  name="pd_tenderDocs"
                  onChange={TenderDocsChange}
                  onBlur={handleBlur}
                  hidden
                  disabled
                />
                <label
                  className="d-block border form-control border-secondary ps-0"
                  for="pd_tenderDocs"
                  style={{ cursor: "pointer", backgroundColor: "#f1f5fa" }}
                >
                  <span
                    className="text-dark ps-3 pe-1 pt-2 pb-1 me-3"
                    style={{ backgroundColor: "#f1f5fa" }}
                  >
                    <i className="las la-paperclip fs-4"></i>
                  </span>{" "}
                  {tenderDoc
                    ? tenderDocName.slice(0, 20) + "..."
                    : values.pd_tenderDocs
                    ? "Tender Documents"
                    : "No Attachment"}
                </label>
                <p className="text-danger">
                  {errors.pd_tenderDocs && touched.pd_tenderDocs
                    ? errors.pd_tenderDocs
                    : validationError?.pd_tenderDocs}
                </p>
              </div>
            </div>
          </div>

          <div className="col-md-3 col-sm-6">
            <div className="form-group">
              <label htmlFor="" className="form-label fw-bold">
                PO/LOA No.<span className="text-danger">*</span>
              </label>
              <input
                type="text"
                className="form-control border-secondary "
                id="pd_poloaNo"
                name="pd_poloaNo"
                placeholder="Enter PO/LOA Number"
                value={values.pd_poloaNo}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <p className="text-danger">
                {errors.pd_poloaNo && touched.pd_poloaNo
                  ? errors.pd_poloaNo
                  : validationError?.pd_poloaNo}
              </p>
            </div>
          </div>

          <div className="col-md-3 col-sm-6">
            <div className="form-group mb-3">
              <label htmlFor="" className="form-label fw-bold">
                PO/LOA Date.<span className="text-danger">*</span>
              </label>
              <input
                type="date"
                className="form-control border-secondary "
                id="pd_poloaDate"
                name="pd_poloaDate"
                placeholder=""
                value={values.pd_poloaDate}
                onChange={handleChange}
                onBlur={handleBlur}
                
              />
              <p className="text-danger">
                {errors.pd_poloaDate && touched.pd_poloaDate
                  ? errors.pd_poloaDate
                  : validationError?.pd_poloaDate}
              </p>
            </div>
          </div>

          <div className="col-md-3 col-sm-6">
            <div className="form-group">
              <div className="d-flex justify-content-between align-items-center">
                <label htmlFor="" className="form-label fw-bold d-inline-block">
                  Total Order Value<span className="text-danger">*</span>
                </label>
                <span class="d-inline-block">
                  <LogsTableOnHover
                    projectData={projectData?.updatedTotalOrderValueLog}
                    type="value"
                  />
                </span>
              </div>
              <div className="d-flex">
                <input
                  type="text"
                  className="form-control border-secondary "
                  id="pd_totalOrderValue"
                  name="pd_totalOrderValue"
                  placeholder="Enter Total Order Value"
                  value={values.pd_totalOrderValue}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  disabled
                />
                <OffcanvasComponent
                  name="pd_totalOrderValue"
                  currentValue={pd_totalOrderValue}
                  updatedValue={setPd_totalOrderValue}
                  text="Total Order Value"
                  amendedFieldApi={amendedField}
                  spinner={spinner}
                  projectData={projectData?.updatedTotalOrderValueLog}
                  fieldType="value"
                />
              </div>

              <p className="text-danger">
                {errors.pd_totalOrderValue && touched.pd_totalOrderValue
                  ? errors.pd_totalOrderValue
                  : validationError?.pd_totalOrderValue}
              </p>
            </div>
          </div>

          <div className="col-md-3">
            <div className="d-flex align-items-center gap-3">
              <div className="form-group w-100">
                <div className="d-flex justify-content-between align-items-center">
                  <label htmlFor="paymentTerms" className="form-label fw-bold">
                    Total Order Quantity<span className="text-danger">*</span>
                  </label>
                  <span class="d-inline-block">
                    <LogsTableOnHover
                      projectData={projectData?.updatedTotalOrderQtyLog}
                      type="qty"
                    />
                  </span>
                </div>
                <div className="d-flex">
                  <input
                    type="text"
                    className="form-control border-secondary"
                    id=""
                    name="pd_totalOrderQty"
                    value={values.pd_totalOrderQty}
                    placeholder="Enter Total Quantity"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    disabled
                  />
                  <OffcanvasComponent
                    name="pd_totalOrderQty"
                    currentValue={pd_totalOrderQty}
                    updatedValue={setPd_totalOrderQty}
                    text="Total Order Qty"
                    amendedFieldApi={amendedField}
                    spinner={spinner}
                    projectData={projectData?.updatedTotalOrderQtyLog}
                    fieldType="qty"
                  />
                </div>
                <p className="text-danger">
                  {errors.pd_totalOrderQty && touched.pd_totalOrderQty
                    ? errors.pd_totalOrderQty
                    : validationError?.pd_totalOrderQty}
                </p>
              </div>
            </div>
          </div>

          <div className="col-md-3">
            <div className="d-flex align-items-center gap-3">
              <div className="form-group w-100">
                <label htmlFor="paymentTerms" className="form-label fw-bold">
                  PO/LOA Docs<span className="text-danger">*</span>
                </label>
                <input
                  type="file"
                  className="form-control border-secondary "
                  accept="application/pdf,image/*"
                  id="pd_poloaDocs"
                  name="pd_poloaDocs"
                  onChange={POLOADocsChange}
                  onBlur={handleBlur}
                  hidden
                />
                <label
                  className="d-block border form-control border-secondary ps-0"
                  for="pd_poloaDocs"
                  style={{ cursor: "pointer" }}
                >
                  <span
                    className="text-dark px-3 pt-2 pb-1 me-3"
                    style={{ backgroundColor: "#f1f5fa" }}
                  >
                    <i className="las la-paperclip fs-4"></i>
                  </span>{" "}
                  {loaDoc
                    ? loaDocName.slice(0, 20) + "..."
                    : values.pd_poloaDocs
                    ? "PO/LOA Document"
                    : "No Attachment"}
                </label>
                <p className="text-danger">
                  {errors.pd_poloaDocs && touched.pd_poloaDocs
                    ? errors.pd_poloaDocs
                    : validationError?.pd_poloaDocs}
                </p>
              </div>
            </div>
          </div>

          <div className="col-md-3 col-sm-6">
            <div className="form-group mb-3">
              <label htmlFor="" className="form-label fw-bold">
                Project Title<span className="text-danger">*</span>
              </label>
              <input
                type="text"
                className="form-control border-secondary "
                id="pd_projectTitle"
                name="pd_projectTitle"
                placeholder="Enter Project Title"
                value={values.pd_projectTitle}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <p className="text-danger">
                {errors.pd_projectTitle && touched.pd_projectTitle
                  ? errors.pd_projectTitle
                  : validationError?.pd_projectTitle}
              </p>
            </div>
          </div>
          <div className="col-md-3 col-sm-6">
            <div className="form-group mb-3">
              <label htmlFor="" className="form-label fw-bold">
                Project Start Date<span className="text-danger">*</span>
              </label>
              <input
                type="date"
                className="form-control border-secondary "
                id="pd_startDate"
                name="pd_startDate"
                placeholder="Enter Start Date"
                value={values.pd_startDate}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <p className="text-danger">
                {errors.pd_startDate && touched.pd_startDate
                  ? errors.pd_startDate
                  : validationError?.pd_startDate}
              </p>
            </div>
          </div>
          <div className="col-md-3 col-sm-6">
            <div className="form-group mb-3">
              <label htmlFor="" className="form-label fw-bold">
                Project End Date<span className="text-danger">*</span>
              </label>
              <input
                type="date"
                className="form-control border-secondary "
                id="pd_endDate"
                name="pd_endDate"
                placeholder="Enter End Date"
                value={values.pd_endDate}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <p className="text-danger">
                {errors.pd_endDate && touched.pd_endDate
                  ? errors.pd_endDate
                  : validationError?.pd_endDate}
              </p>
            </div>
          </div>

          <div className="col-md-3 col-sm-6">
            <div className="form-group mb-3">
              <label htmlFor="" className="form-label fw-bold">
                Project Category<span className="text-danger">*</span>
              </label>
              <select
                type="text"
                className="form-control border-secondary"
                id="pd_projectCategory"
                name="pd_projectCategory"
                placeholder=""
                value={values.pd_projectCategory}
                onChange={handleChange}
                onBlur={handleBlur}
              >
                <option value="">Select</option>
                {dropdowns?.projectCategoryList?.map((item, i) => {
                  return (
                    <option value={item.id} key={i}>
                      {item.name}
                    </option>
                  );
                })}
              </select>
              {touched.pd_projectCategory && errors.pd_projectCategory ? (
                <p className="text-danger">{errors.pd_projectCategory}</p>
              ) : (
                <p className="text-danger">
                  {validationError?.pd_projectCategory}
                </p>
              )}
            </div>
          </div>
          <div className="col-md-3 col-sm-6">
            <div className="form-group mb-3">
              <label htmlFor="" className="form-label fw-bold">
                Project Manager<span className="text-danger">*</span>
              </label>
              <select
                type="text"
                className="form-control border-secondary"
                id=""
                name="pd_projectManager"
                placeholder=""
                value={values.pd_projectManager}
                onChange={handleChange}
                onBlur={handleBlur}
              >
                <option>Select Project Manager</option>
                {dropdowns?.userList?.map((item, i) => {
                  return (
                    <option value={item.id} key={i}>
                      {item.name}
                    </option>
                  );
                })}
              </select>
              {touched.pd_projectManager && errors.pd_projectManager ? (
                <p className="text-danger">{errors.pd_projectManager}</p>
              ) : (
                <p className="text-danger">
                  {validationError?.pd_projectManager}
                </p>
              )}
            </div>
          </div>
          <div className="col-md-3 col-sm-6">
            <div className="form-group mb-3">
              <label htmlFor="" className="form-label fw-bold">
                Purchase Manager<span className="text-danger">*</span>
              </label>
              <select
                type="text"
                className="form-control border-secondary"
                id=""
                name="pd_purchaseManager"
                value={values.pd_purchaseManager}
                placeholder=""
                onChange={handleChange}
                onBlur={handleBlur}
              >
                <option>Select Purchase Manager</option>
                {dropdowns?.userList?.map((item, i) => {
                  return (
                    <option value={item.id} key={i}>
                      {item.name}
                    </option>
                  );
                })}
              </select>
              {touched.pd_purchaseManager && errors.pd_purchaseManager ? (
                <p className="text-danger">{errors.pd_purchaseManager}</p>
              ) : (
                <p className="text-danger">
                  {validationError?.pd_purchaseManager}
                </p>
              )}
            </div>
          </div>
          <div className="col-md-3 col-sm-6">
            <div className="form-group mb-3">
              <label htmlFor="" className="form-label fw-bold">
                Inspection Agency<span className="text-danger">*</span>
              </label>
              <select
                className="form-control border-secondary "
                id="pd_InspectionAgency"
                name="pd_InspectionAgency"
                value={values.pd_InspectionAgency}
                onChange={handleChange}
                onBlur={handleBlur}
              >
                <option value="">Select</option>
                {dropdowns?.inspectionAgency?.map((item, i) => {
                  return (
                    <option value={item.id} key={i}>
                      {item.name}
                    </option>
                  );
                })}
              </select>
              <p className="text-danger">
                {errors.pd_InspectionAgency && touched.pd_InspectionAgency
                  ? errors.pd_InspectionAgency
                  : validationError?.pd_InspectionAgency}
              </p>
            </div>
          </div>

          <div className="col-md-3 col-sm-6">
            <div className="form-group mb-3">
              <label htmlFor="" className="form-label fw-bold">
                Project Actual Completion Date
              </label>
              <input
                type="date"
                className="form-control border-secondary "
                id="pd_completionDate"
                name="pd_completionDate"
                placeholder=""
                value={values.pd_completionDate}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <p className="text-danger">
                {errors.pd_completionDate && touched.pd_completionDate
                  ? errors.pd_completionDate
                  : validationError?.pd_completionDate}
              </p>
            </div>
          </div>

          <div className="col-md-3">
            <div className="d-flex align-items-center gap-3">
              <div className="form-group w-100">
                <label htmlFor="paymentTerms" className="form-label fw-bold">
                  Completion Certificate
                </label>
                <input
                  type="file"
                  className="form-control border-secondary "
                  accept="application/pdf,image/*"
                  id="pd_completionCertificate"
                  name="pd_completionCertificate"
                  onChange={CompletionCertificateDocsChange}
                  onBlur={handleBlur}
                  hidden
                />
                <label
                  className="d-block border form-control border-secondary ps-0"
                  for="pd_completionCertificate"
                  style={{ cursor: "pointer" }}
                >
                  <span
                    className="text-dark px-3 pt-2 pb-1 me-3"
                    style={{ backgroundColor: "#f1f5fa" }}
                  >
                    <i className="las la-paperclip fs-4"></i>
                  </span>{" "}
                  {completionCertificateDoc
                    ? completionCertificateDocName.slice(0, 20) + "..."
                    : values.pd_completionCertificate
                    ? "Completion Cert."
                    : "No Attachment"}
                </label>
                <p className="text-danger">
                  {errors.pd_completionCertificate &&
                  touched.pd_completionCertificate
                    ? errors.pd_completionCertificate
                    : validationError?.pd_completionCertificate}
                </p>
              </div>
            </div>
          </div>

          <div className="col-md-3">
            <div className="d-flex align-items-center gap-3">
              <div className="form-group w-100">
                <label htmlFor="paymentTerms" className="form-label fw-bold">
                  NOC
                </label>
                <input
                  type="file"
                  className="form-control border-secondary "
                  accept="application/pdf,image/*"
                  id="pd_noc"
                  name="pd_noc"
                  onChange={NOCDocsChange}
                  onBlur={handleBlur}
                  hidden
                />
                <label
                  className="d-block border form-control border-secondary ps-0"
                  for="pd_noc"
                  style={{ cursor: "pointer" }}
                >
                  <span
                    className="text-dark px-3 pt-2 pb-1 me-3"
                    style={{ backgroundColor: "#f1f5fa" }}
                  >
                    <i className="las la-paperclip fs-4"></i>
                  </span>{" "}
                  {nocDoc
                    ? nocDocName.slice(0, 20) + "..."
                    : values.pd_noc
                    ? "NOC Document"
                    : "No Attachment"}
                </label>
                <p className="text-danger">
                  {errors.pd_noc && touched.pd_noc
                    ? errors.pd_noc
                    : validationError?.pd_noc}
                </p>
              </div>
            </div>
          </div>

          <div className="col-md-3">
            <div className="d-flex align-items-center gap-3">
              <div className="form-group w-100">
                <label htmlFor="" className="form-label fw-bold">
                  Amendment docs
                </label>
                <input
                  type="file"
                  className="form-control border-secondary "
                  accept="application/pdf,image/*"
                  id="amended_documents"
                  name="amended_documents"
                  onChange={handleFileChange}
                  onBlur={handleBlur}
                  multiple
                />
                <p className="text-danger">
                  {errors.amended_documents && touched.amended_documents
                    ? errors.amended_documents
                    : validationError?.amended_documents}
                </p>
              </div>
            </div>
          </div>

          <div className="col-md-12 col-sm-6">
            <div className="form-group mb-3">
              <label htmlFor="" className="form-label fw-bold">
                Project Description<span className="text-danger">*</span>
              </label>
              <textarea
                type="text"
                className="form-control border-secondary "
                id="pd_projectItemDescription"
                name="pd_projectItemDescription"
                placeholder="Enter Description"
                value={values.pd_projectItemDescription}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <p className="text-danger">
                {errors.pd_projectItemDescription &&
                touched.pd_projectItemDescription
                  ? errors.pd_projectItemDescription
                  : validationError?.pd_projectItemDescription}
              </p>
            </div>
          </div>
        </div>
        <hr></hr>
        <div className="d-flex align-items-center gap-3 mb-3">
          <h5 className="m-0 d-inline-block font-16 fw-bold">LOT Details</h5>

          <div className="d-inline-block ms-auto">
            <button className="btn btn-primary btn-sm me-3 px-2" type="button">
              <Link
                to={`/projects/edit-project-form/lotsLogs/${param.id}`}
                className="text-white fs-6  "
              >
                View Logs <CiShare1 size={17} />
              </Link>
            </button>
            <UpdateLotsLogs
              values={values}
              touched={touched}
              errors={errors}
              text="Lot Detail"
              validationError={validationError}
              handleMltClick={handleMltClick}
              spinner={spinner}
              setFieldTouched={setFieldTouched}
              dropdowns={dropdowns}
              handleMultDelete={handleMultDelete}
              show={show}
              setShow={setShow}
              type={type}
              setType={setType}
              amendedLogs={amendedLogs}
              setFieldValue={setFieldValue}
            />
          </div>
        </div>
        {values?.pd_poloaQuantityMutipleData?.map((val, i) => (
          <div className="row" key={i}>
            <div className="col-md-2 col-sm-6">
              <div className="form-group mb-3">
                <label htmlFor="" className="form-label fw-bold">
                  PO/LOA Quantity<span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  className="form-control border-secondary "
                  placeholder="Enter PO/LOA Quantity"
                  id="poLoaQty"
                  name={`pd_poloaQuantityMutipleData[${i}].poLoaQty`}
                  value={values.pd_poloaQuantityMutipleData[i].poLoaQty}
                  disabled
                  onChange={(e) => {
                    const newValues = [...values.pd_poloaQuantityMutipleData];
                    newValues[i].poLoaQty = e.target.value;
                    setFieldValue(`pd_poloaQuantityMutipleData`, newValues);
                  }}
                  onBlur={(e) => {
                    setFieldTouched(
                      `pd_poloaQuantityMutipleData[${i}].poLoaQty`,
                      true
                    );
                  }}
                />
                <p className="text-danger m-0">
                  {touched.pd_poloaQuantityMutipleData &&
                  touched.pd_poloaQuantityMutipleData[i]?.poLoaQty &&
                  errors?.pd_poloaQuantityMutipleData &&
                  errors?.pd_poloaQuantityMutipleData[i]?.poLoaQty
                    ? errors?.pd_poloaQuantityMutipleData[i]?.poLoaQty
                    : Object.entries(validationError)
                        ?.filter(([key, value]) =>
                          key.includes(`${i}.poLoaQty`)
                        )
                        ?.map(([key, value]) => (
                          <p className="m-0 text-danger" key={key}>
                            {value[0]}
                          </p>
                        ))}
                </p>
              </div>
            </div>

            <div className="col-md-2 col-sm-6">
              <div className="form-group mb-3">
                <label htmlFor="" className="form-label fw-bold">
                  UOM<span className="text-danger">*</span>
                </label>
                <select
                  className="form-control border-secondary "
                  id="unit"
                  disabled
                  value={values.pd_poloaQuantityMutipleData[i].unit}
                  onChange={(e) => {
                    const newValues = [...values.pd_poloaQuantityMutipleData];
                    newValues[i].unit = e.target.value;
                    setFieldValue(`pd_poloaQuantityMutipleData`, newValues);
                  }}
                  onBlur={(e) => {
                    setFieldTouched(
                      `pd_poloaQuantityMutipleData[${i}].unit`,
                      true
                    );
                  }}
                >
                  <option value="">Select</option>
                  {dropdowns?.unitList?.map((item, i) => {
                    return (
                      <option value={item.id} key={i}>
                        {item.name}
                      </option>
                    );
                  })}
                </select>
                <p className="text-danger m-0">
                  {touched.pd_poloaQuantityMutipleData &&
                  touched.pd_poloaQuantityMutipleData[i]?.unit &&
                  errors?.pd_poloaQuantityMutipleData &&
                  errors?.pd_poloaQuantityMutipleData[i]?.unit
                    ? errors?.pd_poloaQuantityMutipleData[i]?.unit
                    : Object.entries(validationError)
                        ?.filter(([key, value]) => key.includes(`${i}.unit`))
                        ?.map(([key, value]) => (
                          <p className="m-0 text-danger" key={key}>
                            {value[0]}
                          </p>
                        ))}
                </p>
              </div>
            </div>
            <div className="col-md-2 col-sm-6">
              <div className="form-group mb-3">
                <label htmlFor="" className="form-label fw-bold">
                  Start Date<span className="text-danger">*</span>
                </label>
                <input
                  type="date"
                  className="form-control border-secondary "
                  id="startDate"
                  disabled
                  // name={`pd_poloaQuantityMutipleData[${i}].startDate`}
                  value={values.pd_poloaQuantityMutipleData[i].startDate}
                  onChange={(e) => {
                    const newValues = [...values.pd_poloaQuantityMutipleData];
                    newValues[i].startDate = e.target.value;
                    setFieldValue(`pd_poloaQuantityMutipleData`, newValues);
                  }}
                  onBlur={(e) => {
                    setFieldTouched(
                      `pd_poloaQuantityMutipleData[${i}].startDate`,
                      true
                    );
                  }}
                />

                <p className="text-danger m-0">
                  {touched.pd_poloaQuantityMutipleData &&
                  touched.pd_poloaQuantityMutipleData[i]?.startDate &&
                  errors?.pd_poloaQuantityMutipleData &&
                  errors?.pd_poloaQuantityMutipleData[i]?.startDate
                    ? errors?.pd_poloaQuantityMutipleData[i]?.startDate
                    : Object.entries(validationError)
                        ?.filter(([key, value]) =>
                          key.includes(`${i}.startDate`)
                        )
                        ?.map(([key, value]) => (
                          <p className="m-0 text-danger" key={key}>
                            {value[0]}
                          </p>
                        ))}
                </p>
              </div>
            </div>
            <div className="col-md-2 col-sm-6">
              <div className="form-group mb-3">
                <label htmlFor="" className="form-label fw-bold">
                  Completion Date<span className="text-danger">*</span>
                </label>
                <input
                  type="date"
                  className="form-control border-secondary "
                  id="completionDate"
                  disabled
                  // name={`pd_poloaQuantityMutipleData[${i}].completionDate`}
                  value={values.pd_poloaQuantityMutipleData[i].completionDate}
                  onChange={(e) => {
                    const newValues = [...values.pd_poloaQuantityMutipleData];
                    newValues[i].completionDate = e.target.value;
                    setFieldValue(`pd_poloaQuantityMutipleData`, newValues);
                  }}
                />
                <p className="text-danger m-0">
                  {touched.pd_poloaQuantityMutipleData &&
                  touched.pd_poloaQuantityMutipleData[i]?.completionDate &&
                  errors?.pd_poloaQuantityMutipleData &&
                  errors?.pd_poloaQuantityMutipleData[i]?.completionDate
                    ? errors?.pd_poloaQuantityMutipleData[i]?.completionDate
                    : Object.entries(validationError)
                        ?.filter(([key, value]) =>
                          key.includes(`${i}.completionDate`)
                        )
                        ?.map(([key, value]) => (
                          <p className="m-0 text-danger" key={key}>
                            {value[0]}
                          </p>
                        ))}
                </p>
              </div>
            </div>
            <div className="col-md-2 col-sm-6">
              <div className="form-group mb-3">
                <div className="d-flex justify-content-between align-items-center">
                  <label htmlFor="" className="form-label fw-bold ">
                    DP Extension
                  </label>
                  <button
                    class="text-end rounded border-0 text-dark  mb-2"
                    type="button"
                    onClick={() => {
                      setLogsData((prev) => {
                        return {
                          ...prev,
                          data: values?.pd_poloaQuantityMutipleData[i]
                            ?.dpExtensionsLogs,
                          index: i,
                        };
                      });

                      setOpen(true);
                    }}
                  >
                    view Logs
                  </button>
                </div>
                <input
                  type="date"
                  className="form-control border-secondary "
                  id="dpExtension"
                  disabled
                  // name={`pd_poloaQuantityMutipleData[${i}].completionDate`}
                  placeholder="Enter DP Extention"
                  value={values.pd_poloaQuantityMutipleData[i].dpExtension}
                  onChange={(e) => {
                    const newValues = [...values.pd_poloaQuantityMutipleData];
                    newValues[i].dpExtension = e.target.value;
                    setFieldValue(`pd_poloaQuantityMutipleData`, newValues);
                  }}
                />
              </div>
            </div>

            {/* <div className="col-md-auto col-sm-6">
              <label className="form-label ">Action</label>
              <div>
                <button
                  type="button"
                  className="btn btn-primary  align-items-center me-2 fw-bold"
                  onClick={handleMltClick}
                >
                  +
                </button>
                {!values.pd_poloaQuantityMutipleData[i].id ? (
                  <button
                    type="button"
                    className="btn btn-de-danger border-danger fw-bold"
                    onClick={() => handleMultDelete(i)}
                    hidden={i === 0}
                  >
                    -
                  </button>
                ) : null}
              </div>
            </div> */}
          </div>
        ))}

        {spinner ? (
          <div className="">
            <i className="fa fa-spinner fa-spin fa-3x text-danger me-3 mt-3"></i>
            <p>Wait a moment....</p>
          </div>
        ) : (
          <>
            <button
              type="submit"
              // onClick={() => changeKey("1")}
              className="btn btn-primary btn-sm me-3"
            >
              Update
            </button>
            <button
              type="button"
              className="btn btn-de-danger btn-sm border-danger"
              onClick={() => changeKey("1")}
            >
              Cancel
            </button>
          </>
        )}
      </form>
      <DpExtensionLogsModal
        open={open}
        setOpen={setOpen}
        data={logsData.data}
        index={logsData.index}
      />
    </>
  );
}

export default EditProjectDetailForm;
