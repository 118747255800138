import * as Yup from "yup";

const addVendorFormSchema = (checkboxes) => {
  let schema = Yup.object().shape({
    party_name: Yup.string().required("This field is required"),
    under_invoice: Yup.string().required("This field is required"),
    under_account: Yup.string().required("This field is required"),
  });

  if (checkboxes.is_address_check) {
    schema = schema.shape({
      address_type: Yup.string().required("This field is required"),
      country_id: Yup.string().required("This field is required"),
      state_id: Yup.string().required("This field is required"),
      city_id: Yup.string().required("This field is required"),
      address: Yup.string().required("This field is required"),
      pin_code: Yup.string()
        .matches(/^\d+$/, "Pin code must contain only numbers")
        .length(6, "Pin code must be exactly 6 digits")
        .required("This field is required"),
      email: Yup.string().required("This field is required").email("Invalid email").nullable().notOneOf([null], 'This field cannot be null'),
      telephone: Yup.string().required("This field is required")
        .matches(
          /^[0-9()+\- ]*$/,
          "Telephone must contain only numbers and special characters (+, -, ())"
        )
        .nullable().notOneOf([null], 'This field cannot be null'), // Ensure null is treated as an error
      mobile: Yup.string().required("This field is required")
        .length(10, "Mobile number must be exactly 10 digits and contain only numbers")
        .matches(/^\d{10}$/, "Mobile number must be exactly 10 digits and contain only numbers")
        .nullable().notOneOf([null], 'This field cannot be null'),
      fax_no: Yup.string().required("This field is required")
        .matches(
          /^[0-9()+\- ]*$/,
          "Fax number must contain only numbers and special characters (+, -, ())"
        )
        .nullable().notOneOf([null], 'This field cannot be null'),
      website: Yup.string().required("This field is required").url("Must be a valid URL").nullable().notOneOf([null], 'This field cannot be null'), 
    });
}

if (checkboxes.is_bank_details_check) {
  schema = schema.shape({
    bank_name: Yup.string().required("Bank name is required"),
    ifsc_code: Yup.string()
    .required("IFSC code is required")
    .nullable()
    .notOneOf([null], 'This field cannot be null')
    .matches(
      /^[A-Za-z]{4}[0-9]{7}$/,
      "IFSC code must be 11 characters long, with the first 4 characters as alphabets and the remaining 7 as digits"
    ),
    account_holder: Yup.string().required("Account holder is required"),
    account_no: Yup.string()
      .matches(/^\d+$/, "Account number must contain only numbers")
      .required("Account number is required"),
    swift_code: Yup.string().required("This field is required")
      .nullable().notOneOf([null], 'This field cannot be null')
      .matches(
        /^[a-zA-Z0-9]{8,11}$/,
        "SWIFT code must be 8-11 characters long and contain only alphanumeric characters"
      ),
    country: Yup.string().required("Country is required"),
    state: Yup.string().required("State is required"),
    city: Yup.string().required("City is required"),
    bank_address: Yup.string().required("Bank address is required"),
    bank_pin_code: Yup.string()
      .matches(/^\d+$/, "Pin code must contain only numbers")
      .length(6, "Pin code must be exactly 6 digits")
      .required("This field is required"),
    bank_email: Yup.string().required("This field is required").email("Invalid email").nullable().notOneOf([null], 'This field cannot be null'),
    bank_telephone: Yup.string().required("This field is required")
      .nullable().notOneOf([null], 'This field cannot be null')
      .matches(
        /^[0-9()+\- ]*$/,
        "Telephone must contain only numbers and special characters (+, -, ())"
      ),
    bank_mobile: Yup.string().required("This field is required")
      .nullable().notOneOf([null], 'This field cannot be null')
      .length(10, "Mobile number must be exactly 10 digits and contain only numbers")
      .matches(/^\d{10}$/, "Mobile number must be exactly 10 digits and contain only numbers"),
    bank_fax_no: Yup.string().required("This field is required")
      .nullable().notOneOf([null], 'This field cannot be null')
      .matches(
        /^[0-9()+\- ]*$/,
        "Fax number must contain only numbers and special characters (+, -, ())"
      ),
    bank_website: Yup.string().required("This field is required").nullable().notOneOf([null], 'This field cannot be null').url("Must be a valid URL"),
  });
}

if (checkboxes.contact_details_check) {
  schema = schema.shape({
    contact_person: Yup.string().required("This field is required"),
    contact_job_title: Yup.string().required("This field is required"),
    contact_job_designation: Yup.string().required("This field is required"),
    contact_mobile: Yup.string().required("This field is required")
      .length(10, "Mobile number must be exactly 10 digits and contain only numbers")
      .matches(/^\d{10}$/, "Mobile number must be exactly 10 digits and contain only numbers"),
    // contact_telephone: Yup.string().required("This field is required")
    //   .nullable().notOneOf([null], 'This field cannot be null')
    //   .matches(
    //     /^[0-9()+\- ]*$/,
    //     "Telephone must contain only numbers and special characters (+, -, ())"
    //   ),
    contact_email: Yup.string().required("This field is required").email("Invalid email").nullable().notOneOf([null], 'This field cannot be null'), // Allows null
  });
}
// if (checkboxes.companyDetail) {
//   schema = schema.shape({
//     company_type: Yup.string().required("This field is required"),
//     discount_percente: Yup.string().required("This field is required"),
//     remarks: Yup.string().required("This field is required"),
//   });
// }

if (checkboxes.accountDetail) {
  schema = schema.shape({
    opening_balance: Yup.string().required("This field is required"),
    opening_balance_type: Yup.string().required("required"),
    currency: Yup.string().required("This field is required"),
    credit_limit: Yup.string().required("This field is required"),
    grade: Yup.string().required("This field is required"),
    cts_no: Yup.string().required("This field is required"),
    pvt_marka: Yup.string().required("This field is required"),
    ecc_no: Yup.string().required("This field is required"),
    sales_target: Yup.string().required("This field is required"),
    ecc_no: Yup.string().required("This field is required"),
    status: Yup.string().required("This field is required"),
    credit_limit_days: Yup.string().required("This field is required"),
    is_msme_check: Yup.string().required("This field is required"),
    pan_no: Yup.string().required("This field is required")
      .matches(
        /^[a-zA-Z0-9]{10}$/,
        "PAN number must be exactly 10 characters and contain only numbers or alphabets"
      )
      .nullable().notOneOf([null], 'This field cannot be null'),
    gst_no: Yup.string().required("This field is required")
      .matches(
        /^[a-zA-Z0-9]{15}$/,
        "GST number must be exactly 15 characters and contain only numbers or alphabets"
      )
      .nullable().notOneOf([null], 'This field cannot be null'),
    msme_no: Yup.string().required("This field is required")
      .length(12, "MSME number must be exactly 12 digits")
      .nullable().notOneOf([null], 'This field cannot be null'),
  });
}

return schema;
};

export default addVendorFormSchema;
