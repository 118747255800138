import { CircularProgress } from "@mui/material";
import React, { useState } from "react";
import * as XLSX from "xlsx";

const ExcelToArray = ({
  setOpen,
  setData,
  setUploadLoading,
  uploadLoading,
}) => {
  const handleFileChange = (event) => {
    setUploadLoading(true);
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const binaryStr = e.target.result;
        const workbook = XLSX.read(binaryStr, { type: "binary" });
        const worksheet = workbook.Sheets[workbook.SheetNames[0]];
        const jsonData = XLSX.utils.sheet_to_json(worksheet, {
          raw: false,
          defval: "",
        });
        const cleanedData = jsonData.map((row) => {
          return Object.fromEntries(
            Object.entries(row).map(([key, value]) => [
              key,
              value === undefined || value === null ? "" : value,
            ])
          );
        });

        setData(cleanedData);
        setOpen(true);
        setUploadLoading(false); // Reset loading state on success
      };

      reader.readAsBinaryString(file);
    } else {
      setUploadLoading(false); // Reset loading state if no file is selected
    }
  };

  const triggerFileInput = () => {
    document.getElementById("file-upload").click();
  };
  return (
    <div>
      <input
        type="file"
        accept=".xls,.xlsx"
        onChange={handleFileChange}
        id="file-upload"
        style={{ display: "none" }}
      />
      <button
        type="button"
        onClick={triggerFileInput}
        className="upload-button btn btn-danger"
        style={{
          background: "white",
          color: "red",
          width: "12rem",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: "1rem", // Adjust padding as needed
        }}
        disabled={uploadLoading}
      >
        {uploadLoading ? (
          <CircularProgress
            style={{ color: "red", marginRight: "0.5rem", padding: "2px" }}
          />
        ) : (
          `Bulk Upload`
        )}
      </button>
    </div>
  );
};

export default ExcelToArray;
