import React from "react";
import styles from "../../Components/ProjectForms/EditForms/LotsLogsTable.module.css";
import { formatDate } from "../../constant";

function TenderTableLogs({ data }) {
  return (
    <div style={{ marginTop: "2rem" }}>
      <div className={styles.lotDetailHeader}>
        <p className="justify-content-start">Tender Logs</p>
      </div>
      <table>
        <thead className={styles.lotsLogsThead}>
          <th>Updated By</th>
          <th>Updated At</th>
          <th>Action</th>
          <th>Old Data</th>
          <th>Updated Data</th>
        </thead>
        <tbody className={styles.lotsLogsTbody}>
          {data[0]?.map((row, index) => (
            <tr key={index}>
              <td>{row?.updated_by || "- - -"}</td>
              <td>{formatDate(row?.updated_at) || "- - -"}</td>
              <td>{row?.event || "- - -"}</td>
              <td>{JSON.stringify(row.old, null, 2) || "- - -"}</td>
              <td>{JSON.stringify(row.updated, null, 2) || "- - -"}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default TenderTableLogs;
