import React, { useEffect, useState } from "react";
import "../TenderStyle/TenderStyle.css";
import active from "../DashboardIcons/Tender/Active.svg";
import awarded from "../DashboardIcons/Tender/Awarded.svg";
import rejected from "../DashboardIcons/Tender/Rejected.svg";
import total from "../DashboardIcons/Tender/TotalTenders.svg";
import team from "../DashboardIcons/Tender/Team.svg";
import submit from "../DashboardIcons/Tender/Submit.svg";
import DashboardProgress from "../../Components/DashboardProgress";
import { IoSquare } from "react-icons/io5";
import { NavLink } from "react-router-dom";
import {
  getTenderDashboard,
  getUpcomingTenders,
  getTenderByCategoryApi,
} from "../../redux/api";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { selectDropdowns } from "../../redux/features/allDropdownSlice";
import { Chart } from "react-google-charts";
import { errorsMessage } from "../../constant";
import { setLogout } from "../../redux/features/authSlice";
import { useDispatch } from "react-redux";
import DashboardAlert from "../TenderModal/DashboardAlert";
import useURLFilters from "../useTenderUrlFilter";
import { faTruckField } from "@fortawesome/free-solid-svg-icons";
import DashoardBarGraph from "../Common/DashBoardBarGraph";

import { getCurrentQuarter } from "../../services/constant-variable";
import QuaterFilter from "../../common/QuaterFilter";

const TenderDashboard = () => {
  const now = new Date();
  const year = now.getFullYear();
  const month = now.getMonth();
  const user = JSON.parse(localStorage.getItem("profile"));
  const user_id = user?.response?.userDetails?.id;
  const [tenderData, setTenderData] = useState({});
  const [upcomingTenders, setUpcomingTenders] = useState([]);
  const [raTenders, setRaTenders] = useState([]);
  const [tenderByCategoryData, setTenderByCategoryData] = useState([]);
  const [showAlertModal, setShowAlertModal] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const allDropdowns = useSelector(selectDropdowns);

  // Filtering department users
  const departmentUsers = allDropdowns?.userList?.filter(
    (user) => user.departmentName === "Tender"
  );

  // Formatting date function
  const formatedDate = (date) => {
    if (date instanceof Date) {
      const pad = (num) => String(num).padStart(2, "0");
      return `${date.getFullYear()}-${pad(date.getMonth() + 1)}-${pad(
        date.getDate()
      )}`;
    }
    return ""; // Return an empty string if date is not valid
  };

  const firstDate = new Date(year, month, 1);
  const lastDate = new Date(year, month + 1, 0);
  const [filters, setFilters] = useURLFilters();

  const navigateToTender = (status) => {
    const newSearch = setFilters({
      ...filters,
      [status === "Bidded" || status === "Not Bidded"
        ? "biddingStatus"
        : "awardedStatus"]: status,
      startDate: filters?.startDate || formatedDate(firstDate),
      endDate: filters?.endDate || formatedDate(lastDate),
    });

    navigate(`/tenders?${newSearch}`, {
      state: {
        status:
          status === "Bidded" || status === "Not Bidded" ? faTruckField : true,
      },
    });
  };

  const getData = () => {
    getTenderDashboard({
      user_id,
      startDate: filters?.startDate,
      endDate: filters?.endDate,
      year: filters?.year,
      quarterData: filters?.quarterData,
    })
      .then((response) => {
        setTenderData(response?.data?.response?.tenderDashboard);
      })
      .catch((err) => {
        errorsMessage(err?.response?.status);
        if (err?.response?.status === 401) {
          dispatch(setLogout());
          navigate("/");
        }
      });

    getUpcomingTenders({ user_id })
      .then((response) => {
        setUpcomingTenders(response?.data?.response?.tendersList);
        setRaTenders(response?.data?.response?.ratendersList);
      })
      .catch((err) => {
        console.error("Error fetching upcoming tenders:", err);
        setShowAlertModal(false); // Consider handling the error more gracefully
      });
  };

  const getTenderByCategory = () => {
    getTenderByCategoryApi({ user_id })
      .then((response) => {
        console.log(
          "tender CAtegory response",
          response?.data?.response?.getStoreDashboardData
            ?.projectCountCategoryWise
        );
        setTenderByCategoryData(
          response?.data?.response?.getStoreDashboardData
            ?.projectCountCategoryWise
        );
      })
      .catch((err) => {
        console.error("Error fetching upcoming tenders:", err);
        setShowAlertModal(false); // Consider handling the error more gracefully
      });
  };

  // Tender counts
  const awardedTenders = tenderData?.awarded?.count_awarded_records || 0;
  const notAwardedTenders = tenderData?.notAwarded?.count_awarded_records || 0;
  const biddedTenders = tenderData?.bidded?.count_awarded_records || 0;
  const notBiddedTenders = tenderData?.notBidded?.count_awarded_records || 0;

  const allZero =
    awardedTenders === 0 &&
    notAwardedTenders === 0 &&
    biddedTenders === 0 &&
    notBiddedTenders === 0;

  const allZeroForCategory =
    tenderByCategoryData?.turn_key_furnishing === 0 &&
    tenderByCategoryData?.toilet_upgradation === 0 &&
    tenderByCategoryData?.refurbishment_and_conversion === 0 &&
    tenderByCategoryData?.supply === 0;

  // Prepare data for display
  const data = allZero
    ? [
        ["Status", "Number of Tenders"],
        ["No Data", 1],
      ]
    : [
        ["Status", "Number of Tenders"],
        ["Awarded", awardedTenders],
        ["Not Awarded", notAwardedTenders],
        ["Bidded", biddedTenders],
        ["Not Bidded", notBiddedTenders],
      ];

  const categoryData = allZeroForCategory
    ? [
        ["Status", "Number of Tenders"],
        ["No Data", 1],
      ]
    : [
        ["Status", "Number of Tenders"],
        ["Turn Key Furnishing", tenderByCategoryData?.turn_key_furnishing],
        ["Toilet Upgradation", tenderByCategoryData?.toilet_upgradation],
        [
          "Refurbishment/Conversion",
          tenderByCategoryData?.refurbishment_and_conversion,
        ],
        ["Supply", tenderByCategoryData?.supply],
      ];

  const colors = allZeroForCategory
    ? ["#000000"]
    : ["#a8e28d", "red", "#fdec8b", "#dcdfd8"];

  useEffect(() => {
    getData();
    getTenderByCategory();
  }, [filters.startDate, filters.endDate]);

  useEffect(() => {
    setShowAlertModal(upcomingTenders.length || raTenders.length);
  }, [upcomingTenders, raTenders]);

  useEffect(() => {
    const currentDate = new Date();
    const currentQuarter = getCurrentQuarter(currentDate);
    setFilters({
      ...filters,
      selectedYear: currentDate.getFullYear(),
      selectedQuarter: currentQuarter,
    });
  }, []);

  return (
    <>
      <div className="page-wrapper">
        <div className="page-content-tab pb-3">
          <div className="row mt-4 ">
            <div className="col-sm-4">
              <h4 className="page-title fw-bold me-auto ms-1"> DASHBOARD </h4>
            </div>
            <div className="col-sm-8">
              <QuaterFilter filters={filters} setFilters={setFilters} />
            </div>
          </div>
          <div className="container-fluid tender-dashboard">
            <div className="row">
              <div className="col-sm-12">
                <div className="page-title-box">
                  <div className="row">
                    <div className="col-md-4 h-100">
                      <div className="card heading-card">
                        <div className="card-body mb-1">
                          <div className="tender-heading">
                            <h3 className="">Tender</h3>
                            <h3 className="mb-0">Dashboard</h3>
                          </div>
                          <p>
                            Better access to customer information · Faster
                            response times · Improved on-time delivery ·
                            Improved order accuracy.
                          </p>
                          <NavLink to="/tenders/add-tender">
                            <button className="btn btn-danger mb-1 fw-bold">
                              Add New Tender
                            </button>
                          </NavLink>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-8">
                      <div className="row">
                        <div
                          className="col-md-4"
                          onClick={() => navigate("/tenders")}
                        >
                          <div className="card detail-card">
                            <div className="card-body">
                              <div className="row">
                                <div className="col-7 tender-type">
                                  <h5 className="fw-bold mt-0">
                                    Total Tenders
                                  </h5>
                                </div>
                                <div className="col-5 text-end icon-img">
                                  <h3
                                    style={{ color: "#6560F0" }}
                                    className="fw-bold mt-0"
                                  >
                                    {
                                      tenderData?.totalTender
                                        ?.count_awarded_records
                                    }
                                  </h3>
                                </div>
                                <div className="col-7 tender-type pt-3">
                                  {/* <p className="m-0">Amount</p>
                                  <p className="fw-bold m-0">
                                    {formatNumber(
                                      tenderData?.totalTender
                                        ?.total_advertised_value
                                    )}
                                  </p> */}
                                </div>
                                <div className="col-5 text-end">
                                  <div>
                                    <img src={total} alt="Icon" />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="col-md-4"
                          onClick={() => navigateToTender("Awarded")}
                        >
                          <div className="card detail-card">
                            <div className="card-body">
                              <div className="row">
                                <div className="col-7 tender-type">
                                  <h5 className="fw-bold mt-0">
                                    Awarded Tenders
                                  </h5>
                                </div>
                                <div className="col-5 text-end">
                                  <h3
                                    style={{ color: "#06B48A" }}
                                    className="fw-bold mt-0"
                                  >
                                    {
                                      tenderData?.awarded_tenders
                                        ?.count_awarded_records
                                    }
                                  </h3>
                                </div>
                                <div className="col-7 tender-type pt-3"></div>
                                <div className="col-5 text-end">
                                  <img src={awarded} alt="Icon" />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="col-md-4"
                          onClick={() => navigateToTender("Not Awarded")}
                        >
                          <div className="card detail-card">
                            <div className="card-body">
                              <div className="row">
                                <div className="col-7 tender-type">
                                  <h5 className="fw-bold mt-0">
                                    Not Awarded Tenders
                                  </h5>
                                </div>
                                <div className="col-5 text-end">
                                  <h3
                                    style={{ color: "#D90000" }}
                                    className="fw-bold mt-0"
                                  >
                                    {
                                      tenderData?.notAwardedTenders
                                        ?.count_awarded_records
                                    }
                                  </h3>
                                </div>
                                <div className="col-7 tender-type pt-3">
                                  {/* <p className="m-0">Amount</p>
                                  <p className="fw-bold m-0">
                                    {formatNumber(
                                      tenderData?.notAwarded
                                        ?.total_advertised_value
                                    )}
                                  </p> */}
                                </div>
                                <div className="col-5 text-end">
                                  <img src={rejected} alt="Icon" />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="col-md-4"
                          onClick={() => navigateToTender("Bidded")}
                        >
                          <div className="card detail-card">
                            <div className="card-body">
                              <div className="row">
                                <div className="col-7 tender-type">
                                  <h5 className="fw-bold mt-0">
                                    Bidded Tenders
                                  </h5>
                                </div>
                                <div className="col-5 text-end">
                                  <h3
                                    style={{ color: "#E8BE00" }}
                                    className="fw-bold mt-0"
                                  >
                                    {tenderData?.bidded?.count_awarded_records}
                                  </h3>
                                </div>
                                <div className="col-7 tender-type pt-3">
                                  {/* <p className="m-0">Amount</p>
                                  <p className="fw-bold m-0">
                                    {formatNumber(
                                      tenderData?.bidded?.total_advertised_value
                                    )}
                                  </p> */}
                                </div>
                                <div className="col-5 text-end">
                                  <img src={submit} alt="Icon" />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="col-md-4"
                          onClick={() => navigateToTender("Not Bidded")}
                        >
                          <div className="card detail-card">
                            <div className="card-body">
                              <div className="row">
                                <div className="col-7 tender-type">
                                  <h5 className="fw-bold mt-0">
                                    Not Bidded Tenders
                                  </h5>
                                </div>
                                <div className="col-5 text-end">
                                  <h3
                                    style={{ color: "#0041D6" }}
                                    className="fw-bold mt-0"
                                  >
                                    {
                                      tenderData?.notBidded
                                        ?.count_awarded_records
                                    }
                                  </h3>
                                </div>
                                <div className="col-7 tender-type pt-3">
                                  {/* <p className="m-0">Amount</p>
                                  <p className="fw-bold m-0">
                                    {formatNumber(
                                      tenderData?.notBidded
                                        ?.total_advertised_value
                                    )}
                                  </p> */}
                                </div>
                                <div className="col-5 text-end">
                                  <img src={active} alt="Icon" />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4 h-100">
                          <div className="card detail-card">
                            <div className="card-body">
                              <div className="row">
                                <div className="col-7 tender-type">
                                  <h5 className="fw-bold mt-0">Total Team</h5>
                                </div>
                                <div className="col-5 text-end">
                                  <h3
                                    style={{ color: "#00BFFC" }}
                                    className="fw-bold mt-0"
                                  >
                                    {departmentUsers?.length}
                                  </h3>
                                </div>
                                <div className="col-7 tender-type pt-3"></div>
                                <div className="col-5 text-end">
                                  <img src={team} alt="Icon" />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6">
                      <div className="card pie-chart">
                        <div className="card-body">
                          <span className="chart-title">
                            Tender By Category
                          </span>
                          <div className="status-color" style={{ zIndex: "1" }}>
                            <div className="">
                              <IoSquare
                                style={{
                                  color: "#a8e28d",
                                  height: "1rem",
                                  width: "1rem",
                                  display: "inline",
                                  marginRight: ".3rem",
                                }}
                              />
                              <p className="m-0 d-inline font-10">
                                Turnkey Furnishing :{" "}
                                {tenderByCategoryData?.turn_key_furnishing}
                              </p>
                            </div>
                            <div className="">
                              <IoSquare
                                style={{
                                  color: "red",
                                  height: "1rem",
                                  width: "1rem",
                                  display: "inline",
                                  marginRight: ".3rem",
                                }}
                              />
                              <p className="m-0 d-inline font-10">
                                Toilet Upgradation :{" "}
                                {tenderByCategoryData?.toilet_upgradation}
                              </p>
                            </div>
                            <div className="">
                              <IoSquare
                                style={{
                                  color: "#fdec8b",
                                  height: "1rem",
                                  width: "1rem",
                                  display: "inline",
                                  marginRight: ".3rem",
                                }}
                              />
                              <p className="m-0 d-inline font-10">
                                Refurbishment/Conversion :{" "}
                                {
                                  tenderByCategoryData?.refurbishment_and_conversion
                                }
                              </p>
                            </div>
                            <div className="">
                              <IoSquare
                                style={{
                                  color: "#dcdfd8",
                                  height: "1rem",
                                  width: "1rem",
                                  display: "inline",
                                  marginRight: ".3rem",
                                }}
                              />
                              <p className="m-0 d-inline font-10">
                                Supply : {tenderByCategoryData?.supply}
                              </p>
                            </div>
                          </div>
                          <Chart
                            chartType="PieChart"
                            width="100%"
                            height="19.375rem"
                            data={categoryData}
                            options={{
                              colors: colors,
                              pieHole: 0.5,
                              is3D: false,
                              titleTextStyle: {
                                color: "black",
                                fontSize: 14,
                              },
                              legend: {
                                position: "none",
                              },
                              pieSliceTextStyle: {
                                color: "black",
                              },
                              // pieSliceText: "label",
                              tooltip: {
                                trigger: allZeroForCategory ? "none" : "focus",
                              },
                              enableInteractivity: !allZeroForCategory,
                            }}
                          />
                          ;
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="card pie-chart">
                        <div className="card-body">
                          <span className="chart-title">
                            Bidding Status Ratio
                          </span>
                          <div className="status-color" style={{ zIndex: "1" }}>
                            <div className="">
                              <IoSquare
                                style={{
                                  color: "#a8e28d",
                                  height: "1rem",
                                  width: "1rem",
                                  display: "inline",
                                  marginRight: ".3rem",
                                }}
                              />
                              <p className="m-0 d-inline font-10">Awarded</p>
                            </div>
                            <div className="">
                              <IoSquare
                                style={{
                                  color: "red",
                                  height: "1rem",
                                  width: "1rem",
                                  display: "inline",
                                  marginRight: ".3rem",
                                }}
                              />
                              <p className="m-0 d-inline font-10">
                                Not Awarded
                              </p>
                            </div>
                            <div className="">
                              <IoSquare
                                style={{
                                  color: "#fdec8b",
                                  height: "1rem",
                                  width: "1rem",
                                  display: "inline",
                                  marginRight: ".3rem",
                                }}
                              />
                              <p className="m-0 d-inline font-10">Bidded</p>
                            </div>
                            <div className="">
                              <IoSquare
                                style={{
                                  color: "#dcdfd8",
                                  height: "1rem",
                                  width: "1rem",
                                  display: "inline",
                                  marginRight: ".3rem",
                                }}
                              />
                              <p className="m-0 d-inline font-10">Not Bidded</p>
                            </div>
                          </div>
                          <Chart
                            chartType="PieChart"
                            width="100%"
                            height="19.375rem"
                            data={data}
                            options={{
                              colors: colors,
                              pieHole: 0.5,
                              is3D: false,
                              titleTextStyle: {
                                color: "black",
                                fontSize: 14,
                              },
                              legend: {
                                position: "none",
                              },
                              pieSliceTextStyle: {
                                color: "black",
                              },
                              pieSliceText: "label",
                              tooltip: {
                                trigger: allZero ? "none" : "focus",
                              },
                              enableInteractivity: !allZero,
                            }}
                          />
                          ;
                        </div>
                      </div>
                    </div>

                    <div className="col-md-12">
                      <div className="card">
                        <div className="card-body">
                          <DashoardBarGraph
                            monthOrderCount={tenderData?.monthly_awarded_counts}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <DashboardAlert
        show={showAlertModal}
        setShow={setShowAlertModal}
        upcomingTenders={upcomingTenders}
        raTenders={raTenders}
      ></DashboardAlert>
    </>
  );
};

export default TenderDashboard;
