import React from "react";
import "../Pages/Style.css";
import TenderAsidebar from "../Tender/TenderComponents/TenderAsidebar";
import Header from "./Header";
import BackOfficeAsidebar from "../Backoffice/BackofficeComponents/Trackers/BackOfficeAsidebar";
import { useSelector } from "react-redux";
import PurchaseAsidebar from "../Purchase/PurchaseComponents/PurchaseAsidebar";
import StoreAsidebar from "../StoreDepartment/StoreAsidebar";
import HrSidebar from "../HrDepartment/HrSideBar";
import HRHeader from "../HrDepartment/HRHeader";

function Aside({ children }) {
  const { user } = useSelector((state) => ({ ...state.auth }));
  // const department = "HR"

  const department = user?.response.userDetails.departmentName;
  return (
    <>
      {department === "Human Resource" ? <HRHeader /> : <Header />}
      {department === "Tender" ? (
        <TenderAsidebar></TenderAsidebar>
      ) : department === "Purchase" ? (
        <PurchaseAsidebar />
      ) : department === "Store" ? (
        <StoreAsidebar />
      ) : department === "Human Resource" ? (
        <HrSidebar />
      ) : (
        <BackOfficeAsidebar />
      )}
      {children}
    </>
  );
}

export default Aside;
