import React, { useState, useRef } from "react";

import axios from "axios";
import { Link, useParams } from "react-router-dom";
import { useEffect } from "react";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import { toast } from "react-toastify";
import BillingupdatePayment from "./BillingupdatePayment";

function Billing({ tabKey, renderState, setRenderState }) {
  const baseUrl = process.env.REACT_APP_DEV_API_KEY_NEW;
  const user = JSON.parse(localStorage.getItem("profile"));
  const user_id = user?.response?.userDetails?.id;
  const getToken = user?.response?.token;
  const bearer_token = `Bearer ${getToken}`;
  const param = useParams();
  let project_id = Number(param.id);
  const [fetchBillingPayment, setFetchBillingPayment] = useState(false);

  const [attachmentName, setAttachmentName] = useState([]);
  const [invoiceAttachmentName, setInvoiceAttachmentName] = useState([]);
  const [rowId, setRowId] = useState([
    { invoiceAttachmetname: "", rNoteAttachmentName: "" },
  ]);
  const attachmentBaseUrl = process.env.REACT_APP_BASE_URL;

  const [updating, setUpdating] = useState(false);
  const [loading, setLoading] = useState(true);

  const [validationError, setValidationError] = useState("");

  const [bpdAtt, setBpdAtt] = useState(null);
  const [invoiceAtt, setInvoiceAtt] = useState(null);

  const [deductionError, setDeductionError] = useState(false);
  const [show, setShow] = useState(false);
  const [modalData, setModalData] = useState([]);

  function addBillingInput(i) {
    const updatedBillingData = [...billingData];

    const singleBill = { ...updatedBillingData[i] };

    const updatedFields = [...singleBill.bpd_Fields, billingFieldsInitial];

    singleBill.bpd_Fields = updatedFields;

    updatedBillingData[i] = singleBill;

    setBillingData(updatedBillingData);
  }

  const [billingData, setBillingData] = useState([
    {
      bpd_invoiceNo: "",
      bpd_invoiceDate: "",
      bpd_invoiceQuantity: "",
      bpd_invoiceAmount: "",
      bpd_descriptionOfInvoice: "",
      bpd_attachmentOfInvoice: "",
      bpd_materialAcceptedRejected: "",
      bpd_attachedRNote: "",
      bpd_rNoteNoDate: "",
      bpd_rNoteNo: "",
      bpd_paymentStatus: "",
      bpd_date: "",
      bpd_amountRecieved: "",
      bpd_sd_securityDeposite: "",
      bpd_ld_liquidityDamage: "",
      bpd_penalty: "",
      bpd_storeDeposite: "",
      bpd_materialDeductied: "",
      bpd_primaryCessSurcharge: "",
      bpd_tds: "",
      bpd_gst: "",
      bpd_otherDeduction: "",
      bpd_totalDeduction: "",
      bpd_paymentDue: "",
      bpd_poloaCompletionStatus: "",
      bpd_billing_pendingAmount: "",
      bpd_billing_pendingDate: "",
      bpd_ICAttachment: "",
      bpd_PaymentSlip: "",
      bpd_warranty_deduction: "",
      //  pending_details: [{ amount: "", date: "" }],
    },
  ]);

  const billingContainerRef = useRef(null);

  const addBillDetail = () => {
    setBillingData([
      ...billingData,
      {
        bpd_invoiceNo: "",
        bpd_invoiceDate: "",
        bpd_invoiceQuantity: "",
        bpd_invoiceAmount: "",
        bpd_descriptionOfInvoice: "",
        bpd_attachmentOfInvoice: "",
        bpd_materialAcceptedRejected: "",
        bpd_attachedRNote: "",
        bpd_rNoteNoDate: "",
        bpd_rNoteNo: "",
        bpd_paymentStatus: "",
        bpd_date: "",
        bpd_amountRecieved: "",
        bpd_sd_securityDeposite: "",
        bpd_ld_liquidityDamage: "",
        bpd_penalty: "",
        bpd_storeDeposite: "",
        bpd_materialDeductied: "",
        bpd_primaryCessSurcharge: "",
        bpd_tds: "",
        bpd_gst: "",
        bpd_otherDeduction: "",
        bpd_totalDeduction: "",
        bpd_paymentDue: "",
        bpd_poloaCompletionStatus: "",
        bpd_ICAttachment: "",
        bpd_PaymentSlip: "",
        bpd_warranty_deduction: "",
        // pending_details: [{ amount: "", date: "" }],
      },
    ]);
    setRowId([...rowId, { invoiceAttachmetname: "", rNoteAttachmentName: "" }]);
    if (billingContainerRef.current) {
      billingContainerRef.current.scrollLeft = 0;
      billingContainerRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "start",
      });
    }
  };

  const removeBillingData = (i) => {
    const deleteVal = [...billingData];
    deleteVal.splice(i, 1);
    setBillingData(deleteVal);

    const attName = [...rowId];
    attName.splice(i, 1);
    setRowId(attName);
  };

  function countDeduction(index) {
    if (billingData[index].bpd_paymentDue < 0) {
      setDeductionError(true);
    } else setDeductionError(false);
  }

  function handleBillingDataChangeNumber(e, i) {
    const { name, value } = e.target;
    const newData = [...billingData];

    if (name === "bpd_attachedRNote") {
      const data = new FileReader();
      data.addEventListener("load", () => {
        newData[i][name] = data.result;
        setBpdAtt(data.result);
      });
      data.readAsDataURL(e.target.files[0]);
    }

    if (name === "bpd_attachmentOfInvoice") {
      const data = new FileReader();
      data.addEventListener("load", () => {
        newData[i][name] = data.result;
        setInvoiceAtt(data.result);
      });
      data.readAsDataURL(e.target.files[0]);
    } else if (/^\d*\.?\d*$/.test(value)) {
      newData[i][name] = value;
    }
    setBillingData(newData);

    countDeduction(i);
  }

  function handleBillingDataChange(e, i) {
    const { name, value } = e.target;
    const newData = [...billingData];
    const attachmentName = [...rowId];
    if (name === "bpd_attachedRNote") {
      const data = new FileReader();
      data.addEventListener("load", () => {
        newData[i][name] = data.result;
        setBpdAtt(data.result);
        attachmentName[i].rNoteAttachmentName = e.target.files[0].name;
      });
      data.readAsDataURL(e.target.files[0]);
    }

    if (name === "bpd_attachmentOfInvoice") {
      const data = new FileReader();
      data.addEventListener("load", () => {
        newData[i][name] = data.result;
        setInvoiceAtt(data.result);
      });
      data.readAsDataURL(e.target.files[0]);
      attachmentName[i].invoiceAttachmetname = e.target.files[0].name;
      setRowId(attachmentName);
    } else {
      newData[i][name] = value;
    }
    setBillingData(newData);

    countDeduction(i);
  }

  function getBillingList() {
    setLoading(true);
    let url = baseUrl + "listuntiprojectbillaing";
    let data = { user_id, project_id };
    axios
      .post(url, data, {
        headers: {
          Authorization: bearer_token,
        },
      })
      .then((response) => {
        setLoading(false);
        setValidationError("");
        if (response.status === 200) {
          const projectList = response?.data?.response?.projectList;
          console.log(projectList);
          setRenderState(!renderState);
          if (projectList && projectList.length > 0) {
            setBillingData(projectList);
            const generateNewArrayForAttachment = Array.from(
              { length: projectList.length },
              () => {
                return { invoiceAttachmetname: "", rNoteAttachmentName: "" };
              }
            );
            setRowId(generateNewArrayForAttachment);

            const rNoteAttachmentNames = projectList.map(
              (e) => e.bpd_attachedRNote
            );
            const invoiceNames = projectList.map(
              (e) => e.bpd_attachmentOfInvoice
            );
            setAttachmentName(rNoteAttachmentNames);
            setInvoiceAttachmentName(invoiceNames);
          }
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error(
          "There is some issue in Loading Billing Detail, Please try again later"
        );
      });
  }

  async function storeBillingData(e) {
    e.preventDefault();
    setUpdating(true);
    let url = baseUrl + "storeuntiprojectbillaing";
    for (let i = 0; i < billingData.length; i++) {
      if (billingData[i]?.bpd_attachedRNote?.startsWith("http")) {
        billingData[i].bpd_attachedRNote = billingData[i].bpd_attachedRNote;
      } else if (billingData[i]?.bpd_attachedRNote === attachmentName[i]) {
        billingData[i].bpd_attachedRNote = billingData[i].bpd_attachedRNote
          ? attachmentBaseUrl + billingData[i].bpd_attachedRNote
          : billingData[i].bpd_attachedRNote;
      }

      if (billingData[i]?.bpd_attachmentOfInvoice?.startsWith("http")) {
        billingData[i].bpd_attachmentOfInvoice =
          billingData[i].bpd_attachmentOfInvoice;
      } else if (
        billingData[i]?.bpd_attachmentOfInvoice === invoiceAttachmentName[i]
      ) {
        billingData[i].bpd_attachmentOfInvoice = billingData[i]
          .bpd_attachmentOfInvoice
          ? attachmentBaseUrl + billingData[i].bpd_attachmentOfInvoice
          : billingData[i].bpd_attachmentOfInvoice;
      }
    }

    let data = { user_id, project_id, billingData };
    axios
      .post(url, data, {
        headers: {
          Authorization: bearer_token,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setUpdating(false);
        setAttachmentName([]);
        if (response.status === 200) {
          getBillingList();
          toast.success("Billing Detail Update successfully");
        }
      })
      .catch(async (error) => {
        setUpdating(false);
        if (error.response.data.message === "Error validation") {
          setValidationError(error.response.data.response);
          toast.error("Please fill the required inputs inputs");
          if (billingContainerRef.current) {
            billingContainerRef.current.scrollLeft = 0;
            billingContainerRef.current.scrollIntoView({
              behavior: "smooth",
              block: "start",
              inline: "start",
            });
          }
        } else {
          toast.error("Something went wrong , please try again");
        }
      });
  }
  useEffect(() => {
    if (tabKey === "finance") {
      getBillingList();
    }
  }, [tabKey, fetchBillingPayment]);
  return (
    <>
      <div>
        <h5 className="mb-3">BILLING DETAILS</h5>
      </div>
      {loading ? (
        <div className="text-center">
          <Spinner animation="border" variant="danger" />
        </div>
      ) : (
        <form>
          <div
            className="mb-3"
            ref={billingContainerRef}
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(25, 15.25rem)",
              gridGap: "1rem",
              overflowY: "hidden",
              overflowX: "scroll",
            }}
          >
            {billingData.map((element, index) => {
              return (
                <>
                  <div className="">
                    <div className="form-group mb-3">
                      <label htmlFor="" className="form-label">
                        Invoice No.
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="bpd_invoiceNo"
                        name="bpd_invoiceNo"
                        placeholder="Enter Invoice no."
                        value={element.bpd_invoiceNo}
                        onChange={(e) => handleBillingDataChange(e, index)}
                      />

                      {Object.entries(validationError)
                        ?.filter(([key, value]) =>
                          key.includes(`${index}.bpd_invoiceNo`)
                        )
                        ?.map(([key, value]) => (
                          <p className="m-0 text-danger" key={key}>
                            {value[0]}
                          </p>
                        ))}
                    </div>
                  </div>
                  <div className="">
                    <div className="form-group mb-3">
                      <label htmlFor="" className="form-label">
                        Invoice Date
                      </label>
                      <input
                        type="date"
                        className="form-control"
                        id="bpd_invoiceDate"
                        name="bpd_invoiceDate"
                        placeholder="Enter Invoice Date"
                        value={element.bpd_invoiceDate}
                        onChange={(e) => handleBillingDataChange(e, index)}
                      />
                      {Object.entries(validationError)
                        ?.filter(([key, value]) =>
                          key.includes(`${index}.bpd_invoiceDate`)
                        )
                        ?.map(([key, value]) => (
                          <p className="m-0 text-danger" key={key}>
                            {value[0]}
                          </p>
                        ))}
                    </div>
                  </div>
                  <div className="">
                    <div className="form-group mb-3">
                      <label htmlFor="" className="form-label">
                        Invoice Quantity
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="bpd_invoiceQuantity"
                        name="bpd_invoiceQuantity"
                        placeholder="Enter quantity"
                        value={element.bpd_invoiceQuantity}
                        onChange={(e) => handleBillingDataChange(e, index)}
                      />
                      {Object.entries(validationError)
                        ?.filter(([key, value]) =>
                          key.includes(`${index}.bpd_invoiceQuantity`)
                        )
                        ?.map(([key, value]) => (
                          <p className="m-0 text-danger" key={key}>
                            {value[0]}
                          </p>
                        ))}
                    </div>
                  </div>
                  <div className="">
                    <div className="form-group mb-3">
                      <label htmlFor="" className="form-label">
                        Invoice Amount
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="bpd_invoiceAmount"
                        name="bpd_invoiceAmount"
                        placeholder="Enter Amount"
                        value={element.bpd_invoiceAmount}
                        onChange={(e) =>
                          handleBillingDataChangeNumber(e, index)
                        }
                      />

                      {Object.entries(validationError)
                        ?.filter(([key, value]) =>
                          key.includes(`${index}.bpd_invoiceAmount`)
                        )
                        ?.map(([key, value]) => (
                          <p className="m-0 text-danger" key={key}>
                            {value[0]}
                          </p>
                        ))}
                    </div>
                  </div>
                  <div className="">
                    <div className="form-group mb-3">
                      <label htmlFor="" className="form-label">
                        Invoice Description
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="bpd_descriptionOfInvoice"
                        name="bpd_descriptionOfInvoice"
                        placeholder="Enter Billing Description"
                        value={element.bpd_descriptionOfInvoice}
                        onChange={(e) => handleBillingDataChange(e, index)}
                      />
                      {Object.entries(validationError)
                        ?.filter(([key, value]) =>
                          key.includes(`${index}.bpd_descriptionOfInvoice`)
                        )
                        ?.map(([key, value]) => (
                          <p className="m-0 text-danger" key={key}>
                            {value[0]}
                          </p>
                        ))}
                    </div>
                  </div>
                  <div className="">
                    <div className="form-group mb-3">
                      <label htmlFor="" className="form-label">
                        {element?.bpd_attachmentOfInvoice ? (
                          element?.bpd_attachmentOfInvoice?.startsWith(
                            "assets"
                          ) ? (
                            <Link
                              to={
                                attachmentBaseUrl +
                                element?.bpd_attachmentOfInvoice
                              }
                              target="_blank"
                            >
                              Invoice Attachment
                            </Link>
                          ) : (
                            "Invoice Attachment"
                          )
                        ) : (
                          "Invoice Attachment"
                        )}
                      </label>
                      <input
                        type="file"
                        accept="application/pdf"
                        className="form-control"
                        id={`bpd_attachmentOfInvoice${index}`}
                        name="bpd_attachmentOfInvoice"
                        placeholder=""
                        onChange={(e) => handleBillingDataChange(e, index)}
                        hidden
                      />

                      <label
                        className="d-block border form-control  ps-0 "
                        htmlFor={`bpd_attachmentOfInvoice${index}`}
                        style={{
                          cursor: "pointer",
                          border: "1px solid #e8ebf3",
                          backgroundColor: "#f1f5fa",
                        }}
                      >
                        <span className="text-dark px-2 pt-1 pb-1 me-1">
                          <i className="las la-paperclip fs-4"></i>
                          <span className="ms-2">
                            {rowId[index]?.invoiceAttachmetname
                              ? rowId[index]?.invoiceAttachmetname.slice(
                                  0,
                                  20
                                ) + "..."
                              : element.bpd_attachmentOfInvoice
                              ? "Invoice Attachment"
                              : "No Attachment"}
                          </span>
                        </span>{" "}
                        {Object.entries(validationError)
                          ?.filter(([key, value]) =>
                            key.includes(`${index}.bpd_attachmentOfInvoice`)
                          )
                          ?.map(([key, value]) => (
                            <p className="m-0 text-danger" key={key}>
                              {value[0]}
                            </p>
                          ))}
                      </label>
                    </div>
                  </div>
                  <div className="">
                    <div className="d-flex align-items-center gap-3">
                      <div className="form-group mb-3 w-100">
                        <label
                          htmlFor={`bpd_attachedRNote${index}`}
                          className="form-label"
                        >
                          {element?.bpd_attachedRNote ? (
                            element?.bpd_attachedRNote?.startsWith("assets") ? (
                              <Link
                                to={
                                  attachmentBaseUrl + element?.bpd_attachedRNote
                                }
                                target="_blank"
                              >
                                Completion Certificate
                              </Link>
                            ) : (
                              "Completion Certificate"
                            )
                          ) : (
                            "Completion Certificate"
                          )}
                        </label>
                        <input
                          type="file"
                          accept="application/pdf"
                          className="form-control"
                          id={`bpd_attachedRNote${index}`}
                          name="bpd_attachedRNote"
                          // value={element.bpd_attachedRNote}
                          onChange={(e) => handleBillingDataChange(e, index)}
                          // hidden={!bpdAtt}
                          hidden
                        />
                        <label
                          className="d-block border form-control ps-0"
                          htmlFor={`bpd_attachedRNote${index}`}
                          style={{
                            cursor: "pointer",
                            border: "1px solid #e8ebf3",
                            backgroundColor: "#f1f5fa",
                          }}
                        >
                          <span className="text-dark px-3 pt-2 pb-1 me-3">
                            <i className="las la-paperclip fs-4"></i>
                            <span className="ms-2">
                              {rowId[index]?.rNoteAttachmentName
                                ? rowId[index]?.rNoteAttachmentName.slice(
                                    0,
                                    20
                                  ) + "..."
                                : element.bpd_attachedRNote
                                ? "RA Attachment"
                                : "No Attachment"}
                            </span>
                          </span>{" "}
                        </label>
                        {Object.entries(validationError)
                          ?.filter(([key, value]) =>
                            key.includes(`${index}.bpd_attachedRNote`)
                          )
                          ?.map(([key, value]) => (
                            <p className="m-0 text-danger" key={key}>
                              {value[0]}
                            </p>
                          ))}
                      </div>
                      {/* {element.bpd_attachedRNote ? (
                        <div className="form-group mb-3">
                          <img
                            src={element.bpd_attachedRNote}
                            style={{
                              width: "70px",
                              height: "70px",
                              objectFit: "cover",
                              borderRadius: "50%",
                            }}
                          />
                        </div>
                      ) : null} */}
                    </div>
                  </div>

                  <div className="">
                    <div className="form-group mb-3">
                      <label htmlFor="bpd_rNoteNo" className="form-label">
                        R Note No.
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="bpd_rNoteNo"
                        name="bpd_rNoteNo"
                        placeholder="Enter R Note no."
                        value={element.bpd_rNoteNo}
                        onChange={(e) => handleBillingDataChange(e, index)}
                      />

                      {Object.entries(validationError)
                        ?.filter(
                          ([key, value]) =>
                            key === `billingData.${index}.bpd_rNoteNo`
                        )
                        ?.map(([key, value]) => (
                          <p className="m-0 text-danger" key={key}>
                            {value[0]}
                          </p>
                        ))}
                    </div>
                  </div>
                  {/* <div className="">
                    <div className="form-group mb-3">
                      <label htmlFor="" className="form-label">
                        R Note Date
                      </label>
                      <input
                        type="date"
                        className="form-control"
                        id="bpd_rNoteNoDate"
                        name="bpd_rNoteNoDate"
                        placeholder=""
                        value={element.bpd_rNoteNoDate}
                        onChange={(e) => handleBillingDataChange(e, index)}
                      />
                      {Object.entries(validationError)
                        ?.filter(([key, value]) =>
                          key.includes(`${index}.bpd_rNoteNoDate`)
                        )
                        ?.map(([key, value]) => (
                          <p className="m-0 text-danger" key={key}>
                            {value[0]}
                          </p>
                        ))}
                    </div>  
                    Removed on client request
                   
                  </div> */}
                  <div className="">
                    <div className="form-group mb-3">
                      <label htmlFor="" className="form-label">
                        Payment Status
                      </label>
                      <select
                        className="form-control"
                        id="bpd_paymentStatus"
                        name="bpd_paymentStatus"
                        value={element.bpd_paymentStatus}
                        onChange={(e) => handleBillingDataChange(e, index)}
                      >
                        <option value="">Select</option>
                        <option value="Received">Received</option>
                        <option value="Pending">Pending</option>
                        <option value="Done">Done</option>
                      </select>
                      {Object.entries(validationError)
                        ?.filter(([key, value]) =>
                          key.includes(`${index}.bpd_paymentStatus`)
                        )
                        ?.map(([key, value]) => (
                          <p className="m-0 text-danger" key={key}>
                            {value[0]}
                          </p>
                        ))}
                    </div>
                  </div>
                  <div className="">
                    <div className="form-group mb-3">
                      <label htmlFor="" className="form-label">
                        Date
                      </label>
                      <input
                        type="date"
                        className="form-control"
                        id="bpd_date"
                        name="bpd_date"
                        placeholder=""
                        value={element.bpd_date}
                        onChange={(e) => handleBillingDataChange(e, index)}
                      />
                      {Object.entries(validationError)
                        ?.filter(([key, value]) =>
                          key.includes(`${index}.bpd_date`)
                        )
                        ?.map(([key, value]) => (
                          <p className="m-0 text-danger" key={key}>
                            {value[0]}
                          </p>
                        ))}
                    </div>
                  </div>
                  {/* <div className="">
                    <div className="form-group mb-3">
                      <label htmlFor="amountReceives" className="form-label">
                        Amount Received
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="bpd_amountRecieved"
                        name="bpd_amountRecieved"
                        placeholder="Enter Amount Received"
                        value={element.bpd_amountRecieved}
                        onChange={(e) =>
                          handleBillingDataChangeNumber(e, index)
                        }
                      />

                      {Object.entries(validationError)
                        ?.filter(([key, value]) =>
                          key.includes(`${index}.bpd_amountRecieved`)
                        )
                        ?.map(([key, value]) => (
                          <p className="m-0 text-danger" key={key}>
                            {value[0]}
                          </p>
                        ))}
                    </div>
                  </div> */}
                  <div className="">
                    <div className="form-group mb-1">
                      <label htmlFor="" className="form-label">
                        Amount Received
                      </label>
                    </div>
                    <div>
                      <input
                        onDoubleClick={(e) => {
                          if (element.id) {
                            setShow(true);
                            setModalData(index);
                            storeBillingData(e);
                          } else {
                            toast.error("Please Upload Invoice First");
                          }
                        }}
                        className="form-control"
                        style={{
                          background:
                            element?.pending_details?.length > 1
                              ? "#c8e2f8"
                              : "",
                        }}
                        value={
                          element.id
                            ? element.pending_details.reduce(
                                (total, amount) => +total + +amount.amount,
                                0
                              )
                            : 0
                        }
                      ></input>
                    </div>
                  </div>
                  <div className="">
                    <div className="form-group mb-3">
                      <label htmlFor="" className="form-label">
                        SD (Security Deposit)
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="bpd_sd_securityDeposite"
                        name="bpd_sd_securityDeposite"
                        placeholder="Enter Security Deposit"
                        value={element.bpd_sd_securityDeposite}
                        onChange={(e) =>
                          handleBillingDataChangeNumber(e, index)
                        }
                      />

                      {Object.entries(validationError)
                        ?.filter(([key, value]) =>
                          key.includes(`${index}.bpd_sd_securityDeposite`)
                        )
                        ?.map(([key, value]) => (
                          <p className="m-0 text-danger" key={key}>
                            {value[0]}
                          </p>
                        ))}
                    </div>
                  </div>

                  <div className="">
                    <div className="form-group mb-3">
                      <label htmlFor="" className="form-label">
                        Warranty Deduction
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="bpd_warranty_deduction"
                        name="bpd_warranty_deduction"
                        placeholder="Enter Warranty Deduction"
                        value={element.bpd_warranty_deduction}
                        onChange={(e) =>
                          handleBillingDataChangeNumber(e, index)
                        }
                      />

                      {Object.entries(validationError)
                        ?.filter(([key, value]) =>
                          key.includes(`${index}.bpd_warranty_deduction`)
                        )
                        ?.map(([key, value]) => (
                          <p className="m-0 text-danger" key={key}>
                            {value[0]}
                          </p>
                        ))}
                    </div>
                  </div>
                  <div className="">
                    <div className="form-group mb-3">
                      <label htmlFor="" className="form-label">
                        LD (Liquidity Damage)
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="bpd_ld_liquidityDamage"
                        name="bpd_ld_liquidityDamage"
                        placeholder="Enter Liquidity Damage"
                        value={element.bpd_ld_liquidityDamage}
                        onChange={(e) =>
                          handleBillingDataChangeNumber(e, index)
                        }
                      />

                      {Object.entries(validationError)
                        ?.filter(([key, value]) =>
                          key.includes(`${index}.bpd_ld_liquidityDamage`)
                        )
                        ?.map(([key, value]) => (
                          <p className="m-0 text-danger" key={key}>
                            {value[0]}
                          </p>
                        ))}
                    </div>
                  </div>
                  <div className="">
                    <div className="form-group mb-3">
                      <label htmlFor="" className="form-label">
                        Penalty (Delay)
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="bpd_penalty"
                        name="bpd_penalty"
                        placeholder="Enter Penalty"
                        value={element.bpd_penalty}
                        onChange={(e) =>
                          handleBillingDataChangeNumber(e, index)
                        }
                      />

                      {Object.entries(validationError)
                        ?.filter(([key, value]) =>
                          key.includes(`${index}.bpd_penalty`)
                        )
                        ?.map(([key, value]) => (
                          <p className="m-0 text-danger" key={key}>
                            {value[0]}
                          </p>
                        ))}
                    </div>
                  </div>
                  <div className="">
                    <div className="form-group mb-3">
                      <label htmlFor="" className="form-label">
                        Store Deposit
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="bpd_storeDeposite"
                        name="bpd_storeDeposite"
                        placeholder="Enter Security Deposit"
                        value={element.bpd_storeDeposite}
                        onChange={(e) =>
                          handleBillingDataChangeNumber(e, index)
                        }
                      />

                      {Object.entries(validationError)
                        ?.filter(([key, value]) =>
                          key.includes(`${index}.bpd_storeDeposite`)
                        )
                        ?.map(([key, value]) => (
                          <p className="m-0 text-danger" key={key}>
                            {value[0]}
                          </p>
                        ))}
                    </div>
                  </div>
                  <div className="">
                    <div className="form-group mb-3">
                      <label htmlFor="" className="form-label">
                        Material Deducted
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="bpd_materialDeductied"
                        name="bpd_materialDeductied"
                        placeholder="Enter Material Deducted"
                        value={element.bpd_materialDeductied}
                        onChange={(e) =>
                          handleBillingDataChangeNumber(e, index)
                        }
                      />

                      {Object.entries(validationError)
                        ?.filter(([key, value]) =>
                          key.includes(`${index}.bpd_materialDeductied`)
                        )
                        ?.map(([key, value]) => (
                          <p className="m-0 text-danger" key={key}>
                            {value[0]}
                          </p>
                        ))}
                    </div>
                  </div>
                  <div className="">
                    <div className="form-group mb-3">
                      <label htmlFor="" className="form-label">
                        Primery/ CESS Surcharge
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="bpd_primaryCessSurcharge"
                        name="bpd_primaryCessSurcharge"
                        placeholder="Enter Primery/ CESS Surcharge"
                        value={element.bpd_primaryCessSurcharge}
                        onChange={(e) =>
                          handleBillingDataChangeNumber(e, index)
                        }
                      />

                      {Object.entries(validationError)
                        ?.filter(([key, value]) =>
                          key.includes(`${index}.bpd_primaryCessSurcharge`)
                        )
                        ?.map(([key, value]) => (
                          <p className="m-0 text-danger" key={key}>
                            {value[0]}
                          </p>
                        ))}
                    </div>
                  </div>
                  <div className="">
                    <div className="form-group mb-3">
                      <label htmlFor="" className="form-label">
                        TDS(Income Tax)
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="bpd_tds"
                        name="bpd_tds"
                        placeholder="Enter TDS"
                        value={element.bpd_tds}
                        onChange={(e) =>
                          handleBillingDataChangeNumber(e, index)
                        }
                      />

                      {Object.entries(validationError)
                        ?.filter(([key, value]) =>
                          key.includes(`${index}.bpd_tds`)
                        )
                        ?.map(([key, value]) => (
                          <p className="m-0 text-danger" key={key}>
                            {value[0]}
                          </p>
                        ))}
                    </div>
                  </div>
                  <div className="">
                    <div className="form-group mb-3">
                      <label htmlFor="" className="form-label">
                        GST
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="bpd_gst"
                        name="bpd_gst"
                        placeholder="Enter GST"
                        value={element.bpd_gst}
                        onChange={(e) =>
                          handleBillingDataChangeNumber(e, index)
                        }
                      />

                      {Object.entries(validationError)
                        ?.filter(([key, value]) =>
                          key.includes(`${index}.bpd_gst`)
                        )
                        ?.map(([key, value]) => (
                          <p className="m-0 text-danger" key={key}>
                            {value[0]}
                          </p>
                        ))}
                    </div>
                  </div>

                  <div className="">
                    <div className="form-group mb-3">
                      <label htmlFor="" className="form-label">
                        Other Deduction
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="bpd_otherDeduction"
                        name="bpd_otherDeduction"
                        placeholder="Enter Other Deduction"
                        value={element.bpd_otherDeduction}
                        onChange={(e) =>
                          handleBillingDataChangeNumber(e, index)
                        }
                      />

                      {Object.entries(validationError)
                        ?.filter(([key, value]) =>
                          key.includes(`${index}.bpd_otherDeduction`)
                        )
                        ?.map(([key, value]) => (
                          <p className="m-0 text-danger" key={key}>
                            {value[0]}
                          </p>
                        ))}
                    </div>
                  </div>
                  <div className="">
                    <div className="form-group mb-3">
                      <label htmlFor="" className="form-label">
                        Total Deduction
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="bpd_totalDeduction"
                        name="bpd_totalDeduction"
                        placeholder="Enter Total Deduction"
                        value={
                          (element.bpd_totalDeduction =
                            Number(element.bpd_sd_securityDeposite) +
                            Number(element.bpd_ld_liquidityDamage) +
                            Number(element.bpd_penalty) +
                            Number(element.bpd_storeDeposite) +
                            Number(element.bpd_materialDeductied) +
                            Number(element.bpd_primaryCessSurcharge) +
                            Number(element.bpd_tds) +
                            Number(element.bpd_gst) +
                            Number(element.bpd_otherDeduction))
                        }
                        // onChange={(e) => handleBillingDataChange(e, index)}
                      />

                      {Object.entries(validationError)
                        ?.filter(([key, value]) =>
                          key.includes(`${index}.bpd_totalDeduction`)
                        )
                        ?.map(([key, value]) => (
                          <p className="m-0 text-danger" key={key}>
                            {value[0]}
                          </p>
                        ))}
                    </div>
                  </div>
                  <div className="">
                    <div className="form-group mb-3">
                      <label htmlFor="" className="form-label">
                        Payment Due
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="bpd_paymentDue"
                        name="bpd_paymentDue"
                        placeholder="Enter Due Payment"
                        value={
                          (element.bpd_paymentDue =
                            Number(element.bpd_invoiceAmount) -
                            (Number(element.bpd_amountRecieved) +
                              Number(element.bpd_totalDeduction) +
                              (element.id
                                ? element.pending_details.reduce(
                                    (total, amount) => +total + +amount.amount,
                                    0
                                  )
                                : 0)))
                        }
                        onChange={(e) => handleBillingDataChange(e, index)}
                      />
                      {Object.entries(validationError)
                        ?.filter(([key, value]) =>
                          key.includes(`${index}.bpd_paymentDue`)
                        )
                        ?.map(([key, value]) => (
                          <p className="m-0 text-danger" key={key}>
                            {value[0]}
                          </p>
                        ))}
                      {element.bpd_paymentDue < 0 && (
                        <p className="text-danger m-0 font-12">
                          Invalid Amount
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="">
                    <div className="form-group mb-3">
                      <label htmlFor="" className="form-label">
                        PO/LOA Completion Status
                      </label>
                      <input
                        // className="form-control"
                        className={`form-control ${
                          billingData[index].bpd_paymentDue <= 100 &&
                          "bg-success"
                        } ${
                          billingData[index].bpd_paymentDue > 100 && "bg-danger"
                        }`}
                        id="bpd_poloaCompletionStatus"
                        name="bpd_poloaCompletionStatus"
                        value={
                          (element.bpd_poloaCompletionStatus =
                            element.bpd_paymentDue > 100
                              ? "Pending"
                              : "Completed")
                        }
                        onChange={(e) => handleBillingDataChange(e, index)}
                      ></input>
                      {Object.entries(validationError)
                        ?.filter(([key, value]) =>
                          key.includes(`${index}.bpd_poloaCompletionStatus`)
                        )
                        ?.map(([key, value]) => (
                          <p className="m-0 text-danger" key={key}>
                            {value[0]}
                          </p>
                        ))}
                    </div>
                  </div>

                  <div className="">
                    <label htmlFor="" className="form-label">
                      Action
                    </label>
                    <div>
                      <button
                        type="button"
                        className="btn btn-success me-2 fw-bolder"
                        onClick={() => addBillDetail()}
                      >
                        +
                      </button>
                      <button
                        type="button"
                        className="btn btn-danger fw-bolder"
                        onClick={() => removeBillingData(index)}
                        hidden={index === 0}
                      >
                        -
                      </button>
                    </div>
                  </div>
                </>
              );
            })}
            <>
              {billingData.length > 1 && (
                <>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div className="">
                    <div className="form-group mb-3">
                      <label htmlFor="" className="form-label">
                        Total Invoice Amount
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Amount"
                        readOnly
                        value={billingData.reduce(
                          (acc, item) => acc + Number(item.bpd_invoiceAmount),
                          0
                        )}
                      />
                    </div>
                  </div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div className="">
                    {/* <div className="form-group mb-3">
                      <label htmlFor="" className="form-label">
                        Total Amount Received
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder=""
                        value={billingData.reduce(
                          (acc, item) => acc + Number(item.bpd_amountRecieved),
                          0
                        )}
                        readOnly
                      />
                    </div> */}
                  </div>
                  <div className="">
                    <div className="form-group mb-3">
                      <label htmlFor="" className="form-label">
                        Total Security Deposit
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder=""
                        readOnly
                        value={billingData.reduce(
                          (acc, item) =>
                            acc + Number(item.bpd_sd_securityDeposite),
                          0
                        )}
                      />
                    </div>
                  </div>
                  <div className="">
                    <div className="form-group mb-3">
                      <label htmlFor="" className="form-label">
                        Total Liquidity Damage
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder=""
                        readOnly
                        value={billingData.reduce(
                          (acc, item) =>
                            acc + Number(item.bpd_ld_liquidityDamage),
                          0
                        )}
                      />
                    </div>
                  </div>
                  <div className="">
                    <div className="form-group mb-3">
                      <label htmlFor="" className="form-label">
                        Total Penalty (Delay)
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder=""
                        readOnly
                        value={billingData.reduce(
                          (acc, item) => acc + Number(item.bpd_penalty),
                          0
                        )}
                      />
                    </div>
                  </div>
                  <div className="">
                    <div className="form-group mb-3">
                      <label htmlFor="" className="form-label">
                        Total Store Deposit
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder=""
                        readOnly
                        value={billingData.reduce(
                          (acc, item) => acc + Number(item.bpd_storeDeposite),
                          0
                        )}
                      />
                    </div>
                  </div>
                  <div className="">
                    <div className="form-group mb-3">
                      <label htmlFor="" className="form-label">
                        Total Material Deducted
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder=""
                        readOnly
                        value={billingData.reduce(
                          (acc, item) =>
                            acc + Number(item.bpd_materialDeductied),
                          0
                        )}
                      />
                    </div>
                  </div>
                  <div className="">
                    <div className="form-group mb-3">
                      <label htmlFor="" className="form-label">
                        Total Primery/ CESS Surcharge
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder=""
                        readOnly
                        value={billingData.reduce(
                          (acc, item) =>
                            acc + Number(item.bpd_primaryCessSurcharge),
                          0
                        )}
                      />
                    </div>
                  </div>
                  <div className="">
                    <div className="form-group mb-3">
                      <label htmlFor="" className="form-label">
                        Total TDS (Income Tax)
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder=""
                        readOnly
                        value={billingData.reduce(
                          (acc, item) => acc + Number(item.bpd_tds),
                          0
                        )}
                      />
                    </div>
                  </div>
                  <div className="">
                    <div className="form-group mb-3">
                      <label htmlFor="" className="form-label">
                        Total GST
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder=""
                        readOnly
                        value={billingData.reduce(
                          (acc, item) => acc + Number(item.bpd_gst),
                          0
                        )}
                      />
                    </div>
                  </div>
                  <div className="">
                    <div className="form-group mb-3">
                      <label htmlFor="" className="form-label">
                        Total Other Deduction
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder=""
                        readOnly
                        value={billingData.reduce(
                          (acc, item) => acc + Number(item.bpd_otherDeduction),
                          0
                        )}
                      />
                    </div>
                  </div>
                  <div className="">
                    <div className="form-group mb-3">
                      <label htmlFor="" className="form-label">
                        Total Deduction
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder=""
                        readOnly
                        value={billingData.reduce(
                          (acc, item) => acc + Number(item.bpd_totalDeduction),
                          0
                        )}
                      />
                    </div>
                  </div>
                  <div className="">
                    <div className="form-group mb-3">
                      <label htmlFor="" className="form-label">
                        Total Payment Due
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder=""
                        readOnly
                        value={billingData.reduce(
                          (acc, item) => acc + Number(item.bpd_paymentDue),
                          0
                        )}
                      />
                    </div>
                  </div>
                </>
              )}
            </>
          </div>
          <div className="text-end">
            {updating ? (
              <div className="">
                <i className="fa fa-spinner fa-spin fa-3x text-danger me-3 mt-3"></i>
                <p>Updating ...</p>
              </div>
            ) : (
              <Button
                variant="danger"
                type="submit"
                onClick={(e) => storeBillingData(e)}
              >
                Update
              </Button>
            )}
          </div>
        </form>
      )}

      {show ? (
        <BillingupdatePayment
          show={show}
          setShow={setShow}
          handleBillingDataChange={handleBillingDataChange}
          addBillingInput={addBillingInput}
          billingData={billingData[modalData]}
          fetchBillingPayment={fetchBillingPayment}
          setFetchBillingPayment={setFetchBillingPayment}
        />
      ) : null}
    </>
  );
}

export default Billing;
