import React, { useState } from "react";
import Spinner from "react-bootstrap/Spinner";
import { MdErrorOutline } from "react-icons/md";
import Modal from "react-bootstrap/Modal";
import "./Style.css";
import { Link } from "react-router-dom";

function ProjectCardView({ loader, projects }) {
  const [descriptionModal, setDescriptionModal] = useState(false);
  const [projectDescription, setProjectDescription] = useState(null);
  const [zoneModal, setZoneModal] = useState(false);
  const [value, setValue] = useState(null);

  function formatDate(value) {
    if (!value) {
      return false;
    }
    var date = new Date(value);
    var formattedDate = date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
      day: "2-digit",
    });
    return formattedDate;
  }

  function getDescription(id) {
    let getProject = projects.find((element) => element.id === id);
    let getDescription = getProject.pd_projectItemDescription;
    setDescriptionModal(true);
    setProjectDescription(getDescription);
  }

  function handleClose() {
    setDescriptionModal(false);
    setProjectDescription(null);
    setZoneModal(false);
  }

  function generateInitials(categoryName) {
    if (categoryName) {
      const words = categoryName.split(" ");
      const initials = words
        .map((word) => word.charAt(0).toUpperCase())
        .join("");
      return initials;
    }
  }

  const supplyColor = {
    backgroundColor: "#FFBED8",
  };
  const suppltandinstallColor = {
    backgroundColor: "#C1F4C5",
  };
  const turnKeyColor = {
    backgroundColor: "#A0E9FF",
  };
  const vcbColor = {
    backgroundColor: "#FFD9C0",
  };
  const furnishingColor = {
    backgroundColor: "#FFD9C0",
  };

  return (
    <>
      {loader ? (
        <div
          className="d-flex align-items-center justify-content-center"
          style={{ height: "70vh" }}
        >
          <Spinner animation="grow" variant="danger" />
          <Spinner animation="grow" variant="danger" />
          <Spinner animation="grow" variant="danger" />
        </div>
      ) : (
        <div className="row">
          {projects.length > 0 ? (
            projects.map((element, index) => {
              return (
                <div className="col-lg-4" key={index}>
                  <div
                    className="card project-card"
                    style={{
                      borderRadius: "10px",
                      border: "1px solid #d6d2d2",
                    }}
                  >
                    <div className="dropdown position-absolute end-0 p-2">
                      <Link
                        to=""
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <i className="fa-solid fa-ellipsis"></i>
                      </Link>
                      <ul className="dropdown-menu">
                        <li>
                          <Link
                            className="dropdown-item"
                            to={`/projects/edit-project-form/${element.id}`}
                          >
                            Edit
                          </Link>
                        </li>
                      </ul>
                    </div>
                    <div className="card-body">
                      <div className="media mb-3 mt-2">
                        <Link to={`/projects/projectdetail/${element.id}`}>
                          <h5
                            className="m-0 font-12 p-3 rounded-circle categorty-name"
                            style={
                              element.projectCategoryName ===
                              "Supply & Installation"
                                ? suppltandinstallColor
                                : element.projectCategoryName === "Supply"
                                ? supplyColor
                                : element.projectCategoryName === "VCB Supply"
                                ? vcbColor
                                : element.projectCategoryName === "Turn Key"
                                ? turnKeyColor
                                : element.projectCategoryName ===
                                  "Furnishing/ Up-gradation/ Refurbishment"
                                ? furnishingColor
                                : {}
                            }
                          >
                            {element.projectCategoryName ? (
                              generateInitials(element.projectCategoryName)
                            ) : (
                              <MdErrorOutline />
                            )}
                          </h5>
                        </Link>
                        <div className="media-body align-self-center text-truncate ms-2">
                          <Link to={`/projects/projectdetail/${element.id}`}>
                            <h4 className="m-0 fw-bold text-dark font-18">
                              {element.pd_projectTitle}
                            </h4>
                          </Link>
                          <p className="text-muted  mb-0 font-13">
                            <span className="text-dark">File No : </span>{" "}
                            {element.pd_fileNo}
                          </p>
                        </div>
                      </div>

                      <div
                        className="project-description"
                        style={{
                          minHeight: "6rem",
                          maxHeight: "6rem",
                          cursor: "pointer",
                        }}
                        onClick={() => getDescription(element.id)}
                      >
                        <p className="mb-2">
                          {element?.pd_projectItemDescription.length > 160 ? (
                            <>
                              {element.pd_projectItemDescription.slice(0, 160)}
                              <span
                                className="text-primary"
                                style={{ textDecoration: "underline" }}
                              >
                                {" "}
                                read more
                              </span>
                            </>
                          ) : (
                            element.pd_projectItemDescription
                          )}
                        </p>
                      </div>
                      <div>
                        <div className="d-flex justify-content-between mb-2">
                          <h6 className="fw-semibold m-0">
                            Start Date :{" "}
                            <span className="text-muted font-weight-normal">
                              {" "}
                              {formatDate(element.pd_startDate)}
                            </span>
                          </h6>
                          <h6 className="fw-semibold m-0">
                            End Date :{" "}
                            <span className="text-muted font-weight-normal">
                              {formatDate(element.pd_endDate)}
                            </span>
                          </h6>
                        </div>
                        <div className="d-block mb-3">
                          <div className="row">
                            <div className="col-md-auto">
                              <h6 className="fw-semibold mb-2">
                                Railway Zone :{" "}
                              </h6>
                            </div>
                            <div
                              className="col-md-8 ps-0"
                              onClick={() => {
                                setZoneModal(true);
                                setValue(element.pd_railwayZone);
                              }}
                              style={{ cursor: "pointer" }}
                            >
                              <h6
                                className="text-muted font-weight-normal font-14"
                                style={{ cursor: "pointer" }}
                              >
                                {" "}
                                {element.pd_railwayZone?.length > 25
                                  ? element.pd_railwayZone?.slice(0, 25) +
                                    " ..."
                                  : element.pd_railwayZone}
                              </h6>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-md-auto">
                              <h6 className="fw-semibold">PO No : </h6>
                            </div>
                            <div
                              className="col-md-6 ps-0"
                              onClick={() => {
                                setZoneModal(true);
                                setValue(element.pd_poloaNo);
                              }}
                              style={{ cursor: "pointer" }}
                            >
                              <h6 className="text-muted font-weight-normal font-14">
                                {element.pd_poloaNo?.length > 25
                                  ? element.pd_poloaNo?.slice(0, 25) + " ..."
                                  : element.pd_poloaNo}
                              </h6>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-auto ">
                            <h6 className="fw-semibold m-0">PO/LOA Date: </h6>
                          </div>
                          <div className="col-md-6 ">
                            <h6 className="text-muted font-weight-normal font-14 m-0">
                              {" "}
                              {formatDate(element.pd_poloaDate)}
                            </h6>
                          </div>
                        </div>

                        {/* <div className="mt-2">
                          <p className="text-muted text-end mb-1">
                            {(element?.completedTickets *
                              element?.totalTickets) /
                              100}
                            % Complete
                          </p>
                          <div
                            className="progress mb-2"
                            style={{ height: "4px" }}
                          >
                            <div
                              className="progress-bar bg-danger"
                              role="progressbar"
                              style={{
                                width: `${
                                  (element?.completedTickets *
                                    element?.totalTickets) /
                                  100
                                }`,
                              }}
                              aria-valuenow="100"
                              aria-valuemin="0"
                              aria-valuemax="100"
                            ></div>
                          </div>
                          <div className="d-flex justify-content-between">
                            <ul className="list-inline mb-0 align-self-center">
                              <li className="list-item d-inline-block me-2">
                                <Link className="" to="#">
                                  <i className="mdi mdi-format-list-bulleted text-success font-15"></i>
                                  <span className="text-muted fw-bold">
                                    &nbsp;{element?.completedTickets}/
                                    {element?.totalTickets}
                                  </span>
                                </Link>
                              </li>
                              <li className="list-item d-inline-block">
                                <Link className="" to="#">
                                  <i className="mdi mdi-comment-outline text-primary font-15"></i>
                                  <span className="text-muted fw-bold">
                                    &nbsp;{element?.totalFollowup}
                                  </span>
                                </Link>
                              </li>
                            </ul>
                          </div>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <div className="text-center">NO PROJECTS</div>
          )}
        </div>
      )}

      <Modal
        show={descriptionModal}
        onHide={handleClose}
        centered
        backdrop="static"
      >
        <Modal.Header closeButton className="m-0 p-3">
          <Modal.Title className="m-0 p-0">Description</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div
            style={{ maxHeight: "300px", overflowY: "scroll" }}
            className="my-3"
          >
            {projectDescription}
          </div>
        </Modal.Body>
      </Modal>

      <Modal show={zoneModal} onHide={handleClose} centered>
        <Modal.Body>{value}</Modal.Body>
      </Modal>
    </>
  );
}

export default ProjectCardView;
