import axios from "axios";
import { useFormik } from "formik";
import React, { useState, useEffect } from "react";
import { FaEye } from "react-icons/fa";
import { MdEdit } from "react-icons/md";
import { MdDelete } from "react-icons/md";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import CommonInput from "../CommonInput";
import { FaCheck } from "react-icons/fa6";
import Modals from "../Modal";
import CommonOptionsInvoice from "../CommonOptionsInvoice";
import CommonOptionsSdPaidExempted from "../CommonOptionsSdPaidExempted";

function SdDetailPreview({ sdData, renderState, setRenderState, billing }) {
  const [data, setData] = useState([]);
  const [rowid, setRowid] = useState([]);
  const [spinner, setSpinner] = useState(false);
  const param = useParams();
  const project_id = Number(param.id);
  const [disabled, setdisabled] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const apiurl = process.env.REACT_APP_DEV_API_KEY_NEW;
  const user = JSON.parse(localStorage.getItem("profile"));
  const user_id = user?.response?.userDetails?.id;
  const getToken = user.response.token;
  const bearer_token = `Bearer ${getToken}`;
  const sdInitial = {
    sd_toPay: 0,
    sd_paidExempted: "",
    sd_paidTransactionNo: "",
    sd_paidTransactionNoDate: "",
    sd_expiryDate: "",
    sd_returnDate: "",
    sd_returnAmount: 0,
    sd_due: 0,
    sd_remark: "",
    sd_attachement: [],
    row_id: "",
    sd_returnStatus: "",
    sd_adjustWithInvoiceNo: "",
  };
  const initialValues = {
    sdMultipleData: sdInitial,
  };
  function editDataOnserver(url, value, project_id, row_id) {
    setSpinner(true);
    axios
      .post(
        apiurl + url,
        { ...value, user_id, project_id, row_id },
        {
          headers: {
            Authorization: bearer_token,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        if (response.data.success) {
          setSpinner(false);
          setRenderState(!renderState);
          toast.success("Data added successfully");
        }
      })
      .catch((error) => {
        setSpinner(false);
        toast.error("Something went wrong");
      });
    return spinner;
  }

  const handleFileChange = (e, i, row_id) => {
    const files = Array.from(e.target.files);
    Promise.all(files.map((file) => readFileAsBase64(file))).then(
      (filesArray) => {
        let newValues = values.sdMultipleData;
        newValues.sd_attachement = filesArray;
        newValues.row_id = row_id;
        setRowid(sdData[i].row_id);
      }
    );
  };

  const readFileAsBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = () => {
        resolve({ fileName: file.name, base64: reader.result });
      };

      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(file);
    });
  };
  function multHandleChange(e, i) {
    const { name, value } = e.target;
    if (name === "sd_adjustWithInvoiceNo" && value) {
      const selectedInvoice = billing.find(
        (invoice) => invoice.bpd_invoiceNo === value
      );
      const newValues = values.sdMultipleData;
      newValues.sd_toPay = selectedInvoice.bpd_sd_securityDeposite;
      newValues.sd_adjustWithInvoiceNo = value;
      setData((prevData) => {
        const newData = [...prevData];
        newData[i] = values.sdMultipleData;
        return newData;
      });
    } else {
      const newValues = values.sdMultipleData;
      name === "sd_paidExempted" && value !== "Adj Against Invoice"
        ? ((newValues.sd_adjustWithInvoiceNo = ""), (newValues.sd_toPay = 0))
        : (newValues[name] = value);
      newValues[name] = value;
      newValues.sd_due = newValues.sd_toPay - newValues.sd_returnAmount;
      newValues.sd_returnStatus =
        newValues.sd_toPay - newValues.sd_returnAmount !== 0
          ? "Pending"
          : "Completed";
      setRowid(sdData[i].row_id);
      setData((prevData) => {
        const newData = [...prevData];
        newData[i] = values.sdMultipleData;
        return newData;
      });
    }
  }
  const { values, errors, handleBlur, handleSubmit, touched, setValues } =
    useFormik({
      initialValues: initialValues,
      onSubmit: (values) => {
        editDataOnserver("updateuntiprojectsd", values, project_id, rowid);
      },
    });
  useEffect(() => {
    const Arraylength = sdData.length;
    const generateNewArray = Array.from({ length: Arraylength }, () => true);
    const generateNewArrayForAttachment = Array.from(
      { length: Arraylength },
      () => false
    );
    setData(sdData);
    if (Arraylength) {
      setdisabled(generateNewArray);
      setModalShow(generateNewArrayForAttachment);
    }
  }, [sdData]);

  function deleteRow(row_id) {
    axios
      .post(
        apiurl + "deleteuntiprojectsd",
        { user_id, project_id, row_id },
        {
          headers: {
            Authorization: bearer_token,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        if (response.data.success) {
          setSpinner(false);
          setRenderState(!renderState);
          toast.success("Row Deleted Successfully");
        }
      })
      .catch((error) => {
        setSpinner(false);
        toast.error("Something went wrong");
      });
  }

  return (
    <>
      {data?.map((element, i) => (
        <React.Fragment key={i}>
          <div className="">
            <label
              htmlFor=""
              className="form-label"
              style={{ textAlign: "center" }}
            >
              Type
            </label>
            <h5
              className="text-danger fw-bold m-0 form-control border-0 ps-0"
              style={{ text: "center" }}
            >
              SD Details
            </h5>
          </div>
          <div className="form-group mb-12 col">
            <CommonOptionsSdPaidExempted
              handleChange={(e) => multHandleChange(e, i)}
              values={element?.sd_paidExempted}
              name="sd_paidExempted"
              labelValue="Paid/Exempted"
              handleBlur={handleBlur}
              readOnly={disabled[i]}
            />
          </div>

          <div className="form-group mb-12 col">
            <CommonOptionsInvoice
              handleChange={(e) => multHandleChange(e, i)}
              values={element?.sd_adjustWithInvoiceNo}
              name="sd_adjustWithInvoiceNo"
              labelValue="Invoice No."
              handleBlur={handleBlur}
              readOnly={disabled[i]}
              billing={billing}
              disabled={element?.sd_paidExempted !== "Adj Against Invoice"}
            />
          </div>
          <div className="form-group mb-12 col">
            <CommonInput
              handleChange={(e) => multHandleChange(e, i)}
              values={element?.sd_toPay}
              type="text"
              name="sd_toPay"
              labelValue="Amount"
              handleBlur={handleBlur}
              touched={touched}
              errors={errors}
              onBlur={handleBlur}
              readOnly={disabled[i]}
              disabled={
                element?.sd_paidExempted === "Exempted" ||
                element?.sd_paidExempted === "Not Applicable"
              }
            />
          </div>

          <div className="form-group mb-12 col">
            <CommonInput
              handleChange={(e) => multHandleChange(e, i)}
              values={element?.sd_paidTransactionNo}
              type="text"
              name="sd_paidTransactionNo"
              labelValue="Transaction No."
              handleBlur={handleBlur}
              touched={touched}
              errors={errors}
              placeHolder={"SD Paid Transaction no."}
              readOnly={disabled[i]}
              disabled={
                element?.sd_paidExempted === "Exempted" ||
                element?.sd_paidExempted === "Not Applicable"
              }
            />
          </div>

          <div className="form-group mb-12 col">
            <CommonInput
              handleChange={(e) => multHandleChange(e, i)}
              values={element?.sd_paidTransactionNoDate}
              type="date"
              name="sd_paidTransactionNoDate"
              labelValue="Transaction Date"
              handleBlur={handleBlur}
              touched={touched}
              errors={errors}
              readOnly={disabled[i]}
              disabled={
                element?.sd_paidExempted === "Exempted" ||
                element?.sd_paidExempted === "Not Applicable"
              }
            />
          </div>
          <div className="form-group mb-12 col">
            <CommonInput
              handleChange={(e) => multHandleChange(e, i)}
              values={element?.sd_expiryDate}
              type="date"
              name="sd_expiryDate"
              labelValue="Expiry Date"
              handleBlur={handleBlur}
              touched={touched}
              errors={errors}
              readOnly={disabled[i]}
              disabled={
                element?.sd_paidExempted === "Exempted" ||
                element?.sd_paidExempted === "Not Applicable"
              }
            />
          </div>
          <div className="form-group mb-12 col">
            <CommonInput
              handleChange={(e) => multHandleChange(e, i)}
              values={element?.sd_returnDate}
              type="date"
              name="sd_returnDate"
              labelValue="Return Date"
              handleBlur={handleBlur}
              touched={touched}
              errors={errors}
              readOnly={disabled[i]}
              disabled={
                element?.sd_paidExempted === "Exempted" ||
                element?.sd_paidExempted === "Not Applicable"
              }
            />
          </div>
          <div className="form-group mb-12 col">
            <CommonInput
              handleChange={(e) => multHandleChange(e, i)}
              values={element?.sd_returnAmount}
              type="text"
              name="sd_returnAmount"
              labelValue="Return Amount"
              handleBlur={handleBlur}
              touched={touched}
              errors={errors}
              onBlur={handleBlur}
              readOnly={disabled[i]}
              disabled={
                element?.sd_paidExempted === "Exempted" ||
                element?.sd_paidExempted === "Not Applicable"
              }
            />
          </div>
          <div className="form-group mb-12 col">
            <CommonInput
              values={
                Number(element?.sd_toPay) - Number(element?.sd_returnAmount)
              }
              type="text"
              name="sd_due"
              labelValue="Due Amount"
              handleBlur={handleBlur}
              touched={touched}
              errors={errors}
              onBlur={handleBlur}
              handleChange={(e) => multHandleChange(e, i)}
            />
          </div>
          <div className="form-group mb-12 col">
            <CommonInput
              values={Number(element?.sd_due) !== 0 ? "Pending" : "Completed"}
              type="text"
              name="sd_returnStatus"
              labelValue="Return Status"
              handleBlur={handleBlur}
              touched={touched}
              errors={errors}
              onBlur={handleBlur}
              handleChange={(e) => multHandleChange(e, i)}
              className={
                Number(element?.sd_due) !== 0 ? "bg-danger" : "bg-success"
              }
            />
          </div>
          <div className="form-group mb-12 col">
            <CommonInput
              handleChange={(e) => multHandleChange(e, i)}
              values={element?.sd_remark}
              type="text"
              name="sd_remark"
              labelValue="Remark"
              handleBlur={handleBlur}
              touched={touched}
              errors={errors}
              onBlur={handleBlur}
              placeHolder={"SD Remark"}
              readOnly={disabled[i]}
              disabled={
                element?.sd_paidExempted === "Exempted" ||
                element?.sd_paidExempted === "Not Applicable"
              }
            />
          </div>
          <div className="form-group mb-12 col">
            <CommonInput
              handleChange={(e) => handleFileChange(e, i, element.row_id)}
              type="file"
              name="sd_attachement"
              labelValue="Attachment"
              handleBlur={handleBlur}
              touched={touched}
              errors={errors}
              readOnly={disabled[i]}
              disabled={
                element?.sd_paidExempted === "Exempted" ||
                element?.sd_paidExempted === "Not Applicable"
              }
            />
          </div>

          <div className="col-md-12">
            <div className="form-group mb-3">
              <label htmlFor="" className="form-label">
                Action
              </label>
              <div>
                {disabled[i] ? (
                  <span
                    className="fs-4 me-2"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setdisabled((prevDisabled) => {
                        const updatedDisabled = [...prevDisabled];
                        updatedDisabled[i] = false;
                        return updatedDisabled;
                      });
                      setValues({
                        ...values,
                        sdMultipleData: { ...sdData[i], sd_attachement: [] },
                      });
                    }}
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Edit"
                  >
                    {" "}
                    <MdEdit />{" "}
                  </span>
                ) : null}

                {!disabled[i] ? (
                  <span
                    className="fs-4"
                    style={{ cursor: "pointer" }}
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Update"
                    onClick={(e) => {
                      handleSubmit(e);
                      setdisabled((prevDisabled) => {
                        const updatedDisabled = [...prevDisabled];
                        updatedDisabled[i] = true;
                        return updatedDisabled;
                      });
                      setRowid(sdData[i].row_id);
                    }}
                  >
                    {" "}
                    <FaCheck />{" "}
                  </span>
                ) : null}
                <span
                  data-bs-toggle="modal"
                  data-bs-target="#sdmodalDelete"
                  className="fs-4"
                  style={{ cursor: "pointer" }}
                  onClick={() => setRowid(sdData[i].row_id)}
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Delete"
                >
                  {" "}
                  <MdDelete />{" "}
                </span>
                <span
                  style={{
                    cursor: "pointer",
                    textAlign: "center",
                    width: "50px",
                  }}
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Attachment Preview "
                  onClick={() => {
                    setModalShow((prevDisabled) => {
                      const updatedDisabled = [...prevDisabled];
                      updatedDisabled[i] = true;
                      return updatedDisabled;
                    });
                  }}
                >
                  <FaEye style={{ height: "1.2rem", width: "1.2rem" }} />
                </span>
              </div>
            </div>
          </div>
          <Modals
            show={modalShow[i]}
            onHide={() =>
              setModalShow((prevDisabled) => {
                const updatedDisabled = [...prevDisabled];
                updatedDisabled[i] = false;
                return updatedDisabled;
              })
            }
            data={element?.sd_attachement}
            renderState={renderState}
            setRenderState={setRenderState}
            url={"setdeletesdattechment"}
          />
        </React.Fragment>
      ))}
      {/* Delete Model start */}
      <div className="modal fade" id="sdmodalDelete">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body py-5 text-center">
              <h4 className="lh-base mb-4">
                Are you sure you want to <br /> delete this record
              </h4>
              <button
                type="button"
                className="btn btn-success me-3"
                onClick={() => deleteRow(rowid)}
                data-bs-dismiss="modal"
              >
                Yes
              </button>
              <button
                type="button"
                className="btn btn-danger"
                data-bs-dismiss="modal"
                // onClick={() => setShowModal(false)}
              >
                No
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SdDetailPreview;
