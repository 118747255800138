import React, { useEffect, useState } from "react";
import { fileToBase64 } from "../../Components/serviceFunctions/base64";
import Select from "react-select";
import { errorsMessage } from "../../constant";
import { getVendorDropDown } from "../../Purchase/purchaseApi";

function InvoiceStructure({
  handleChange,
  values,
  serverSideValidation,
  errors,
  handleBlur,
  touched,
  setFieldValue,
  projectList,
  loading,
  setSearchTerm,
}) {
  const userProfile = JSON.parse(localStorage.getItem("profile"));
  const user_id = userProfile?.response?.userDetails?.id;
  const [users, setUsers] = useState([]);
  const customStyles = {
    indicatorSeparator: (provided) => ({
      ...provided,
      backgroundColor: "red", // Change loader color here
    }),

    loadingIndicator: (provided) => ({
      ...provided,
      color: "red", // Change loader color here
    }),
  };

  function getDropDown() {
    getVendorDropDown({ user_id })
      .then((res) => {
        setUsers(res?.data?.response?.PartyMasterDropdownList?.users);

      })
      .catch((err) => {
        if (err) {
          errorsMessage(err?.response?.status);
        }
      })
      .finally(() => {
        // setLoading(false);
      });
  }
  useEffect(() => {
    getDropDown();
  }, []);

  

  return (
    <div className="row">
      <div className="col-3">
        <div className="input-group me-3 d-block">
          <label htmlFor="" className="form-label fw-bold font-16">
            Railway P.O. No.
          </label>
          <Select
            placeholder="Select Project"
            style={{ width: "100%" }}
            className="form-select-control border-secondary"
            name="project_id"
            id="project_id"
            isSearchable={true}
            options={[
              { id: "", project_title: "Select an PO. No." },
              ...projectList,
            ]}
            getOptionLabel={(projectList) => projectList["pd_poloaNo"]}
            getOptionValue={(projectList) => projectList["id"]}
            isLoading={loading}
            value={
              projectList.find((option) => option.id === values.project_id) || {
                id: "",
                pd_poloaNo: "Select an PO. No.",
              }
            }
            onChange={(value) => {
              setFieldValue("project_id", value.id);
            }}
            onInputChange={(value) => setSearchTerm(value)}
            theme={(theme) => ({
              ...theme,
              borderRadius: 4,
              colors: {
                ...theme.colors,
                primary25: "lightgrey",
                primary: "lightgrey",
              },
            })}
            styles={customStyles}
          />
        </div>
        <p className="text-danger m-0">
          {serverSideValidation
            ? serverSideValidation.project_id
            : touched.project_id && errors.project_id
            ? errors.project_id
            : null}
        </p>
      </div>
      <div className="col-3">
        <label htmlFor="" className="form-label fw-bold font-16">
          Invoice Date
        </label>
        <input
          className="form-control"
          placeholder="Vehicle Number"
          name="invoice_date"
          type="date"
          value={values.invoice_date}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        <p className="text-danger m-0">
          {serverSideValidation
            ? serverSideValidation.invoice_date
            : touched.invoice_date && errors.invoice_date
            ? errors.invoice_date
            : null}
        </p>
      </div>
      <div className="col-3">
        <label htmlFor="" className="form-label fw-bold font-16">
          Invoice No.
        </label>
        <input
          className="form-control"
          placeholder="Enter Invoice No."
          name="invoice_no"
          value={values.invoice_no}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        <p className="text-danger m-0">
          {serverSideValidation
            ? serverSideValidation.invoice_no
            : touched.invoice_no && errors.invoice_no
            ? errors.invoice_no
            : null}
        </p>
      </div>
      <div className="col-3">
        <label htmlFor="" className="form-label fw-bold font-16">
          Invoice Attachment
        </label>
        <input
          type="file"
          className="form-control"
          placeholder="Purchase Order Ref No."
          name="invoice_pdf"
          onChange={(e) =>
            fileToBase64(e.target.files[0], "invoice_pdf", setFieldValue)
          }
        />
        <p className="text-danger m-0">
          {serverSideValidation
            ? serverSideValidation.invoice_pdf
            : touched.invoice_pdf && errors.invoice_pdf
            ? errors.invoice_pdf
            : null}
        </p>
      </div>
      <div className="col-3 mt-4">
        <label htmlFor="" className="form-label fw-bold font-16">
          Received by
        </label>
        <select
          className="form-control"
          name="user_id"
          value={values.user_id}
          onChange={handleChange}
          onBlur={handleBlur}
        >
          <option value={""}>Select</option>
          {users?.map((user) => (
            <option key={user?.id} value={user?.id}>
              {user?.name}
            </option>
          ))}
        </select>
        <p className="text-danger m-0">
          {serverSideValidation
            ? serverSideValidation.user_id
            : touched.user_id && errors.user_id
            ? errors.user_id
            : null}
        </p>
      </div>
    </div>
  );
}

export default InvoiceStructure;
