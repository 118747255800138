import React, { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import Spinner from "react-bootstrap/Spinner";
import Footer from "../../Components/Footer";
import { departmentDropdown } from "../../redux/api";
import { Modal } from "react-bootstrap";

function DepartmentList() {
  const [departmentList, setDepartmentList] = useState([]);
  const [loader, setLoader] = useState(true);
  const [show, setShow] = useState(false);
  const user = JSON.parse(localStorage.getItem("profile"));
  const user_id = user?.response?.userDetails?.id;

  function getDepartmentList() {
    setLoader(true);
    departmentDropdown({ user_id: user_id })
      .then((res) => {
        setDepartmentList(res?.data?.response?.departmentDropDown);
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
      });
  }

  useEffect(() => {
    getDepartmentList();
  }, []);
  return (
    <>
      <div className="page-wrapper">
        <div className="page-content-tab">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div className="page-title-box">
                  <div className="float-end">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item"></li>
                      <li className="breadcrumb-item active">Departments</li>
                    </ol>
                  </div>
                  <h4 className="page-title">DEPARTMENTS</h4>
                </div>
              </div>
            </div>
            {loader ? (
              <div
                className="mt-5 d-flex align-items-center  justify-content-center"
                style={{ height: "70vh" }}
              >
                <Spinner animation="grow" variant="danger" />
                <Spinner animation="grow" variant="danger" />
                <Spinner animation="grow" variant="danger" />
              </div>
            ) : (
              <div className="row">
                <div className="table-responsive">
                  <table className="table table-striped">
                    <thead className="thead-light">
                      <tr>
                        <th className="fw-bolder">S. No.</th>
                        <th>Role</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {departmentList?.map((e, i) => {
                        return (
                          <tr key={e.id}>
                            <td>{i + 1}</td>
                            <td>
                              <NavLink to={`/department-list/assign-permission/${e.id}`}>
                                {e.name}
                              </NavLink>
                            </td>
                            <td>
                              <NavLink to={`/department-list/assign-permission/${e.id}`}>
                                <i
                                  className="las la-key  mx-1"
                                  style={{
                                    fontSize: "24px",
                                    cursor: "pointer",
                                  }}
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title="Access Role Permission"
                                ></i>
                              </NavLink>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <Modal show={show} centered>
        <Modal.Body className="text-center">
            
        </Modal.Body>
      </Modal>
      <Footer />
    </>
  );
}

export default DepartmentList;
