import * as Yup from "yup";

export const loginSchema = Yup.object({
  email: Yup.string()
    .email("Please enter a valid email address.")
    .matches(/@[^.]*\./, "Please enter a valid email address")
    .required("Please enter your email.")
    .min(10)
    .max(40),
  // password: Yup.string().required("This field is required.").matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/, "Enter a valid email or password").min(6).max(16),
  password: Yup.string().required("Please enter your password."),
});

export const departSchema = Yup.object({
  name: Yup.string()
    .required("This field is required")
    .min(3, "Name should be more than 3 characters")
    .max(30, "Name should be less than 30 characters"),
  email: Yup.string()
    .email("Please enter a valid email address")
    .matches(/@[^.]*\./, "Please enter a valid email address")
    .required("This field is required")
    .min(10)
    .max(40),
  // department_project : Yup.array().required("Please Select an Option"),
  image: Yup.mixed().required("Image is required"),
  department_head: Yup.string().required("This field is required"),
  description: Yup.string()
    .required("This field is required")
    .min(20, "Description should be more than 20 characters")
    .max(2000, "Description should be less than 2000 characters"),
});

export const EditdepartSchema = Yup.object({
  name: Yup.string()
    .required("This field is required")
    .min(3, "Name should be more than 3 characters")
    .max(30, "Name should be less than 30 characters"),
  email: Yup.string()
    .email("Please enter a valid email address")
    .matches(/@[^.]*\./, "Please enter a valid email address")
    .required("This field is required")
    .min(10)
    .max(40),
  image: Yup.mixed().required("Image is required"),
  description: Yup.string()
    .required("This field is required")
    .min(20, "Description should be more than 20 characters")
    .max(2000, "Description should be less than 2000 characters"),
});

export const ticketSchema = Yup.object({
  lot_id: Yup.string().required("Schedule is required"),
  ticket_type: Yup.string().required("Ticket type is required"),
  board_group_id: Yup.string().required("Board Group is required"),
  title: Yup.string().required("Ticket title is required"),
  description: Yup.string().required("Description is required"),
  assignee: Yup.string().required("Assignee is required"),
  start_date: Yup.date().required("Start date is required"),
  due_date: Yup.date().required("Due date is required"),
  priority: Yup.string().required("Priority is required"),
  estimated_time: Yup.number()
    .typeError("Enter valid number please")
    .required("Estimated time is required"),
  reporting_to: Yup.string().required("Reporting Person is required"),
  board_group_status_id: Yup.string().required("Group Status is required"),
  required_qty: Yup.number().typeError("Enter valid number please"),
});

export const editTicketSchema = Yup.object({
  lot_id: Yup.string().required("Lot is required"),
  ticket_type: Yup.string().required("Ticket type is required"),
  board_group_id: Yup.string().required("Board Group is required"),
  title: Yup.string().required("Ticket title is required"),
  description: Yup.string().required("Description is required"),
  assignee: Yup.string().required("Assignee is required"),
  start_date: Yup.date().required("Start date is required"),
  due_date: Yup.date().required("Due date is required"),
  priority: Yup.string().required("Priority is required"),
  estimated_time: Yup.number()
    .typeError("Enter valid number please")
    .required("Estimated time is required"),
  reporting_to: Yup.string().required("Reporting Person is required"),
  board_group_status_id: Yup.string().required("Group Status is required"),
  required_qty: Yup.number().typeError("Enter valid number please"),
  completed_qty: Yup.number()
    .typeError("Enter valid number please")
    .required("Completed Quantity is required"),
});

export const userSchema = Yup.object({
  name: Yup.string().required("Name field is required").min(3).max(30),
  email: Yup.string()
    .email("Please enter a valid email address")
    .matches(/@[^.]*\./, "Please enter a valid email address")
    .required("Email is required"),
  phone: Yup.number("Please Enter a Valid Number")
    .typeError("Enter a valid number please")
    .min(10, "Must be exactly 10")
    .required("Contact Number (work) is required"),
  designation: Yup.string().required("Designation is Required"),
  reporting_person: Yup.string().required("Reporting person is required"),
  profileimage: Yup.mixed().required("Image is required"),
  department_id: Yup.string().required("Department is required"),
  boardCategory: Yup.string().required("Board Category is required"),
  department_role: Yup.string().required("Department Role is required"),
});

export const editUserSchema = Yup.object({
  name: Yup.string().required("Name field is required").min(3).max(30),
  email: Yup.string()
    .email("Please enter a valid email address")
    .matches(/@[^.]*\./, "Please enter a valid email address")
    .required("Email is required"),
  phone: Yup.number("Please Enter a Valid Number")
    .typeError("Enter a valid number please")
    .min(10, "Must be exactly 10")
    .required("Phone Number is required"),
  designation: Yup.string().required("Designation is Required"),
  reporting_person: Yup.string().required("Reporting person is required"),
  department_id: Yup.string().required("Department is required"),
  boardCategory: Yup.string().required("Board Category is required"),
});

export const addTenderchema = Yup.object({
  railwayZone: Yup.string().required("This field is required"),
  tenderNo: Yup.string().required("This field is required"),
  tenderType: Yup.string().required("This field is required"),
  contractType: Yup.string().required("This field is required"),
  tenderDocCost: Yup.string().required("This field is required"),
  advertisedValue: Yup.string().required("This field is required"),
  itemDecsription: Yup.string().required("This field is required"),
  quantity: Yup.number()
    .typeError("Please enter a valid number")
    .required("This field is required"),
  uom: Yup.string().required("This field is required"),
  publishedDate: Yup.string().required("This field is required"),
  time: Yup.string().required("This field is required"),
  higherAuthorityapproval: Yup.string().required("This field is required"),
  closingDate: Yup.string()
    .required("This field is required.")
    .when("publishedDate", (publishedDate, schema) => {
      return schema.test({
        test: function (closingDate) {
          return (
            new Date(closingDate) >= new Date(publishedDate) ||
            this.createError({
              message: "End date must be after start date",
            })
          );
        },
      });
    }),
  biddingSystem: Yup.string().required("This field is required"),
  validityOfOffer: Yup.string().required("This field is required"),
  advertisedValue: Yup.number().required("This field is required"),
  ifApplicableAmountCost: Yup.number().required("This field is required"),
  eligibilityCriteria: Yup.string().required("This field is required"),
  intendingParticipation: Yup.string().required("This field is required"),
  tenderCategory : Yup.string().required("This field is required"),
  // biddingStatus: Yup.string().required("This field is required"),
  tenderReturnAttachement: Yup.string().when("tenderReturnStatus", {
    is: "Returned",
    then: (schema) => schema.required("This field is required if Tender Return Status is Returned"),
    otherwise: (schema) => schema.notRequired(),
  }),
});
