import React from "react";
import { BarChart, Bar, CartesianGrid, XAxis, YAxis, Tooltip } from "recharts";

const DashoardBarGraph = ({ monthOrderCount }) => {
  const monthName = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  let sortedData;
  let maxCount;
  if (monthOrderCount?.length) {
    sortedData = [...monthOrderCount].sort((a, b) => a.month - b.month);
    maxCount = Math.max(...sortedData.map((item) => item.count));
  }

  return (
    <div style={{ width: "100%" }}>
      Total Awarded Tenders
      <BarChart width={1200} height={390} data={sortedData}>
        <Bar dataKey="count" fill="rgb(0, 149, 255)" stroke="none" />

        <Tooltip
          formatter={(values, name, props) => {
            const { payload } = props;
            if (payload) {
              const formatPayload = {
                ...payload,
                month: monthName[payload?.month - 1],
              };

              return [`Count: ${formatPayload.count}`];
            } else {
              return [];
            }
          }}
          cursor={{ fill: "none" }} // This removes the gray hover line
        />

        <XAxis
          dataKey="month"
          tickFormatter={(values) => monthName[values - 1]}
        />
        <YAxis domain={[0, maxCount]} />
      </BarChart>
    </div>
  );
};

export default DashoardBarGraph;
