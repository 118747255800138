import axios from "axios";
import React, { useState } from "react";
import { Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { FaCheck } from "react-icons/fa6";

function CommonIframe({ attachments, ...props }) {
  const attachmentBaseUrl = process.env.REACT_APP_BASE_URL;
  const [deleteAttachmentId, setDeleteAttachmentId] = useState(null);
  const [spinner, setSpinner] = useState(false);


  console.log("attachments", attachments);


  function deleteAttachment(attachmentId) {
    const apiurl = process.env.REACT_APP_DEV_API_KEY_NEW;
    const user = JSON.parse(localStorage.getItem("profile"));
    const user_id = user?.response?.userDetails?.id;
    const getToken = user.response.token;
    const bearer_token = `Bearer ${getToken}`;
    setSpinner(true);
    axios
      .post(
        apiurl + "setdeletetenderattechment",
        { attachmentId, user_id },
        {
          headers: {
            Authorization: bearer_token,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        if (response.data.success) {
          setDeleteAttachmentId(null);
          props.setRenderState(!props.renderState);
          toast.success("Attachment Deleted successfully");
          setSpinner(false);
        }
      })
      .catch((error) => {
        toast.error("Something went wrong");
        setSpinner(false);
      });
  }
  return (
    <>
      <div className="row">
        {attachments?.length ? (
          attachments.map((element, i) => {
            return (
              <div key={element.id} className="col-md-4">
                {deleteAttachmentId === element.id ? (
                  <div className="d-flex justify-content-between">
                    <p className="fw-bold text-danger">Are you sure?</p>
                    {spinner ? (
                      <Spinner variant="danger" />
                    ) : (
                      <span style={{ marginleft: "10rem", cursor: "pointer" }}>
                        <button
                          className="border-0 bg-white me-2"
                          onClick={() => setDeleteAttachmentId(null)}
                        >
                          <i className="fa fa-times" aria-hidden="true"></i>
                        </button>
                        <button
                          className="border-0 bg-white "
                          onClick={() => deleteAttachment(element.id)}
                        >
                          <FaCheck />
                        </button>
                      </span>
                    )}
                  </div>
                ) : (
                  <div
                    key={i}
                    className="d-flex justify-content-between w-100 shadow px-2 py-1 rounded border-1 mb-3"
                  >
                    <Link
                      to={attachmentBaseUrl + element.name}
                      target="_blank"
                      className="fw-bold text-dark me-2"
                      style={{
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {element.name.includes("_qks2024fictive_") ? (
                        <p className="m-0 text-dark">
                          {element.name.split("_qks2024fictive_")[1]}
                        </p>
                      ) : (
                        <p className="m-0 text-dark">
                          {element.name.split('/').pop().split('.').slice(0, -1).join('.')}
                        </p>
                      )}
                    </Link>

                    <button
                      className="border-0 bg-white"
                      onClick={() => setDeleteAttachmentId(element.id)}
                      type="button"
                      style={{ cursor: "pointer" }}
                    >
                      {" "}
                      <i className="fa fa-times" aria-hidden="true"></i>
                    </button>
                  </div>
                )}
              </div>
            );
          })
        ) : (
          <div className="text-center">
            <h5 className="fw-bold">NO ATTACHMENTS TO THIS CATEGORY</h5>
          </div>
        )}
      </div>
    </>
  );
}

export default CommonIframe;
