import React, { useCallback, useEffect, useState } from "react";
import BasicPagination from "../commonComponents/Pagination";
import { debounce } from "lodash";
import { getTaxInvoiceApi } from "../commonComponents/storeApi";
import { Spinner } from "react-bootstrap";
import { errorsMessage } from "../../constant";
import { Link, useLocation } from "react-router-dom";
import InvoiceTable from "./InvoiceTable";
import InvoiceFilter from "./InvoiceFilter";

function InvoiceList() {
  const userProfile = JSON.parse(localStorage.getItem("profile"));
  const user_id = userProfile?.response?.userDetails?.id;
  const [loader, setLoader] = useState(true);
  const [listData, setListData] = useState([]);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState("");
  const { state } = useLocation();
  const [filters, setFilters] = useState({
    startDate: state ? state?.startDate : "",
    endDate: state ? state?.endDate : "",
    search: "",
  });

  function taxInvoiceList(term, page) {
    setLoader(true);
    getTaxInvoiceApi({ ...term, page })
      .then((res) => {
        setListData(res?.data?.response?.listTaxInvoice?.data);
        setPageCount(res?.data?.response?.listTaxInvoice?.last_page);
      })
      .catch((err) => {
        errorsMessage(err?.response?.status);
      })
      .finally(() => {
        setLoader(false);
      });
  }
  const debouncedFetchTaxInvoiceList = useCallback(
    debounce((term, page) => {
      taxInvoiceList(term, page);
    }, 600),
    [user_id]
  );
  const handleFilterChange = (name, value) => {
    if (name === "dateFilter") {
      setFilters({
        ...filters,
        startDate: value[0],
        endDate: value[1],
      });
    } else {
      setFilters({ ...filters, [name]: value });
    }
  };

  useEffect(() => {
    debouncedFetchTaxInvoiceList(filters, page);
  }, [debouncedFetchTaxInvoiceList, page, filters]);

  function handlePaginateChange(event, value) {
    setPage(value);
  }
  return (
    <div className="page-wrapper">
      <div className="page-content-tab responsive-tab">
        <div className="container-fluid">
          <div className="card mt-4">
            <div className="card-body tabsall">
              <div className="row">
                <div className="col-sm-4">
                  <div className="page-title-box">
                    <h2 className="page-title"> Tax Invoice </h2>
                  </div>
                </div>
                <div className="col-md-8 d-flex align-items-center justify-content-end">
                  <div className="d-flex">
                    <InvoiceFilter
                      handleFilterChange={handleFilterChange}
                      filters={filters}
                    />
                    <div className="">
                      <Link to={"/addTaxInvoice"}>
                        <button className="btn btn-danger">
                          Add&nbsp;Invoice
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
                {loader ? (
                  <div
                    className="d-flex align-items-center justify-content-center"
                    style={{ height: "70vh" }}
                  >
                    <Spinner animation="grow" variant="danger" />
                    <Spinner animation="grow" variant="danger" />
                    <Spinner animation="grow" variant="danger" />
                  </div>
                ) : (
                  <InvoiceTable detail={listData} />
                )}
                <div className="text-end mt-2">
                  <BasicPagination
                    handlePaginateChange={handlePaginateChange}
                    page={page}
                    pageCount={pageCount}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default InvoiceList;
