import React from "react";
import { Link } from "react-router-dom";
import Footer from "../Components/Footer";
import Header from "../Components/Header";
import { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { getRoleList, getUserDropdown, departmentDropdown } from "../redux/api";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Formik, useFormik } from "formik";
import { editUserSchema } from "../schemas/Index";
import Spinner from "react-bootstrap/Spinner";

function EditUserForm() {
  const apiurl = process.env.REACT_APP_DEV_API_KEY_NEW;
  const imageBaseUrl = process.env.REACT_APP_BASE_URL;
  const navigate = useNavigate();
  const param = useParams();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [designation, setDesignation] = useState("");
  const [reporting_person, setReporting_person] = useState("");
  const [selectDate, setSelectDate] = useState(new Date());
  const [profileimage, setProfileimage] = useState(null);
  const [signatureImage, setSignatureImage] = useState("");
  const [validationError, setValidationError] = useState("");
  const [spinner, setSpinner] = useState(false);
  const [editImage, setEditImage] = useState(null);
  const [users, setUsers] = useState([]);
  const [roles, setRoles] = useState([]);
  const [error, setError] = useState("");
  const [imageName, setImageName] = useState("");
  const [departmentList, setDepartmentList] = useState([]);
  const [department, setDepartment] = useState("");
  const [loading, setLoading] = useState(true);
  const [groupList, setGroupList] = useState([]);
  const [allData, setAllData] = useState({
    name: "",
    email: "",
    phone: "",
    designation: "",
    reporting_person: "",
    profileimage: null,
    department_id: "",
    prefix: "",
    personal_phone: "",
    boardCategory: "",
  });
  const user = JSON.parse(localStorage.getItem("profile"));
  const user_id = user?.response?.userDetails?.id;
  const getToken = user?.response?.token;
  const bearer_token = `Bearer ${getToken}`;
  var body = { user_id: user_id };
  const id = param.id;
  // let joining_date = selectDate
  //   ? `${selectDate.getFullYear()}-${
  //       selectDate.getMonth() + 1
  //     }-${selectDate.getDate()}`
  //   : "";

  const initialValues = {};

  const {
    values,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    setValues,
    touched,
  } = useFormik({
    validationSchema: editUserSchema,
    initialValues: initialValues,
    onSubmit: (values) => {
      editUser(values);
    },
  });

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (!file) return;

    if (!file.type.startsWith("image/")) {
      setError("Please select a valid image file.");
      return;
    }
    const img = new Image();
    const reader = new FileReader();
    reader.onloadend = () => {
      img.onload = () => {
        const width = img.width;
        const height = img.height;
        setAspectRatio({ width, height });
        setFieldValue("signature", img.src);
        setError("");
      };
      img.src = reader.result;
    };

    reader.readAsDataURL(file);
  };

  const handleSubmitData = (e) => {
    if (aspectRatio?.height < 340 && aspectRatio?.width < 550) {
      handleSubmit(e);
    } else {
      alert("Please select an image with a valid aspect ratio.");
    }
  };

  const handleImageChange = (e) => {
    const data = new FileReader();
    data.addEventListener("load", () => {
      setProfileimage(data.result);
    });
    data.readAsDataURL(e.target.files[0]);
  };

  function getAllDropdownList() {
    getUserDropdown({ user_id: user_id, accessType: "view" })
      .then((res) => {
        setUsers(res?.data?.response?.userList);
      })
      .catch((err) => {});
    getRoleList({ user_id: user_id, accessType: "view" })
      .then((res) => {
        setRoles(res?.data?.response?.roleList);
      })
      .catch((err) => {});
    departmentDropdown({ user_id: user_id, accessType: "view" })
      .then((res) => {
        setDepartmentList(res?.data?.response?.departmentDropDown);
      })
      .catch((err) => {});
  }

  async function getEditableData() {
    setLoading(true);
    const url = apiurl + "getusereditdetails";
    const config = {
      headers: {
        Authorization: bearer_token,
      },
    };
    const data = {
      user_id: id,
      accessType: "edit",
    };
    axios
      .post(url, data, config)
      .then((res) => {
        setEmail(res?.data?.response?.editData?.email);
        setDesignation(res?.data?.response?.editData?.designation);
        setReporting_person(res?.data?.response?.editData?.reporting_person);
        setPhone(res?.data?.response?.editData?.phone);
        setSelectDate(new Date(res?.data?.response?.editData?.joining_date));
        setEditImage(
          imageBaseUrl + res?.data?.response?.editData?.profileimage
        );
        setImageName(res?.data?.response?.editData?.profileimage);
        setSignatureImage(res?.data?.response?.editData?.signature);
        setAllData(res?.data?.response?.editData);
        let data = res?.data?.response?.editData;
        setValues(data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        toast.error("Something went wrong , Please try again");
        navigate("/ProjectsUsers");
        if (error?.response?.data?.status === "Token is Expired") {
          navigate("/");
        }
      });
  }

  const editUser = (value) => {
    setSpinner(true);
    const url = apiurl + "updateuserdata";
    const user = JSON.parse(localStorage.getItem("profile"));
    const user_id = user?.response?.userDetails?.id;
    const data = {
      ...value,
      user_id,
      profileimage: profileimage ? profileimage : imageBaseUrl + imageName,
      signature: siganture ? accessType : "edit",
    };
    axios
      .post(url, data, {
        headers: {
          Authorization: bearer_token,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        // return  response;
        setSpinner(false);
        if (response.data.success) {
          navigate("/ProjectsUsers");
          toast.success("Updated Successfully");
        }
        if (response.data.status === "Token is Expired") {
          navigate("/");
        }
        setError(response.data.status);
      })
      .catch((error) => {
        if (error.response.data.message === "Error validation") {
          setValidationError(error.response.data.response);
        }
        setSpinner(false);
      });
  };
  function getGroupList() {
    const user = JSON.parse(localStorage.getItem("profile"));
    const user_id = user?.response?.userDetails?.id;
    const getToken = user?.response?.token;
    const bearer_token = `Bearer ${getToken}`;
    let url = apiurl + "listboardgroup";
    let data = { user_id };
    axios
      .post(url, data, {
        headers: {
          Authorization: bearer_token,
        },
      })
      .then((response) => {
        setGroupList(response?.data?.response.BoardGroupList);
      })
      .catch((error) => {});
  }
  useEffect(() => {
    getAllDropdownList();
    getEditableData();
    getGroupList();
  }, []);
  return (
    <>
      <div className="page-wrapper">
        <div className="page-content-tab">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div className="page-title-box">
                  <div className="float-end">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to="#"></Link>
                      </li>
                      <li className="breadcrumb-item">
                        <Link to="#!">Projects /</Link>
                      </li>
                      <li className="breadcrumb-item">
                        <Link to="/ProjectsUsers">Users /</Link>
                      </li>
                      <li className="breadcrumb-item active">Edit User</li>
                    </ol>
                  </div>
                  <h4 className="page-title">Edit User</h4>
                </div>
              </div>
            </div>
            {loading ? (
              <div
                className="d-flex align-items-center justify-content-center pb-5"
                style={{ height: "70vh" }}
              >
                <Spinner animation="grow" variant="danger" />
                <Spinner animation="grow" variant="danger" />
                <Spinner animation="grow" variant="danger" />
              </div>
            ) : (
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-body">
                      <Formik>
                        <form
                          onSubmit={handleSubmit}
                          encType="multipart/form-data"
                        >
                          <div className="row">
                            <div className="col-md-1">
                              <div className="form-group mb-3">
                                <label htmlFor="prefix" className="form-label">
                                  Prefix
                                </label>
                                <select
                                  className="form-control border-secondary"
                                  name="prefix"
                                  placeholder=""
                                  value={values.prefix}
                                  onChange={handleChange}
                                >
                                  <option value="">Select</option>
                                  <option value="Mr.">Mr.</option>
                                  <option value="Miss">Ms.</option>
                                </select>

                                <p className="text-danger">
                                  {validationError?.prefix}
                                </p>
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="form-group mb-3">
                                <label htmlFor="name" className="form-label">
                                  Employee Name
                                </label>
                                <input
                                  type="text"
                                  className="form-control border-secondary"
                                  id="name"
                                  name="name"
                                  placeholder="Enter User Name"
                                  value={values.name}
                                  onChange={handleChange}
                                  maxLength={30}
                                  onBlur={handleBlur}
                                />
                                {touched.name && errors.name ? (
                                  <p className="text-danger">{errors.name}</p>
                                ) : (
                                  <p className="text-danger">
                                    {validationError?.name}
                                  </p>
                                )}
                              </div>
                            </div>

                            <div className="col-md-5">
                              <div className="form-group mb-3">
                                <label htmlFor="email" className="form-label">
                                  Employee Email ID
                                </label>
                                <input
                                  className="form-control border-secondary"
                                  id="email"
                                  name="email"
                                  placeholder="Ex: abc@abc.com"
                                  onChange={handleChange}
                                  maxLength={60}
                                  value={values.email}
                                  onBlur={handleBlur}
                                />
                                {touched.email && errors.email ? (
                                  <p
                                    className="text-capitalize"
                                    style={{ color: "red" }}
                                  >
                                    {errors.email}
                                  </p>
                                ) : (
                                  <p
                                    className="text-capitalize"
                                    style={{ color: "red" }}
                                  >
                                    {validationError?.email}
                                  </p>
                                )}
                              </div>
                            </div>
                            <div className="col-md-5">
                              <div className="form-group mb-3">
                                <label
                                  htmlFor="designation"
                                  className="form-label"
                                >
                                  Designation
                                </label>
                                <select
                                  className="form-control border-secondary"
                                  id="designation"
                                  name="designation"
                                  onChange={handleChange}
                                  value={values.designation}
                                  onBlur={handleBlur}
                                >
                                  <option value="">Select</option>
                                  {roles.map((item, i) => {
                                    return (
                                      <option value={item.id} key={i}>
                                        {item.name}
                                      </option>
                                    );
                                  })}
                                </select>
                                {touched.designation && errors.designation ? (
                                  <p className="text-danger">
                                    {errors.designation}
                                  </p>
                                ) : (
                                  <p className="text-danger text-capitalize">
                                    {validationError?.designation}
                                  </p>
                                )}
                              </div>
                            </div>

                            <div className="col-md-5">
                              <div className="form-group mb-3">
                                <label
                                  htmlFor="reporting_person"
                                  className="form-label"
                                >
                                  Reporting Manager
                                </label>
                                <select
                                  className="form-control border-secondary"
                                  id="reporting_person"
                                  name="reporting_person"
                                  value={values.reporting_person}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                >
                                  <option value="">Select</option>
                                  {users.map((obj, i) => {
                                    return (
                                      <option value={obj.id} key={i}>
                                        {obj.name}
                                      </option>
                                    );
                                  })}
                                </select>
                                {touched.reporting_person &&
                                errors.reporting_person ? (
                                  <p className="text-danger">
                                    {errors.reporting_person}
                                  </p>
                                ) : (
                                  <p className="text-danger text-capitalize">
                                    {validationError?.reporting_person}
                                  </p>
                                )}
                              </div>
                            </div>

                            <div className="col-md-5">
                              <div className="form-group mb-3">
                                <label
                                  htmlFor="reporting_person"
                                  className="form-label"
                                >
                                  Department
                                </label>
                                <select
                                  className="form-control border-secondary"
                                  id="department_id"
                                  name="department_id"
                                  value={values.department_id}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                >
                                  <option value="">Select</option>
                                  {departmentList?.map((obj, i) => {
                                    return (
                                      <option value={obj.id} key={i}>
                                        {obj.name}
                                      </option>
                                    );
                                  })}
                                </select>
                                {touched.department_id &&
                                errors.department_id ? (
                                  <p className="text-danger">
                                    {errors.department_id}
                                  </p>
                                ) : (
                                  <p className="text-danger text-capitalize">
                                    {validationError?.department_id}
                                  </p>
                                )}
                              </div>
                            </div>

                            <div className="col-md-5">
                              <div className="form-group mb-3">
                                <label className="form-label">Image</label>
                                <input
                                  type="file"
                                  className="form-control border-secondary"
                                  id="profileimage"
                                  name="profileimage"
                                  title="Choose a Image Please"
                                  onChange={handleImageChange}
                                  hidden
                                />
                                <label
                                  className="d-block border form-control border-secondary"
                                  for="profileimage"
                                >
                                  {profileimage
                                    ? profileimage.slice(0, 40)
                                    : imageName}
                                </label>
                                {touched.profileimage && errors.profileimage ? (
                                  <p className="text-danger">
                                    {errors.profileimage}
                                  </p>
                                ) : (
                                  <p className="text-danger text-capitalize">
                                    {validationError?.profileimage}
                                  </p>
                                )}
                              </div>
                            </div>
                            <div className="col-md-2">
                              <div className="form-group mb-3">
                                {/* <label htmlFor="profileimage" className="form-label">Image preview</label> */}
                                <img
                                  src={profileimage ? profileimage : editImage}
                                  style={{
                                    width: "70px",
                                    height: "70px",
                                    objectFit: "cover",
                                    borderRadius: "50%",
                                  }}
                                />
                              </div>
                            </div>

                            <div className="col-md-12">
                              <div className="row">
                                <div className="col-md-5">
                                  <label className="form-label">
                                    Contact Number{" "}
                                    <span className="font-12">(Work)</span>
                                  </label>
                                  <div className="form-group mb-">
                                    <input
                                      className="form-control border-secondary "
                                      id="phone"
                                      name="phone"
                                      placeholder="Ex: 9001900191"
                                      value={values.phone}
                                      onChange={handleChange}
                                      maxLength={10}
                                      onBlur={handleBlur}
                                    />
                                    {touched.phone && errors.phone ? (
                                      <p className="text-danger">
                                        {errors.phone}
                                      </p>
                                    ) : (
                                      <p className="text-danger text-capitalize">
                                        {validationError?.phone}
                                      </p>
                                    )}
                                  </div>
                                </div>

                                <div className="col-md-5">
                                  <label className="form-label">
                                    Contact Number{" "}
                                    <span className="font-12">(Personal)</span>
                                  </label>
                                  <div className="form-group mb-">
                                    <input
                                      className="form-control border-secondary "
                                      id="personal_phone"
                                      name="personal_phone"
                                      placeholder="Ex: 9001900191"
                                      value={values.personal_phone}
                                      onChange={handleChange}
                                      maxLength={10}
                                    />

                                    <p className="text-danger text-capitalize">
                                      {validationError?.personal_phone}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="col-md-5">
                              <div className="form-group mb-3">
                                <label
                                  htmlFor="joining_date"
                                  className="form-label d-block"
                                >
                                  Joining Date
                                </label>
                                <input
                                  type="date"
                                  className="form-control border-secondary"
                                  id="joining_date"
                                  name="joining_date"
                                  placeholderText="Select a Date"
                                  onChange={handleChange}
                                  value={values.joining_date}
                                  dateFormat="dd-MM-yyyy"
                                  onBlur={handleBlur}
                                />
                              </div>
                            </div>
                            <div className="col-md-5">
                              <div className="form-group mb-3">
                                <label
                                  htmlFor="reporting_person"
                                  className="form-label"
                                >
                                  Board category
                                </label>
                                <select
                                  className="form-control border-secondary"
                                  id="boardCategory"
                                  name="boardCategory"
                                  value={values.boardCategory}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                >
                                  <option value="">Select</option>
                                  {groupList?.map((obj, i) => {
                                    return (
                                      <option value={obj.id} key={i}>
                                        {obj.name}
                                      </option>
                                    );
                                  })}
                                </select>
                                {touched.boardCategory &&
                                errors.boardCategory ? (
                                  <p className="text-danger">
                                    {errors.boardCategory}
                                  </p>
                                ) : (
                                  <p className="text-danger text-capitalize">
                                    {validationError?.boardCategory}
                                  </p>
                                )}
                              </div>
                            </div>
                            <div className="col-md-5">
                              <div className="form-group mb-3">
                                <label className="form-label">Signature</label>
                                <input
                                  type="file"
                                  className="form-control border-secondary"
                                  id="profileimage"
                                  name="profileimage"
                                  title="Choose a Image Please"
                                  onChange={handleImageChange}
                                  hidden
                                />
                                <label
                                  className="d-block border form-control border-secondary"
                                  for="profileimage"
                                >
                                  {profileimage
                                    ? profileimage.slice(0, 40)
                                    : imageName}
                                </label>
                                {touched.profileimage && errors.profileimage ? (
                                  <p className="text-danger">
                                    {errors.profileimage}
                                  </p>
                                ) : (
                                  <p className="text-danger text-capitalize">
                                    {validationError?.profileimage}
                                  </p>
                                )}
                              </div>
                            </div>
                            <div className="col-md-2">
                              <div className="form-group mb-3">
                                {/* <label htmlFor="profileimage" className="form-label">Image preview</label> */}
                                <img
                                  src={
                                    values?.signature
                                      ? values?.signature
                                      : editImage
                                  }
                                  style={{
                                    width: "70px",
                                    height: "70px",
                                    objectFit: "cover",
                                    borderRadius: "50%",
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                          {spinner ? (
                            <div className="">
                              <i className="fa fa-spinner fa-spin fa-3x text-danger me-3 mt-3"></i>
                              <p>Updating User...</p>
                            </div>
                          ) : (
                            <div>
                              <button
                                type="submit"
                                className="btn btn-primary btn-sm me-3 mt-3"
                                onClick={handleSubmit}
                              >
                                Submit
                              </button>
                              <button
                                type="button"
                                className="btn btn-de-danger btn-sm me-3 mt-3"
                                onClick={() => navigate("/ProjectsUsers")}
                              >
                                Cancel
                              </button>
                            </div>
                          )}
                        </form>
                      </Formik>

                      {/* <p style={{ color: "red" }}>{error}</p> */}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default EditUserForm;
