import * as React from "react";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";

export default function PaginationTender({
  handlePaginateChange,
  page,
  pageCount,
}) {
  return (
    <Stack spacing={2} direction="row" justifyContent="flex-end">
      <Pagination
        count={pageCount}
        page={page}
        onChange={handlePaginateChange}
      />
    </Stack>
  );
}
