import React from "react";
import DateRangePickerLatest from "../../../../StoreDepartment/commonComponents/DateRangePicker";

function Filters({ filters, handleFilterChange }) {
  return (
    <div class="input-group search_matarial d-flex align-items-center me-3">
      <DateRangePickerLatest handleChange={handleFilterChange} />
      <select
        className="form-control border-secondary ms-3"
        onChange={(e) => handleFilterChange("status", e.target.value)}
        value={filters?.status}
      >
        <option value="" disabled hidden>
          Filter By Status
        </option>
        <option value={""}>All</option>
        <option value="Closed">Closed</option>
        <option value="Pending">Pending</option>
        <option value="Attended (CRN Pending)">Attended (CRN Pending)</option>
      </select>
      <input
        className="form-control border-secondary ms-3 "
        placeholder="search by Rly,CoachType,Make"
        value={filters?.search}
        onChange={(e) => handleFilterChange("search", e.target.value)}
      />
    </div>
  );
}

export default Filters;
