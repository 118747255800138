import React from "react";
import DateRangePickerLatest from "../commonComponents/DateRangePicker";

function DeliveryChallanFilter({ handleFilterChange }) {
  return (
    <div class="input-group search_matarial d-flex align-items-center me-3">
      <DateRangePickerLatest handleChange={handleFilterChange} />
      <input
        className="form-control border-secondary ms-3 "
        style={{ minWidth: "17rem" }}
        placeholder="Search By Store or Delivery Challan "
        onChange={() => handleFilterChange("search", e.target.value)}
      />
    </div>
  );
}

export default DeliveryChallanFilter;
