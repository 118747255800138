import * as Yup from "yup";

export const requistionSchema = Yup.object({
  requested_date: Yup.string().required("This field is required."),
  store_id: Yup.string().required("This field is required."),
  requested_by: Yup.string().required("This field is required."),
  required_date: Yup.string().required("This field is required."),
  project_id: Yup.string().required("This field is required."),
  items: Yup.array().of(
    Yup.object().shape({
      item_id: Yup.string().required("This field is required."),
      required_qty: Yup.string().required("This field is required."),
    })
  ),
});
