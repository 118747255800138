import React, { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import AddProjectTargetData from "../Modals/AddProjectTargetData";
import axios from "axios";

function ProjectTarget() {
  const [showAddTarget, setShowAddTarget] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [targetId, setTargetId] = useState(null);
  const [targetList, setTargetList] = useState([]);
  const baseUrl = process.env.REACT_APP_DEV_API_KEY_NEW;
  const user = JSON.parse(localStorage.getItem("profile"));
  const user_id = user?.response?.userDetails?.id;
  const getToken = JSON.parse(localStorage.getItem("profile"))?.response?.token;
  const bearer_token = `Bearer ${getToken}`;
  const config = { headers: { Authorization: bearer_token } };
  const [loader, setLoader] = useState(true);
  const [updateData, setUpdateData] = useState("");

  function handleClose() {
    setShowAddTarget(false);
    setDeleteModal(false);
    setTargetId(null);
  }
  function getTargetList() {
    setLoader(true);
    const url = `${baseUrl}historicalTargetList?user_id=${user_id}`;
    axios
      .get(url, config)
      .then((response) => {
        setLoader(false);
        if (response.status === 200) {
          const targets = response?.data?.response?.targets || [];
          setTargetList(targets);
        }
      })
      .catch((error) => {
        setLoader(false);
      });
  }
  useEffect(() => {
    getTargetList();
  }, []);

  return (
    <div className="page-wrapper">
      <div className="page-content-tab">
        <div className="container-fluid">
          <div className="mt-4 d-flex justify-content-between">
            <h4>Projects Target </h4>
            <div>
              <button
                className="btn btn-primary"
                onClick={() => setShowAddTarget(true)}
              >
                + Set Target
              </button>
            </div>
          </div>
          <div className="row">
            <div className="col-md-5">
              <div className="mt-4 table-border-radius">
                <Table responsive>
                  <thead>
                    <tr className="bg-table-black">
                      <th className="text-light">Financial Year</th>
                      <th className="text-light">Project Category</th>
                      <th className="text-light">Target Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    {targetList.length ? (
                      targetList.map((element, index) => {
                        return (
                          <tr className="border-bottom">
                            <td className="border-end">
                              <p className="dashboard-text text-danger">
                                {element?.financial_year}
                              </p>
                            </td>
                            <td className="border-end">
                              <p className="dashboard-text">
                                {" "}
                                {element.project_category_name}
                              </p>
                            </td>
                            <td>
                              <p className="dashboard-text">
                                {" "}
                                {Number(element.target_amount)?.toLocaleString(
                                  "en-IN",
                                  {
                                    style: "currency",
                                    currency: "INR",
                                  }
                                )}{" "}
                              </p>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <>
                        <tr>
                          <td colSpan="3">No Data for Selected Year</td>
                        </tr>
                      </>
                    )}
                  </tbody>
                </Table>
              </div>
            </div>
            <AddProjectTargetData
              show={showAddTarget}
              handleClose={handleClose}
              targetId={targetId}
              getTargetList={getTargetList}
              updateDataStore={updateData}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProjectTarget;
