import React, { useState } from "react";
import { useFormik } from "formik";
import { useLocation, useNavigate } from "react-router-dom";
import { errorsMessage } from "../../constant";
import { toast } from "react-toastify";
import { updateCustomerApi } from "../commonComponents/storeApi";
import CustomerStructure from "./CustomerStructure";
import CardBody from "../invoice/CardBody";
import { customerSchema } from "./customerSchema";

function UpdateCustomer() {
  const userProfile = JSON.parse(localStorage.getItem("profile"));
  const user_id = userProfile?.response?.userDetails?.id;
  const [spinner, setSpinner] = useState(false);
  const [serverSideValidation, setServerSideValidation] = useState("");
  const { state } = useLocation();
  const initialValue = {
    customer_details_id: state?.detail?.id,
    name: state?.detail?.name,
    address: state?.detail?.address,
    gst_in: state?.detail?.gst_in,
    state_code: state?.detail?.state_code,
    railway_zone: state?.detail?.railway_zone,
    mobile_Number: state?.detail?.mobile_Number,
    email: state?.detail?.email,
  };
  const navigate = useNavigate();

  function updateCustomerDetail(values) {
    const data = {
      user_id,
      ...values,
    };
    setSpinner(true);
    updateCustomerApi(data)
      .then((response) => {
        if (response.status === 200) {
          toast.success("Customer Updated successfully");
          navigate(-1);
        }
      })
      .catch((error) => {
        //setServerSideValidation(error?.response?.data?.response);
        errorsMessage(error?.response?.status);
      })
      .finally(() => {
        setSpinner(false);
      });
  }

  const {
    values,
    handleSubmit,
    handleChange,
    errors,
    touched,
    handleBlur,
    setFieldValue,
  } = useFormik({
    initialValues: initialValue,
    validationSchema:customerSchema,
    onSubmit: (values) => {
      updateCustomerDetail(values);
    },
  });
  return (
    <form>
      <div className="page-wrapper">
        <div className="page-content-tab responsive-tab">
          <div className="container-fluid mt-4">
            <h4 className="ms-3 mb-4">Update Customer</h4>

            <CardBody>
              <CustomerStructure
                handleChange={handleChange}
                errors={errors}
                touched={touched}
                handleBlur={handleBlur}
                values={values}
                setFieldValue={setFieldValue}
              />
              <div className="text-end mt-3">
                <button
                  className="btn btn-danger mx-2"
                  type="button"
                  onClick={() => navigate(-1)}
                >
                  Cancel
                </button>

                <button
                  className="btn btn-danger"
                  onClick={handleSubmit}
                  values={values}
                >
                  Update
                </button>
              </div>
            </CardBody>
          </div>
        </div>
      </div>
    </form>
  );
}

export default UpdateCustomer;
