import React, { useEffect } from "react";
import { getCurrentQuarter } from "../services/constant-variable";
import TenderDatePicker from "../Tender/Common/DatePicker";
import { CiSearch } from "react-icons/ci";

function QuaterFilter({ filters, setFilters }) {
  const currentYear = new Date().getFullYear();
  const startYear = 2013;
  const dynamicYears = Array.from(
    { length: currentYear - startYear + 1 },
    (_, i) => startYear + i
  );

  useEffect(() => {
    if ( !filters.selectedQuarter || !filters.selectedYear ) return;
    
    if (filters.selectedQuarter === "quarter_1") {
      setFilters({
        ...filters,
        startDate: `${filters.selectedYear}-04-01`,
        endDate: `${filters.selectedYear}-06-30`,
      });
    }
    if (filters.selectedQuarter === "quarter_2") {
      setFilters({
        ...filters,
        startDate: `${filters.selectedYear}-07-01`,
        endDate: `${filters.selectedYear}-09-30`,
      });
    }
    if (filters.selectedQuarter === "quarter_3") {
      setFilters({
        ...filters,
        startDate: `${filters.selectedYear}-10-01`,
        endDate: `${filters.selectedYear}-12-31`,
      });
    }
    if (filters.selectedQuarter === "quarter_4") {
      setFilters({
        ...filters,
        startDate: `${Number(filters.selectedYear) + 1}-01-01`,
        endDate: `${Number(filters.selectedYear) + 1}-03-31`,
      });
    }
    if(filters?.selectedQuarter === "Full Financial Year"){
      setFilters({
        ...filters,
        startDate: `${Number(filters.selectedYear)}-04-01`,
        endDate: `${Number(filters.selectedYear) + 1}-03-31`,
      });
    }
  }, [filters.selectedQuarter, filters.selectedYear]);

  useEffect(() => {
    const currentDate = new Date();
    const currentQuarter = getCurrentQuarter(currentDate);
    setFilters({
      ...filters,
      selectedYear: currentDate.getFullYear(),
      selectedQuarter: currentQuarter,
    });
  }, []);

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    if (name === "dateFilter") {
      setFilters({
        ...filters,
        startDate: value[0],
        endDate: value[1],
      });
    } else {
      setFilters({ ...filters, [name]: value });
    }
  };

  return (
    <div>
      <form className="page-title d-flex align-items-center gap-2">
        <div className="row w-100">
          <div className="col-md-4">
            <select
              className="form-control w-100"
              name="selectedYear"
              value={filters.selectedYear}
              onChange={handleFilterChange}
            >
              <option value={""}>Select Year</option>
              {dynamicYears
                .slice()
                .reverse()
                .map((year) => (
                  <option key={year} value={year}>
                    {year}-{(year + 1).toString().slice(2)}
                  </option>
                ))}
            </select>
          </div>
          <div className="col-md-4">
            <select
              className="form-control"
              name="selectedQuarter"
              value={filters.selectedQuarter}
              onChange={handleFilterChange}
            >
              <option value={""}>Select Quarter</option>
              <option value="quarter_1">Q1 (April - June)</option>
              <option value="quarter_2">Q2 (July - September)</option>
              <option value="quarter_3">Q3 (Oct - Dec)</option>
              <option value="quarter_4">Q4 (Jan - March)</option>
              <option value="Full Financial Year">Full Financial Year</option>

            </select>
          </div>
          <div className="col-md-4">
            <div className="page-title">
              <TenderDatePicker
                handleChange={handleFilterChange}
                startDate={filters.startDate}
                endDate={filters.endDate}
                filters={filters}
                setFilters={setFilters}
              />
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

export default QuaterFilter;
