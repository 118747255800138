import React from 'react'
import { Link } from 'react-router-dom'

const DepartmentRole = () => {
    return (
        <div className="page-wrapper">
            <div className="page-content-tab">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="page-title-box">
                                <div className="float-end">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item">
                                            <Link to="#"></Link>
                                        </li>
                                        <li className="breadcrumb-item">
                                            <Link to="#!">Projects /</Link>
                                        </li>
                                        <li className="breadcrumb-item active">Department Role</li>
                                    </ol>
                                </div>
                                <h4 className="page-title">Department Role</h4>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                                <h4 className="mb-sm-0">Department Role</h4>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="department__card_detail">
                                                <div className="tab-content" id="pills-tabContent">
                                                    <div className="table-responsive browser_users">
                                                        <table className="table table-hover mb-0 detail-table">
                                                            <thead>
                                                                <tr>
                                                                    <th scope="col">Department</th>
                                                                    <th scope="col">Role</th> 
                                                                    <th scope="col">View </th>
                                                                    <th scope="col">Add</th>
                                                                    <th scope="col">Edit</th>
                                                                    <th scope="col">Delete</th>

                                                                </tr>
                                                            </thead>
                                                            <tbody> 
                                                                <tr  >
                                                                    <th scope="row"> </th>
                                                                    <td> </td> 
                                                                    <td>
                                                                        <input
                                                                            className="form-check-input"
                                                                            type="checkbox"
                                                                            value=""
                                                                        // checked={e.can_view}
                                                                        // onChange={() => handleChange(i, 'can_view', e.id)}

                                                                        />
                                                                    </td>
                                                                    <td>
                                                                        <input
                                                                            className="form-check-input"
                                                                            type="checkbox"
                                                                            value=""
                                                                        // checked={e.can_add}
                                                                        // onChange={() => handleChange(i, 'can_add', e.id)}
                                                                        />
                                                                    </td>
                                                                    <td>
                                                                        <input
                                                                            className="form-check-input"
                                                                            type="checkbox"
                                                                            value=""
                                                                        // checked={e.can_edit}
                                                                        // onChange={() => handleChange(i, 'can_edit', e.id)}
                                                                        />
                                                                    </td>
                                                                    <td>
                                                                        <input
                                                                            className="form-check-input"
                                                                            type="checkbox"
                                                                            value=""
                                                                        // checked={e.can_delete}
                                                                        // onChange={() => handleChange(i, 'can_delete', e.id)}
                                                                        />
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DepartmentRole