import React, { useState, useEffect, useRef } from "react";
import Footer from "../Components/Footer";
import { useNavigate } from "react-router-dom";
import AddFinancialTargetModal from "../Modals/AddFinacialTargetModal";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import { toast } from "react-toastify";
import Spinner from "react-bootstrap/Spinner";
import AddHistoricProjectModal from "../Modals/AddHistoricProjectModal";
import { Edit, PersonPinCircle } from "@mui/icons-material";

function HistoricData() {
  const navigate = useNavigate();
  const tableBottomRef = useRef();
  const tableTopRef = useRef();
  const [showAddTarget, setShowAddTarget] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [targetId, setTargetId] = useState(null);
  const [targetList, setTargetList] = useState([]);
  const [search, setSearch] = useState("");
  const baseUrl = process.env.REACT_APP_DEV_API_KEY_NEW;
  const user = JSON.parse(localStorage.getItem("profile"));
  const user_id = user?.response?.userDetails?.id;
  const getToken = JSON.parse(localStorage.getItem("profile"))?.response?.token;
  const bearer_token = `Bearer ${getToken}`;
  const config = { headers: { Authorization: bearer_token } };
  const [loader, setLoader] = useState(true);
  const [updateData, setUpdateData] = useState("");

  function handleClose() {
    setShowAddTarget(false);
    setDeleteModal(false);
    setTargetId(null);
  }

  function getTargetList() {
    setLoader(true);
    const url = `${baseUrl}historicalProjectDataList?user_id=${user_id}`;
    axios
      .get(url, config)
      .then((response) => {
        setLoader(false);

        if (response.status === 200) {
          const targets = response?.data?.response?.projectId || [];
          setTargetList(targets);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoader(false);
        // Optionally, you could show an error message to the user here
      });
  }

  function deleteTarget() {
    let url = baseUrl + "deletefinancialyeartarget";
    let data = { user_id, target_id: targetId };
    axios
      .post(url, data, config)
      .then((response) => {
        if (response.status === 200) {
          getTargetList();
          handleClose();
          toast.success("Deleted Successfully");
        }
      })
      .catch((error) => {
        toast.error("Something went wrong , Please try again later");
      });
  }

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  function moveToTop() {
    scrollToTop();
  }

  useEffect(() => {
    getTargetList();
  }, []);

  return (
    <>
      <div className="page-wrapper">
        <div className="page-content-tab">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div className="page-title-box">
                  <div className="float-end">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        {/* <Link to="#"></Link> */}
                      </li>
                      <li className="breadcrumb-item active">Historic Data</li>
                    </ol>
                  </div>
                  <h4 className="page-title">Historic Data</h4>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6">
                <ul className="list-inline">
                  <li className="list-inline-item"></li>
                </ul>
              </div>
              <div className="col-lg-6 text-end">
                <div className="text-end">
                  <ul className="list-inline">
                    {/* <li className="list-inline-item">
                      <div className="input-group">
                        <input
                          type="text"
                          id="example-input1-group2"
                          name="example-input1-group2"
                          className="form-control form-control-sm"
                          placeholder="Search"
                          value={search}
                          onChange={(e) => setSearch(e.target.value)}
                        />
                        <button
                          type="button"
                          className="btn btn-primary btn-sm"
                        >
                          <i className="fas fa-search"></i>
                        </button>
                      </div>
                    </li> */}
                    <li className="list-inline-item">
                      <button
                        type="button"
                        className="btn btn-primary btn-sm"
                        onClick={() => setShowAddTarget(true)}
                      >
                        Add Project
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            {loader ? (
              <div
                className="d-flex align-items-center justify-content-center"
                style={{ height: "70vh" }}
              >
                <Spinner animation="grow" variant="danger" />
                <Spinner animation="grow" variant="danger" />
                <Spinner animation="grow" variant="danger" />
              </div>
            ) : (
              <div className="row">
                {targetList?.map((data, index) => (
                  <div className="col-md-4" key={index}>
                    <div className="card">
                      <div className="card-body">
                        <div className="d-flex ">
                          <div className="d-flex align-items-center mb-3 historicCard">
                            <p className="m-0">Project ID &nbsp;</p>
                            <span>:</span>
                            <h5 className="m-0">
                              {" "}
                              &nbsp;{data?.project_id}
                            </h5>{" "}
                          </div>
                          <div>
                            <Edit
                              onClick={() => {
                                setShowAddTarget(true);
                                setUpdateData(data);
                                setTargetId(data?.id);
                              }}
                              style={{ cursor: "pointer" }}
                            />
                          </div>
                        </div>

                        <div className="d-flex align-items-center mb-3 historicCard">
                          <p className="m-0">Financial Year</p>
                          <span>:</span>
                          <h5 className="m-0">{data.financial_year}</h5>{" "}
                        </div>
                        <div className="d-flex  align-items-center mb-3 historicCard">
                          <p className="m-0">Project Category</p>
                          <span>:</span>
                          <h5 className="m-0">
                            {data.project_category_name}
                          </h5>{" "}
                        </div>
                        <div className="d-flex  align-items-center mb-3 historicCard">
                          <p className="m-0">Project Value</p>
                          <span>:</span>
                          <h5 className="m-0">{data.project_value}</h5>{" "}
                        </div>
                        <div className="d-flex align-items-center mb-3 historicCard">
                          <p className="m-0">Title</p>
                          <span>:</span>
                          <h5 className="m-0">{data.project_title}</h5>{" "}
                        </div>
                        <div className="d-flex align-items-center mb-3 historicCard">
                          <p className="m-0">Status</p>
                          <span>:</span>
                          <h5 className="m-0">{data.status_title}</h5>{" "}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
      <AddHistoricProjectModal
        show={showAddTarget}
        handleClose={handleClose}
        targetId={targetId}
        getTargetList={getTargetList}
        updateDataStore={updateData}
      />

      <Modal show={deleteModal} onHide={handleClose} centered backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title className="text-uppercase mb-0">Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h3 className="text-secondary text-center mx-3">
            Are you sure, <br /> Do you want to Delete this?
          </h3>
        </Modal.Body>
        <div className="d-flex gap-3 justify-content-center mb-3">
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="primary" onClick={deleteTarget}>
            Delete
          </Button>
        </div>
      </Modal>

      <div ref={tableBottomRef} />
      <div ref={tableTopRef} />
      <Footer></Footer>
    </>
  );
}

export default HistoricData;
