import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import { errorsMessage } from "../../constant";
import LotsLogsDetailPageBreadCrums from "../../Components/ProjectForms/EditForms/LotsLogsDetailPageBreadCrums";
import { getTendetLogsList } from "../../redux/api";
import TenderTableLogs from "./TenderTableLogs";

function TenderLogs() {
  const [logsData, setLogsData] = useState([]);
  const [loader, setLoader] = useState(false);
  const { state } = useLocation();
  const user = JSON.parse(localStorage.getItem("profile"));
  const user_id = user?.response?.userDetails?.id;

  function lotsLogsList() {
    setLoader(true);
    getTendetLogsList({ user_id, TenderId: state?.TenderId })
      .then((res) => {
        setLogsData(res?.data?.response?.TenderLogList);
      })
      .catch((err) => {
        errorsMessage(err?.response?.status);
      })
      .finally(() => {
        setLoader(false);
      });
  }
  useEffect(() => lotsLogsList(), []);
  return (
    <div className="page-wrapper">
      <div className="page-content-tab ">
        <div className="container-fluid ">
          {/* <LotsLogsDetailPageBreadCrums id={params.id} /> */}
          {loader ? (
            <div
              className="d-flex align-items-center justify-content-center"
              style={{ height: "70vh" }}
            >
              <Spinner animation="grow" variant="danger" />
              <Spinner animation="grow" variant="danger" />
              <Spinner animation="grow" variant="danger" />
            </div>
          ) : (
            <TenderTableLogs data={logsData} />
          )}
        </div>
      </div>
    </div>
  );
}

export default TenderLogs;
