import Table from "react-bootstrap/Table";
import Modal from "react-bootstrap/Modal";

function DashboardDeductionsModal({ show, setShow }) {
  const handleClose = () => setShow(false);

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title className="p-0 fs-4 m-0">Deductions</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Table striped>
          <thead>
            <tr>
              <th colSpan={2}>Deduction</th>
              <th>Amount</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td colSpan={2}>Insurance</td>
              <td className="text-danger">8,000</td>
            </tr>
            <tr>
              <td colSpan={2}>Maintanace and Repair</td>
              <td className="text-danger">10,000</td>
            </tr>
            <tr>
              <td colSpan={2}>Dues and Fees</td>
              <td className="text-danger">12,000</td>
            </tr>
          </tbody>
        </Table>{" "}
      </Modal.Body>
    </Modal>
  );
}

export default DashboardDeductionsModal;
