import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import { useFormik } from "formik";
import { departmentDropdown} from "../redux/api"
export default function AddUserRoleModal({
  show,
  handleClose,
  getRoleList,
  editAbleRole,
}) {
  const baseUrl = process.env.REACT_APP_DEV_API_KEY_NEW;
  const user = JSON.parse(localStorage.getItem("profile"));
  const user_id = user?.response?.userDetails?.id;
  const getToken = user?.response?.token;
  const bearer_token = `Bearer ${getToken}`;
  const [name, setName] = useState("");
  const [spinner, setSpinner] = useState(false);
  const[departmentList,setDepartmentList] = useState([])
  const initialValues = { name: "", department_id: "" };

  const {
    touched,
    handleSubmit,
    values,
    errors,
    handleChange,
    handleBlur,
    setValues,
    setFieldValue,
  } = useFormik({
    initialValues: initialValues,
    // validationSchema: userSchema,
    onSubmit: (values) => {
      if (editAbleRole) {
        editRole();
      } else {
        addRole(values);
      }
    },
  });
const getDepartmentDropdown =()=>{
  departmentDropdown({user_id })
      .then((res) => {
        setDepartmentList(res?.data?.response?.departmentDropDown);
      })
      .catch((err) => {});
}
  function addRole() {
    // e.preventDefault();
    setSpinner(true);
    let url = baseUrl + "createnewrole";
    let data = { user_id, ...values };
    axios
      .post(url, data, {
        headers: {
          Authorization: bearer_token,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setSpinner(false);
        if (response.status === 200) {
          handleClose();
          getRoleList();
          // setName("");
        }
      })
      .catch((error) => {
        setSpinner(false);
      });
  }

  function editRole() {
    setSpinner(true);
    let url = baseUrl + "editrole";
    let role_id = editAbleRole.id;
    let data = { user_id, ...values, role_id };
    axios
      .post(url, data, {
        headers: {
          Authorization: bearer_token,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setSpinner(false);
        if (response.status === 200) {
          handleClose();
          getRoleList();
          // setName("");
        }
      })
      .catch((error) => {
        setSpinner(false);
      });
  }

  useEffect(() => {
    if (editAbleRole) {
      setValues(editAbleRole);
    } else {
      setValues(initialValues);
    }
  }, [editAbleRole, show]);
  useEffect(()=>{
    getDepartmentDropdown()
  },[])

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Add User Role</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-12 mb-3">
                <label className="form-label">Role Name</label>
                <input
                  className="form-control"
                  placeholder="Enter Role Name"
                  name="name"
                  value={values.name}
                  onBlur={handleBlur}
                  onChange={handleChange}
                ></input>
              </div>
              <div className="col-12">
                <label htmlFor="department_id" className="form-label">
                  Department
                </label>
                <select
                  className="form-control"
                  id="department_id"
                  name="department_id"
                  value={values.department_id}
                  onChange={handleChange}
                  onBlur={handleBlur}
                >
                  <option value="">Select</option>
                  {departmentList?.map((obj, i) => {
                    return (
                      <option value={obj.id} key={i}>
                        {obj.name}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            <Modal.Footer>
              {spinner ? (
                <div className="">
                  <i className="fa fa-spinner fa-spin fa-3x text-danger me-3 mt-3"></i>
                  <p>Wait...</p>
                </div>
              ) : (
                <>
                  <Button variant="secondary" onClick={handleClose}>
                    Close
                  </Button>
                  <Button type="submit" variant="primary">
                    {editAbleRole ? " Update Role" : "Add Role"}
                  </Button>
                </>
              )}
            </Modal.Footer>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
}
