import React, { useCallback, useEffect, useState } from "react";
import BasicPagination from "../commonComponents/Pagination";
import { debounce } from "lodash";
import {
  customerDetailBulkApi,
  getCustomerApi,
} from "../commonComponents/storeApi";
import { Spinner } from "react-bootstrap";
import { errorsMessage } from "../../constant";
import { Link } from "react-router-dom";
import CustomerTable from "./CustomerTable";
import ExcelToArray from "./UploadBulkCustomer";
import ConfirmationModal from "./ConfirmationModal";
import { toast } from "react-toastify";
import CustomerFilter from "./CustomerFilter";

function CustomerList() {
  const userProfile = JSON.parse(localStorage.getItem("profile"));
  const user_id = userProfile?.response?.userDetails?.id;
  const [loader, setLoader] = useState(true);
  const [listData, setListData] = useState([]);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState("");
  const [open, setOpen] = React.useState(false);
  const [data, setData] = useState([]);
  const [uploadLoading, setUploadLoading] = useState(false);
  const [filters, setFilters] = useState({
    search: "",
    railway_zone: "",
  });

  function customerList(term, page) {
    setLoader(true);
    getCustomerApi({ ...term, page })
      .then((res) => {
        setListData(res?.data?.response?.CustomerDetailsList?.data || []);
        setPageCount(res?.data?.response?.CustomerDetailsList?.last_page);
      })
      .catch((err) => {
        errorsMessage(err?.response?.status);
      })
      .finally(() => {
        setLoader(false);
      });
  }
  const debouncedFetchTaxInvoiceList = useCallback(
    debounce((term, page) => {
      customerList(term, page);
    }, 600),
    [user_id]
  );

  function uploadBulkCustomerFile() {
    setOpen(false);
    setUploadLoading(true);
    customerDetailBulkApi(data)
      .then((res) => {
        customerList(filters, page);
        toast.success("Data upload succesfully");
      })
      .catch((err) => {
        errorsMessage(err?.response?.status);
      })
      .finally(() => {
        setUploadLoading(false);
      });
  }
  function handleFilterChange(e) {
    const { name, value } = e.target;
    setFilters({ ...filters, [name]: value });
  }

  useEffect(() => {
    debouncedFetchTaxInvoiceList(filters, page);
  }, [debouncedFetchTaxInvoiceList, page, filters]);

  function handlePaginateChange(event, value) {
    setPage(value);
  }
  console.log(filters);
  return (
    <div className="page-wrapper">
      <div className="page-content-tab responsive-tab">
        <div className="container-fluid">
          <div className="card mt-4">
            <div className="card-body tabsall">
              <div className="row">
                <div className="col-sm-12">
                  <div className="page-title-box">
                    <h4 className=""> Customer List </h4>
                  </div>
                </div>
                <div className="row mb-3">
                  <CustomerFilter
                    filters={filters}
                    handleFilterChange={handleFilterChange}
                  />

                  <div className="col-md-6 ">
                    <div className="d-flex align-items-center justify-content-end">
                      <div class="input-group search_matarial d-flex align-items-center"></div>
                      <div className="d-flex gap-2">
                        <div>
                          <a
                            href="/assets/images/files/bulkUploadFormat.xlsx"
                            download
                          >
                            <button className="btn btn-danger">
                              Download&nbsp;Format
                            </button>
                          </a>
                        </div>

                        <div>
                          <ExcelToArray
                            setOpen={setOpen}
                            setData={setData}
                            setUploadLoading={setUploadLoading}
                            uploadLoading={uploadLoading}
                          />
                        </div>
                        <Link to={"/addCustomerDetail"}>
                          <button className="btn btn-danger">
                            Add&nbsp;Customer
                          </button>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>

                {loader ? (
                  <div
                    className="d-flex align-items-center justify-content-center"
                    style={{ height: "70vh" }}
                  >
                    <Spinner animation="grow" variant="danger" />
                    <Spinner animation="grow" variant="danger" />
                    <Spinner animation="grow" variant="danger" />
                  </div>
                ) : (
                  <CustomerTable detail={listData} />
                )}
                <div className="text-end mt-2">
                  <BasicPagination
                    handlePaginateChange={handlePaginateChange}
                    page={page}
                    pageCount={pageCount}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ConfirmationModal
        open={open}
        setOpen={setOpen}
        uploadBulkCustomerFile={uploadBulkCustomerFile}
        setUploadLoading={setUploadLoading}
      />
    </div>
  );
}

export default CustomerList;
