
import React from "react";
import {
  BarChart,
  Bar,
  CartesianGrid,
  XAxis,
  YAxis,
  tickFormatter,
  Tooltip,
  Legend,
} from "recharts";
const DashoardBarGraph = ({ monthOrderCount }) => {
  const monthName = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const data = [
    { year: 2024, month: 1, count: 0 },
    { year: 2024, month: 3, count: 4550 },
    { year: 2024, month: 2, count: 400 },
    { year: 2024, month: 5, count: 7526 },
  ];

  let sortedData;
  let maxCount;
  if (monthOrderCount?.length) {
    sortedData = [...monthOrderCount].sort((a, b) => a.month - b.month);
    maxCount = Math.max(...sortedData.map((item) => item.count));
  }

  return (
    <div style={{ width: "100%" }}>
      <BarChart width={600} height={400} data={sortedData}>
        <Bar dataKey="count" fill="rgb(0, 149, 255)" />

        <Tooltip 
          formatter={(values, name, props) => {
            console.log(props)
            const { payload } = props;
            if(payload){const formatPayload = {
              ...payload,
              month: monthName[payload?.month - 1],
            };

            return [
              `Count: ${formatPayload.count}, Month: ${formatPayload.month}`,
            ];}else {return[]}
            
          }}
        />

        <XAxis
          dataKey="month"
          tickFormatter={(values) => monthName[values - 1]}
        />
        <YAxis domain={[0, maxCount]} />
        {/* <Legend /> */}
      </BarChart>
    </div>
  );
};

export default DashoardBarGraph;
