import axios from "axios";

const apiurl = process.env.REACT_APP_DEV_API_KEY_NEW;
const API = axios.create({
  baseURL: apiurl,
});

API.interceptors.request.use((req) => {
  if (localStorage.getItem("profile")) {
    req.headers.Authorization = `Bearer ${
      JSON.parse(localStorage.getItem("profile"))?.response?.token
    }`;
  }
  return req;
});

export const getPurchaseTickets = (data) =>
  API.post("/purchaseprojectstickets", data);
export const getPurchaseTicketsByProjectId = (data) =>
  API.post("/getprojectticketslist", data);
export const getMainGroupMasterList = (data) =>
  API.post("/listpurchasemaingroupmaster", data);
export const getSubGroupMasterList = (data) =>
  API.post("/listpurchasesubgroupmaster", data);
export const getUnitMasterList = (data) =>
  API.post("/listpurchaseunitmaster", data);
export const getCountryMasterList = (data) =>
  API.post("/listpurchasecountrymaster", data);
export const getStateMasterList = (data) =>
  API.post("/listpurchasestatemaster", data);
export const getCityMasterList = (data) =>
  API.post("/listpurchasecitymaster", data);
export const getStoreMasterList = (data) =>
  API.post("/listpurchasestoremaster", data);
export const getBrandMasterList = (data) =>
  API.post("/listpurchasebrandmaster", data);
export const getMaterialMasterList = (data) =>
  API.post("/listpurchasematerialmaster", data);
export const getItemMasterList = (data) =>
  API.post("/listpurchaseitemmaster", data);
export const getpurchaseItemDropdown = (data) =>
  API.post("/getpurchaseitemmasterdropdowns", data);
export const getPartyMasterList = (data) =>
  API.post("/listpurchasepartymaster", data);
export const getVendorDropDown = (data) =>
  API.post("/getpurchasepartymasterdropdowns", data);
export const addPurchaseVechileMasterApi = (data) =>
  API.post("/addpurchasevehiclemaster", data);
export const listPurchaseVechileMasterApi = (data) =>
  API.post("/listpurchasevehiclemaster", data);
export const listPurchaseTermsAndConditons = (data) =>
  API.post("/listpurchasetermmaster", data);
export const storeProjectWiseCoachFurnished = (data) =>
  API.post("/storeprojectwisecoachfurnished", data);
export const poOrderListApi = (data) => API.post("/listpurchaseorder", data);
export const updatePurchaseOrderApi = (data) =>
  API.post("/updatepurchaseorder", data);
export const getListItemBySow = (data) =>
  API.post("/getpurchaseprojectitems", data);
export const getPurchaseOrderPdfDetail = (data) =>
  API.post("/getpurchaseorderpdfdetails", data);
export const getBranchName = (data) =>
  API.post("/listpurchasebranchmaster", data);
export const getCurrencyList = (data) =>
  API.post("/listpurchasecurrencymaster", data);
export const addSchedulesDeliveryItems = (data) =>
  API.post("/addSchedulesDeliveryItems", data);
export const getSchedulesDeliveryItemsList = (data) =>
  API.post("/getSchedulesDeliveryItemsList", data);
export const getPurchaseDashboard = (data) =>
  API.post("/purchasedashboarddetails", data);

export const getSupplierPaymentApi = (data) =>
  API.post("/listsupplierpayment", data);

export const makeSupplierPaymentApi = (data) =>
  API.post("/makesupplierpayment", data);

export const paymentLogsApi = (data) =>
  API.post("/getsupplierpaymentlogs", data);
export const getProjectWiseCount = (data) =>
  API.post("/getprojectwisepocount", data);
