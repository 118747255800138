import axios from "axios";
import React, { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import Accordion from "react-bootstrap/Accordion";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import DashboardProgress from "../../../Components/DashboardProgress";
import { setLogout } from "../../../redux/features/authSlice";
import PendingStatusAlert from "./WarrantyTracker/PendingStatusAlert";
import { getPendingWarrantyTrackerList } from "../../../redux/api";
import { errorsMessage } from "../../../constant";
// import awarded from "../DashboardIcons/Tender/Awarded.svg";
import awarded from "../../../Tender/DashboardIcons/Tender/Awarded.svg";
import { getCurrentQuarter } from "../../../services/constant-variable";
import QuaterFilter from "../../../common/QuaterFilter";
import DashboardDeductionsModal from "../../../Modals/DashboardDeductionsModal";

function BackOfficeDashboard() {
  const baseUrl = process.env.REACT_APP_DEV_API_KEY_NEW;
  const user = JSON.parse(localStorage.getItem("profile"));
  const user_id = user?.response?.userDetails?.id;
  const getToken = JSON.parse(localStorage.getItem("profile"))?.response?.token;
  const bearer_token = `Bearer ${getToken}`;
  const [showDeductionModal , setShowDeductionModal] = useState(false);
  const config = { headers: { Authorization: bearer_token } };
  const [year, setYear] = useState("2024");
  const [financialData, setFinancialData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pendingStatusList, setPendingStatusList] = useState([]);
  const [cardsData, setCardsData] = useState([]);
  const dispatch = useDispatch();
  const [filters, setFilters] = useState({
    startDate: "",
    endDate: "",
    selectedYear: "",
    selectedQuarter: "",
  });

  function getFinancialYearTarget() {
    const url = baseUrl + "getfinancialyeardata";
    let data = { user_id, year, accessType: "view" };
    axios
      .post(url, data, config)
      .then((response) => {
        if (response.status === 200) {
          setFinancialData(response.data.response.getFinancialYearData);
        }
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          dispatch(setLogout());
          toast.error("Token is expired, Please Login again");
        }
      });
  }
  function PendingStatusTableList() {
    setLoading(true);
    getPendingWarrantyTrackerList({ user_id })
      .then((res) => {
        setPendingStatusList(res?.data?.response?.TrakerList);
      })
      .catch((err) => {
        errorsMessage(err?.response?.status);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function dashboardCardData() {
    const url = baseUrl + "getStoreDashboardData";
    let data = { user_id, ...filters };
    axios
      .post(url, data, config)
      .then((response) => {
        if (response.status === 200) {
          setCardsData(
            response.data.response?.getStoreDashboardData
              ?.projectCountCategoryWise
          );
        }
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          dispatch(setLogout());
          toast.error("Token is expired, Please Login again");
        }
      });
  }

  useEffect(() => {
    PendingStatusTableList();
  }, [getToken]);
  useEffect(() => {
    getFinancialYearTarget();
  }, [year]);

  useEffect(() => {
    if (filters?.startDate && filters?.endDate) {
      dashboardCardData();
    }
  }, [filters]);

  useEffect(() => {
    const currentDate = new Date();
    const currentQuarter = getCurrentQuarter(currentDate);
    setFilters({
      ...filters,
      selectedYear: currentDate.getFullYear(),
      selectedQuarter: currentQuarter,
    });
  }, []);

  return (
    <div className="page-wrapper">
      <div className="page-content-tab">
        <div className="container-fluid">
          {/* <!-- Page-Title --> */}
          <div className="row my-3">
            <div className="col-md-4">
              <h3 className=""> Dashboard</h3>
            </div>
            <div className="col-md-8">
              <QuaterFilter filters={filters} setFilters={setFilters} />
            </div>
          </div>
          {/* <!-- end page title end breadcrumb --> */}

          <div className="row">
            <div className="col-md-7 h-100">
              <div className="card overflow-hidden card-radius">
                <div className="card-body">
               
                  <div className="mt-4 table-border-radius">
                    <Table responsive>
                      <thead>
                        <tr className="bg-table-black">
                          <th
                            className="text-light"
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Click to Change Year"
                          >
                            {" "}
                            <select
                              className="form-control bg-black text-light border-0 fw-bold"
                              name="target_year"
                              style={{ cursor: "pointer" }}
                              value={year}
                              onChange={(e) => setYear(e.target.value)}
                            >
                              <option value="" disabled selected hidden>
                                Select a Target Year
                              </option>
                              <option value="2013">2013-2014</option>
                              <option value="2014">2014-2015</option>
                              <option value="2015">2015-2016</option>
                              <option value="2016">2016-2017</option>
                              <option value="2017">2017-2018</option>
                              <option value="2018">2018-2019</option>
                              <option value="2019">2019-2020</option>
                              <option value="2020">2020-2021</option>
                              <option value="2021">2021-2022</option>
                              <option value="2022">2022-2023</option>
                              <option value="2023">2023-2024</option>
                              <option value="2024">2024-2025</option>
                              <option value="2025">2025-2026</option>
                            </select>
                          </th>
                          <th className="text-light">Target</th>
                          <th className="text-light">Achieved</th>
                          <th className="text-light">Bidded Value</th>
                        </tr>
                      </thead>
                      <tbody>
                        {financialData.length ? (
                          financialData.map((element, index) => {
                            return (
                              <tr className="border-bottom">
                                <td className="border-end">
                                  <p className="dashboard-text text-danger">
                                    {element.target_name}
                                  </p>
                                </td>
                                <td className="border-end">
                                  <p className="dashboard-text">
                                    {Number(
                                      element.target_value
                                    )?.toLocaleString("en-IN", {
                                      style: "currency",
                                      currency: "INR",
                                    })}
                                  </p>
                                </td>
                                <td>
                                  <p className="dashboard-text">
                                    {" "}
                                    {Number(
                                      element.achieved_value
                                    )?.toLocaleString("en-IN", {
                                      style: "currency",
                                      currency: "INR",
                                    })}{" "}
                                  </p>
                                </td>
                                <td>
                                  <p className="dashboard-text">
                                    {" "}
                                    {Number(
                                      element.bidded_value
                                    )?.toLocaleString("en-IN", {
                                      style: "currency",
                                      currency: "INR",
                                    })}{" "}
                                  </p>
                                </td>

                                
                              </tr>
                            );
                          })
                        ) : (
                          <>
                            <tr>
                              <td colSpan="3">No Data for Selected Year</td>
                            </tr>
                          </>
                        )}
         
                      </tbody>
                    </Table>
                    <PendingStatusAlert pendingStatusList={pendingStatusList} />
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-5 h-100">
              <div className="row h-100">
                <div className="col-md-6 h-100">
                  <div className="card detail-card">
                    <div className="card-body">
                      <div className="row">
                        <div className="col-7 tender-typ">
                          <h5 className="fw-bold mt-0">Supply </h5>
                        </div>
                        <div className="col-5 text-end">
                          <h3
                            style={{ color: "#06B48A" }}
                            className="fw-bold mt-0"
                          >
                            {cardsData?.supply || 0}
                          </h3>
                        </div>
                        <div className="col-7 tender-type h-100"></div>
                        <div className="col-5 text-end">
                          <img src={awarded} alt="Icon" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 h-100">
                  <div className="card detail-card">
                    <div className="card-body">
                      <div className="row">
                        <div className="col-7 tender-type">
                          <h5 className="fw-bold mt-0">Toilet upgradation</h5>
                        </div>
                        <div className="col-5 text-end">
                          <h3
                            style={{ color: "#06B48A" }}
                            className="fw-bold mt-0"
                          >
                            {cardsData?.toilet_upgradation || 0}
                          </h3>
                        </div>
                        <div className="col-7 tender-type pt-3"></div>
                        <div className="col-5 text-end">
                          <img src={awarded} alt="Icon" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 h-100">
                  <div className="card detail-card">
                    <div className="card-body">
                      <div className="row">
                        <div className="col-7 tender-type h-100">
                          <h5 className="fw-bold mt-0">Turn Key Furnishing</h5>
                        </div>
                        <div className="col-5 text-end">
                          <h3
                            style={{ color: "#06B48A" }}
                            className="fw-bold mt-0"
                          >
                            {cardsData?.turn_key_furnishing || 0}
                          </h3>
                        </div>
                        <div className="col-7 tender-type pt-3"></div>
                        <div className="col-5 text-end">
                          <img src={awarded} alt="Icon" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-6 h-100">
                  <div className="card detail-card">
                    <div className="card-body">
                      <div className="row">
                        <div className="col-7 tender-type">
                          <h5 className="fw-bold mt-0">
                            Refurbishment and Conversion
                          </h5>
                        </div>
                        <div className="col-5 text-end">
                          <h3
                            style={{ color: "#06B48A" }}
                            className="fw-bold mt-0"
                          >
                            {cardsData?.refurbishment_and_conversion || 0}
                          </h3>
                        </div>
                        <div className="col-7 tender-type pt-3"></div>
                        <div className="col-5 text-end">
                          <img src={awarded} alt="Icon" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            
            </div>
          </div>

          <div className="row">
                <div className="col-12">
                  <div className="card card-radius overflow-hidden  ">
                    <div className="card-header">
                      <div className="row align-items-center">
                        <div className="col">
                          <h4 className="card-title">Progress Analysis </h4>
                        </div>
                        {/* <div className="col-auto">
                            <div className="dropdown">
                              <Link
                                to="#"
                                className="btn btn-sm btn-outline-light dropdown-toggle"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >
                                This Year
                                <i className="las la-angle-down ms-1"></i>
                              </Link>
                              <div className="dropdown-menu dropdown-menu-end">
                                <Link className="dropdown-item" to="#">
                                  Today
                                </Link>
                                <Link className="dropdown-item" to="#">
                                  Last Week
                                </Link>
                                <Link className="dropdown-item" to="#">
                                  Last Month
                                </Link>
                                <Link className="dropdown-item" to="#">
                                  This Year
                                </Link>
                              </div>
                            </div>
                          </div> */}
                      </div>
                    </div>
                    {/* <!--end card-header--> */}
                    <div className="card-body">
                      <div className="row">
                        <div className="col-md-12">
                          <DashboardProgress />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

          <div className="mb-4">
            <Accordion defaultActiveKey="0" className="table-border-radius">
              <Accordion.Item eventKey="0" className="table-border-radius">
                <Accordion.Header className="mt-0 bg-table-black">
                  FY 23-24 - Project Target (Turn Key)
                </Accordion.Header>
                <Accordion.Body className="p-0">
                  <div className="">
                    <Table className="mb-0 dashboard-table" responsive>
                      <thead>
                        <tr className="bg-white border">
                          <th className="border text-danger font-16 th-small">
                            SN
                          </th>
                          <th className="border text-danger font-16 ">
                            Manager
                          </th>
                          <th className="border text-danger font-16 ">
                            Projects
                          </th>
                          <th className="border text-danger font-16 th-small-mid">
                            Total
                          </th>
                          <th className="border text-danger font-16 th-mid">
                            Complete
                          </th>
                          <th className="border text-danger font-16 td-min-long">
                            DP
                          </th>
                          <th className="border text-danger font-16 th-long">
                            Status (as on 04 Oct)
                          </th>
                          <th className="border text-danger font-16">
                            <select
                              class="form-select form-select-lg text-danger fw-bold border-0"
                              name=""
                              id=""
                            >
                              <option value="">Oct</option>
                              <option value="">Nov</option>
                              <option value="">Dec</option>
                              <option value="">Jan</option>
                              <option value="">Feb</option>
                              <option value="">Mar</option>
                            </select>
                          </th>
                          <th className="border text-danger font-16 font-14">
                            Value
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr className="border">
                          <td className="border th-small">
                            <p>1</p>
                          </td>
                          <td className="border">
                            <p> Bheem Pandey</p>
                          </td>
                          <td className="border">
                            <p> MCF- DDG </p>
                          </td>
                          <td className="border td-mid">
                            <p> 63</p>
                          </td>
                          <td className="border ">
                            <p> 19</p>
                          </td>
                          <td className="border px-0 py-1">
                            <p className="text-danger border-bottom px-4 pb-2">
                              15-07.10.2023
                            </p>
                            <p className="border-bottom px-4 pb-2">
                              15-06.11.2023
                            </p>
                            <p className="px-4 pb-2">14-06.12.2023</p>
                          </td>
                          <td className="border">
                            <p>Project on time Pay by - 15 Oct</p>
                          </td>
                          <td className="border">
                            <p> 15</p>
                          </td>
                          <td className="border ">
                            <p> 122,842,015.97 </p>
                          </td>
                        </tr>
                        <tr className="border">
                          <td className="border">
                            <p>2</p>
                          </td>
                          <td className="border">
                            <p> Sivesh Mishra</p>
                          </td>
                          <td className="border">
                            <p> BEML- Mockup </p>
                          </td>
                          <td className="border">
                            <p> 1</p>
                          </td>
                          <td className="border">
                            <p> 0</p>
                          </td>
                          <td className="border px-0 py-1">
                            <p className="px-4 pb-2">
                              20.09.2023 <br /> (to re-fix)
                            </p>
                          </td>

                          <td className="border">
                            <p className="text-danger">
                              80% work completed expected by 15 Oct Billing &
                              payment - 10 day process Pay by - 30 Oct
                            </p>
                          </td>
                          <td className="border">
                            <p> 1</p>
                          </td>
                          <td className="border th-min-long">
                            <p> 5,896,000.00 </p>
                          </td>
                        </tr>
                        <tr className="border">
                          <td className="border">
                            <p>3</p>
                          </td>
                          <td className="border">
                            <p> Dheeraj</p>
                          </td>
                          <td className="border px-0 py-1">
                            <p className="border-bottom px-4 pb-2">Vijaywada</p>
                            <p className="px-4 pb-2">BCL</p>
                          </td>
                          <td className="border px-0 py-1">
                            <p className="border-bottom px-4 pb-2"> 40</p>
                            <p className="px-4 pb-2"> 217</p>
                          </td>
                          <td className="border px-0 py-1">
                            <p className="border-bottom px-4 pb-2"> 33</p>
                            <p className="px-4 pb-2"> 50</p>
                          </td>
                          <td className="border px-0 py-1">
                            <p className="border-bottom px-4 pb-2">
                              {" "}
                              13.10.2023
                            </p>
                            <p className="px-4 pb-2"> 08.10.2024</p>
                          </td>
                          <td className="border px-0 py-1">
                            <p className="text-danger border-bottom px-4 pb-2">
                              {" "}
                              34-35 - offer for inspection on 30 Set 36 - Offer
                              by 3 Oct
                            </p>
                            <p className="text-danger px-4 pb-2">
                              {" "}
                              today lrc- dy by wed- frid - braith pay
                            </p>
                          </td>
                          <td className="border px-0 py-1">
                            <p className="border-bottom px-4 pb-2">
                              {" "}
                              2 (37-38)
                            </p>
                            <p className="px-4 pb-2"> 15 (56-70)</p>
                          </td>
                          <td className="border px-0 py-1">
                            <p className=" border-bottom px-4 pb-2">
                              17,687,814.30
                            </p>
                            <p className="px-4 pb-2">&nbsp; </p>
                          </td>
                        </tr>
                        <tr className="border">
                          <td className="border">
                            <p>4</p>
                          </td>
                          <td className="border">
                            <p> RK Pandey</p>
                          </td>
                          <td className="border px-0 py-1">
                            <p className="border-bottom px-4 pb-2">
                              {" "}
                              CR- Perambur
                            </p>
                            <p className="border-bottom px-4 pb-2"> CRGKP</p>
                            <p className="border-bottom px-4 pb-2">
                              {" "}
                              Bhavnagar
                            </p>
                            <p className="border-bottom px-4 pb-2">
                              {" "}
                              ICF LRAAC
                            </p>
                            <p className="border-bottom px-4 pb-2">
                              {" "}
                              ICF - LRAAC
                            </p>
                            <p className="border-bottom px-4 pb-2"> RCF RA</p>
                            <p className="border-bottom px-4 pb-2"> NER- NMG</p>
                            <p className="px-4 pb-2"> NR RCNK</p>
                          </td>
                          <td className="border px-0 py-1">
                            <p className="border-bottom px-4 pb-2"> 16</p>
                            <p className="border-bottom px-4 pb-2"> 65</p>
                            <p className="border-bottom px-4 pb-2"> 25</p>
                            <p className="border-bottom px-4 pb-2"> 2</p>
                            <p className="border-bottom px-4 pb-2"> 6</p>
                            <p className="border-bottom px-4 pb-2"> 22</p>
                            <p className="border-bottom px-4 pb-2"> 175</p>
                            <p className="px-4 pb-2"> 80</p>
                          </td>
                          <td className="border px-0 py-1">
                            <p className="border-bottom px-4 pb-2"> 8</p>
                            <p className="border-bottom px-4 pb-2"> 41</p>
                            <p className="border-bottom px-4 pb-2"> 14</p>
                            <p className="border-bottom px-4 pb-2"> 1</p>
                            <p className="border-bottom px-4 pb-2"> 0</p>
                            <p className="border-bottom px-4 pb-2"> 2</p>
                            <p className="border-bottom px-4 pb-2"> 0</p>
                            <p className="px-4 pb-2"> 0</p>
                          </td>
                          <td className="border px-0 py-1">
                            <p className="border-bottom px-4 pb-2">
                              {" "}
                              31.01.2024
                            </p>
                            <p className="border-bottom px-4 pb-2">
                              {" "}
                              31.01.2024
                            </p>
                            <p className="border-bottom px-4 pb-2">
                              {" "}
                              30.11.2023
                            </p>
                            <p className="border-bottom px-4 pb-2">
                              {" "}
                              30.08.2023
                            </p>
                            <p className="border-bottom px-4 pb-2">
                              {" "}
                              31.03.2024
                            </p>
                            <p className="border-bottom px-4 pb-2">
                              {" "}
                              25.08.2023
                            </p>
                            <p className="border-bottom px-4 pb-2">
                              {" "}
                              18.09.2024
                            </p>
                            <p className="px-4 pb-2">03.05.2024</p>
                          </td>
                          <td className="border px-0 py-1">
                            <p className="border-bottom px-4 pb-2">&nbsp;</p>
                            <p className="border-bottom px-4 pb-2">&nbsp;</p>
                            <p className="border-bottom px-4 pb-2">&nbsp;</p>
                            <p className="border-bottom px-4 pb-2">&nbsp;</p>
                            <p className="border-bottom px-4 pb-2">&nbsp;</p>
                            <p className="border-bottom px-4 pb-2">&nbsp;</p>
                            <p className="border-bottom px-4 pb-2">&nbsp;</p>
                            <p className="px-4 pb-2">&nbsp;</p>
                          </td>
                          <td className="border px-0 py-1">
                            <p className="border-bottom px-4 pb-2">&nbsp;</p>
                            <p className="border-bottom px-4 pb-2">&nbsp;</p>
                            <p className="border-bottom px-4 pb-2"> 4</p>
                            <p className="border-bottom px-4 pb-2">&nbsp;</p>
                            <p className="border-bottom px-4 pb-2"> 1</p>
                            <p className="border-bottom px-4 pb-2"> 3</p>
                            <p className="border-bottom px-4 pb-2"> 15</p>
                            <p className="px-4 pb-2">&nbsp;</p>
                          </td>
                          <td className="border px-0 py-1">
                            <p className="border-bottom px-4 pb-2">&nbsp; </p>
                            <p className="border-bottom px-4 pb-2">
                              27,069,096.00{" "}
                            </p>
                            <p className="border-bottom px-4 pb-2">
                              2,839,501.56
                            </p>
                            <p className="border-bottom px-4 pb-2">
                              {" "}
                              5,961,950.00
                            </p>
                            <p className="border-bottom px-4 pb-2">
                              43,442,970.91
                            </p>
                            <p className="border-bottom px-4 pb-2">
                              119,889,824.00
                            </p>
                            <p className="border-bottom px-4 pb-2">
                              27,105,231.60{" "}
                            </p>
                            <p className="px-4 pb-2">324,987,474.05</p>
                          </td>
                        </tr>
                        <tr>
                          <td className="px-3" colSpan={9}>
                            <div className="d-flex justify-content-between">
                              <h6 className="font-16">Total</h6>
                              <h6 className="font-16">982,709,614.11</h6>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1" className="table-border-radius mt-4">
                <Accordion.Header className="bg-table-black mt-0" r>
                  Supply & Installation
                </Accordion.Header>
                <Accordion.Body className="p-0">
                  <div>
                    <Table className="mb-0 dashboard-table" responsive>
                      <thead>
                        <tr className="bg-white border">
                          <th className="border text-danger font-16 th-small">
                            SN
                          </th>
                          <th className="border text-danger font-16 ">
                            Manager
                          </th>
                          <th className="border text-danger font-16 ">
                            Projects
                          </th>
                          <th className="border text-danger font-16 th-small-mid">
                            Total
                          </th>
                          <th className="border text-danger font-16 th-mid">
                            Complete
                          </th>
                          <th className="border text-danger font-16 td-min-long">
                            DP
                          </th>
                          <th className="border text-danger font-16 th-long">
                            Status (as on 04 Oct)
                          </th>
                          <th className="border text-danger font-16">
                            <select
                              class="form-select form-select-lg text-danger fw-bold border-0"
                              name=""
                              id=""
                            >
                              <option value="">Oct</option>
                              <option value="">Nov</option>
                              <option value="">Dec</option>
                              <option value="">Jan</option>
                              <option value="">Feb</option>
                              <option value="">Mar</option>
                            </select>
                          </th>
                          <th className="border text-danger font-16 font-14">
                            Value
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr className="border">
                          <td className="border th-small">
                            <p>1</p>
                          </td>
                          <td className="border">
                            <p> Bheem Pandey</p>
                          </td>
                          <td className="border">
                            <p> MCF- DDG </p>
                          </td>
                          <td className="border td-mid">
                            <p> 63</p>
                          </td>
                          <td className="border ">
                            <p> 19</p>
                          </td>
                          <td className="border px-0 py-1">
                            <p className="text-danger border-bottom px-4 pb-2">
                              15-07.10.2023
                            </p>
                            <p className="border-bottom px-4 pb-2">
                              15-06.11.2023
                            </p>
                            <p className="px-4 pb-2">14-06.12.2023</p>
                          </td>
                          <td className="border">
                            <p>Project on time Pay by - 15 Oct</p>
                          </td>
                          <td className="border">
                            <p> 15</p>
                          </td>
                          <td className="border ">
                            <p> 122,842,015.97 </p>
                          </td>
                        </tr>
                        <tr className="border">
                          <td className="border">
                            <p>2</p>
                          </td>
                          <td className="border">
                            <p> Sivesh Mishra</p>
                          </td>
                          <td className="border">
                            <p> BEML- Mockup </p>
                          </td>
                          <td className="border">
                            <p> 1</p>
                          </td>
                          <td className="border">
                            <p> 0</p>
                          </td>
                          <td className="border px-0 py-1">
                            <p className="px-4 pb-2">
                              20.09.2023 <br /> (to re-fix)
                            </p>
                          </td>

                          <td className="border">
                            <p className="text-danger">
                              80% work completed expected by 15 Oct Billing &
                              payment - 10 day process Pay by - 30 Oct
                            </p>
                          </td>
                          <td className="border">
                            <p> 1</p>
                          </td>
                          <td className="border th-min-long">
                            <p> 5,896,000.00 </p>
                          </td>
                        </tr>
                        <tr className="border">
                          <td className="border">
                            <p>3</p>
                          </td>
                          <td className="border">
                            <p> Dheeraj</p>
                          </td>
                          <td className="border px-0 py-1">
                            <p className="border-bottom px-4 pb-2">Vijaywada</p>
                            <p className="px-4 pb-2">BCL</p>
                          </td>
                          <td className="border px-0 py-1">
                            <p className="border-bottom px-4 pb-2"> 40</p>
                            <p className="px-4 pb-2"> 217</p>
                          </td>
                          <td className="border px-0 py-1">
                            <p className="border-bottom px-4 pb-2"> 33</p>
                            <p className="px-4 pb-2"> 50</p>
                          </td>
                          <td className="border px-0 py-1">
                            <p className="border-bottom px-4 pb-2">
                              {" "}
                              13.10.2023
                            </p>
                            <p className="px-4 pb-2"> 08.10.2024</p>
                          </td>
                          <td className="border px-0 py-1">
                            <p className="text-danger border-bottom px-4 pb-2">
                              {" "}
                              34-35 - offer for inspection on 30 Set 36 - Offer
                              by 3 Oct
                            </p>
                            <p className="text-danger px-4 pb-2">
                              {" "}
                              today lrc- dy by wed- frid - braith pay
                            </p>
                          </td>
                          <td className="border px-0 py-1">
                            <p className="border-bottom px-4 pb-2">
                              {" "}
                              2 (37-38)
                            </p>
                            <p className="px-4 pb-2"> 15 (56-70)</p>
                          </td>
                          <td className="border px-0 py-1">
                            <p className=" border-bottom px-4 pb-2">
                              17,687,814.30
                            </p>
                            <p className="px-4 pb-2">&nbsp; </p>
                          </td>
                        </tr>
                        <tr className="border">
                          <td className="border">
                            <p>4</p>
                          </td>
                          <td className="border">
                            <p> RK Pandey</p>
                          </td>
                          <td className="border px-0 py-1">
                            <p className="border-bottom px-4 pb-2">
                              {" "}
                              CR- Perambur
                            </p>
                            <p className="border-bottom px-4 pb-2"> CRGKP</p>
                            <p className="border-bottom px-4 pb-2">
                              {" "}
                              Bhavnagar
                            </p>
                            <p className="border-bottom px-4 pb-2">
                              {" "}
                              ICF LRAAC
                            </p>
                            <p className="border-bottom px-4 pb-2">
                              {" "}
                              ICF - LRAAC
                            </p>
                            <p className="border-bottom px-4 pb-2"> RCF RA</p>
                            <p className="border-bottom px-4 pb-2"> NER- NMG</p>
                            <p className="px-4 pb-2"> NR RCNK</p>
                          </td>
                          <td className="border px-0 py-1">
                            <p className="border-bottom px-4 pb-2"> 16</p>
                            <p className="border-bottom px-4 pb-2"> 65</p>
                            <p className="border-bottom px-4 pb-2"> 25</p>
                            <p className="border-bottom px-4 pb-2"> 2</p>
                            <p className="border-bottom px-4 pb-2"> 6</p>
                            <p className="border-bottom px-4 pb-2"> 22</p>
                            <p className="border-bottom px-4 pb-2"> 175</p>
                            <p className="px-4 pb-2"> 80</p>
                          </td>
                          <td className="border px-0 py-1">
                            <p className="border-bottom px-4 pb-2"> 8</p>
                            <p className="border-bottom px-4 pb-2"> 41</p>
                            <p className="border-bottom px-4 pb-2"> 14</p>
                            <p className="border-bottom px-4 pb-2"> 1</p>
                            <p className="border-bottom px-4 pb-2"> 0</p>
                            <p className="border-bottom px-4 pb-2"> 2</p>
                            <p className="border-bottom px-4 pb-2"> 0</p>
                            <p className="px-4 pb-2"> 0</p>
                          </td>
                          <td className="border px-0 py-1">
                            <p className="border-bottom px-4 pb-2">
                              {" "}
                              31.01.2024
                            </p>
                            <p className="border-bottom px-4 pb-2">
                              {" "}
                              31.01.2024
                            </p>
                            <p className="border-bottom px-4 pb-2">
                              {" "}
                              30.11.2023
                            </p>
                            <p className="border-bottom px-4 pb-2">
                              {" "}
                              30.08.2023
                            </p>
                            <p className="border-bottom px-4 pb-2">
                              {" "}
                              31.03.2024
                            </p>
                            <p className="border-bottom px-4 pb-2">
                              {" "}
                              25.08.2023
                            </p>
                            <p className="border-bottom px-4 pb-2">
                              {" "}
                              18.09.2024
                            </p>
                            <p className="px-4 pb-2">03.05.2024</p>
                          </td>
                          <td className="border px-0 py-1">
                            <p className="border-bottom px-4 pb-2">&nbsp;</p>
                            <p className="border-bottom px-4 pb-2">&nbsp;</p>
                            <p className="border-bottom px-4 pb-2">&nbsp;</p>
                            <p className="border-bottom px-4 pb-2">&nbsp;</p>
                            <p className="border-bottom px-4 pb-2">&nbsp;</p>
                            <p className="border-bottom px-4 pb-2">&nbsp;</p>
                            <p className="border-bottom px-4 pb-2">&nbsp;</p>
                            <p className="px-4 pb-2">&nbsp;</p>
                          </td>
                          <td className="border px-0 py-1">
                            <p className="border-bottom px-4 pb-2">&nbsp;</p>
                            <p className="border-bottom px-4 pb-2">&nbsp;</p>
                            <p className="border-bottom px-4 pb-2"> 4</p>
                            <p className="border-bottom px-4 pb-2">&nbsp;</p>
                            <p className="border-bottom px-4 pb-2"> 1</p>
                            <p className="border-bottom px-4 pb-2"> 3</p>
                            <p className="border-bottom px-4 pb-2"> 15</p>
                            <p className="px-4 pb-2">&nbsp;</p>
                          </td>
                          <td className="border px-0 py-1">
                            <p className="border-bottom px-4 pb-2">&nbsp; </p>
                            <p className="border-bottom px-4 pb-2">
                              27,069,096.00{" "}
                            </p>
                            <p className="border-bottom px-4 pb-2">
                              2,839,501.56
                            </p>
                            <p className="border-bottom px-4 pb-2">
                              {" "}
                              5,961,950.00
                            </p>
                            <p className="border-bottom px-4 pb-2">
                              43,442,970.91
                            </p>
                            <p className="border-bottom px-4 pb-2">
                              119,889,824.00
                            </p>
                            <p className="border-bottom px-4 pb-2">
                              27,105,231.60{" "}
                            </p>
                            <p className="px-4 pb-2">324,987,474.05</p>
                          </td>
                        </tr>
                        <tr>
                          <td className="px-3" colSpan={9}>
                            <div className="d-flex justify-content-between">
                              <h6 className="font-16">Total</h6>
                              <h6 className="font-16">982,709,614.11</h6>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2" className="table-border-radius mt-4">
                <Accordion.Header className="bg-table-black mt-0">
                  VCB
                </Accordion.Header>
                <Accordion.Body className="p-0">
                  <div>
                    <Table className="mb-0 dashboard-table" responsive>
                      <thead>
                        <tr className="bg-white border">
                          <th className="border text-danger font-16 th-small">
                            SN
                          </th>
                          <th className="border text-danger font-16 ">
                            Manager
                          </th>
                          <th className="border text-danger font-16 ">
                            Projects
                          </th>
                          <th className="border text-danger font-16 th-small-mid">
                            Total
                          </th>
                          <th className="border text-danger font-16 th-mid">
                            Complete
                          </th>
                          <th className="border text-danger font-16 td-min-long">
                            DP
                          </th>
                          <th className="border text-danger font-16 th-long">
                            Status (as on 04 Oct)
                          </th>
                          <th className="border text-danger font-16">
                            <select
                              class="form-select form-select-lg text-danger fw-bold border-0"
                              name=""
                              id=""
                            >
                              <option value="">Oct</option>
                              <option value="">Nov</option>
                              <option value="">Dec</option>
                              <option value="">Jan</option>
                              <option value="">Feb</option>
                              <option value="">Mar</option>
                            </select>
                          </th>
                          <th className="border text-danger font-16 font-14">
                            Value
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr className="border">
                          <td className="border th-small">
                            <p>1</p>
                          </td>
                          <td className="border">
                            <p> Bheem Pandey</p>
                          </td>
                          <td className="border">
                            <p> MCF- DDG </p>
                          </td>
                          <td className="border td-mid">
                            <p> 63</p>
                          </td>
                          <td className="border ">
                            <p> 19</p>
                          </td>
                          <td className="border px-0 py-1">
                            <p className="text-danger border-bottom px-4 pb-2">
                              15-07.10.2023
                            </p>
                            <p className="border-bottom px-4 pb-2">
                              15-06.11.2023
                            </p>
                            <p className="px-4 pb-2">14-06.12.2023</p>
                          </td>
                          <td className="border">
                            <p>Project on time Pay by - 15 Oct</p>
                          </td>
                          <td className="border">
                            <p> 15</p>
                          </td>
                          <td className="border ">
                            <p> 122,842,015.97 </p>
                          </td>
                        </tr>
                        <tr className="border">
                          <td className="border">
                            <p>2</p>
                          </td>
                          <td className="border">
                            <p> Sivesh Mishra</p>
                          </td>
                          <td className="border">
                            <p> BEML- Mockup </p>
                          </td>
                          <td className="border">
                            <p> 1</p>
                          </td>
                          <td className="border">
                            <p> 0</p>
                          </td>
                          <td className="border px-0 py-1">
                            <p className="px-4 pb-2">
                              20.09.2023 <br /> (to re-fix)
                            </p>
                          </td>

                          <td className="border">
                            <p className="text-danger">
                              80% work completed expected by 15 Oct Billing &
                              payment - 10 day process Pay by - 30 Oct
                            </p>
                          </td>
                          <td className="border">
                            <p> 1</p>
                          </td>
                          <td className="border th-min-long">
                            <p> 5,896,000.00 </p>
                          </td>
                        </tr>
                        <tr className="border">
                          <td className="border">
                            <p>3</p>
                          </td>
                          <td className="border">
                            <p> Dheeraj</p>
                          </td>
                          <td className="border px-0 py-1">
                            <p className="border-bottom px-4 pb-2">Vijaywada</p>
                            <p className="px-4 pb-2">BCL</p>
                          </td>
                          <td className="border px-0 py-1">
                            <p className="border-bottom px-4 pb-2"> 40</p>
                            <p className="px-4 pb-2"> 217</p>
                          </td>
                          <td className="border px-0 py-1">
                            <p className="border-bottom px-4 pb-2"> 33</p>
                            <p className="px-4 pb-2"> 50</p>
                          </td>
                          <td className="border px-0 py-1">
                            <p className="border-bottom px-4 pb-2">
                              {" "}
                              13.10.2023
                            </p>
                            <p className="px-4 pb-2"> 08.10.2024</p>
                          </td>
                          <td className="border px-0 py-1">
                            <p className="text-danger border-bottom px-4 pb-2">
                              {" "}
                              34-35 - offer for inspection on 30 Set 36 - Offer
                              by 3 Oct
                            </p>
                            <p className="text-danger px-4 pb-2">
                              {" "}
                              today lrc- dy by wed- frid - braith pay
                            </p>
                          </td>
                          <td className="border px-0 py-1">
                            <p className="border-bottom px-4 pb-2">
                              {" "}
                              2 (37-38)
                            </p>
                            <p className="px-4 pb-2"> 15 (56-70)</p>
                          </td>
                          <td className="border px-0 py-1">
                            <p className=" border-bottom px-4 pb-2">
                              17,687,814.30
                            </p>
                            <p className="px-4 pb-2">&nbsp; </p>
                          </td>
                        </tr>
                        <tr className="border">
                          <td className="border">
                            <p>4</p>
                          </td>
                          <td className="border">
                            <p> RK Pandey</p>
                          </td>
                          <td className="border px-0 py-1">
                            <p className="border-bottom px-4 pb-2">
                              {" "}
                              CR- Perambur
                            </p>
                            <p className="border-bottom px-4 pb-2"> CRGKP</p>
                            <p className="border-bottom px-4 pb-2">
                              {" "}
                              Bhavnagar
                            </p>
                            <p className="border-bottom px-4 pb-2">
                              {" "}
                              ICF LRAAC
                            </p>
                            <p className="border-bottom px-4 pb-2">
                              {" "}
                              ICF - LRAAC
                            </p>
                            <p className="border-bottom px-4 pb-2"> RCF RA</p>
                            <p className="border-bottom px-4 pb-2"> NER- NMG</p>
                            <p className="px-4 pb-2"> NR RCNK</p>
                          </td>
                          <td className="border px-0 py-1">
                            <p className="border-bottom px-4 pb-2"> 16</p>
                            <p className="border-bottom px-4 pb-2"> 65</p>
                            <p className="border-bottom px-4 pb-2"> 25</p>
                            <p className="border-bottom px-4 pb-2"> 2</p>
                            <p className="border-bottom px-4 pb-2"> 6</p>
                            <p className="border-bottom px-4 pb-2"> 22</p>
                            <p className="border-bottom px-4 pb-2"> 175</p>
                            <p className="px-4 pb-2"> 80</p>
                          </td>
                          <td className="border px-0 py-1">
                            <p className="border-bottom px-4 pb-2"> 8</p>
                            <p className="border-bottom px-4 pb-2"> 41</p>
                            <p className="border-bottom px-4 pb-2"> 14</p>
                            <p className="border-bottom px-4 pb-2"> 1</p>
                            <p className="border-bottom px-4 pb-2"> 0</p>
                            <p className="border-bottom px-4 pb-2"> 2</p>
                            <p className="border-bottom px-4 pb-2"> 0</p>
                            <p className="px-4 pb-2"> 0</p>
                          </td>
                          <td className="border px-0 py-1">
                            <p className="border-bottom px-4 pb-2">
                              {" "}
                              31.01.2024
                            </p>
                            <p className="border-bottom px-4 pb-2">
                              {" "}
                              31.01.2024
                            </p>
                            <p className="border-bottom px-4 pb-2">
                              {" "}
                              30.11.2023
                            </p>
                            <p className="border-bottom px-4 pb-2">
                              {" "}
                              30.08.2023
                            </p>
                            <p className="border-bottom px-4 pb-2">
                              {" "}
                              31.03.2024
                            </p>
                            <p className="border-bottom px-4 pb-2">
                              {" "}
                              25.08.2023
                            </p>
                            <p className="border-bottom px-4 pb-2">
                              {" "}
                              18.09.2024
                            </p>
                            <p className="px-4 pb-2">03.05.2024</p>
                          </td>
                          <td className="border px-0 py-1">
                            <p className="border-bottom px-4 pb-2">&nbsp;</p>
                            <p className="border-bottom px-4 pb-2">&nbsp;</p>
                            <p className="border-bottom px-4 pb-2">&nbsp;</p>
                            <p className="border-bottom px-4 pb-2">&nbsp;</p>
                            <p className="border-bottom px-4 pb-2">&nbsp;</p>
                            <p className="border-bottom px-4 pb-2">&nbsp;</p>
                            <p className="border-bottom px-4 pb-2">&nbsp;</p>
                            <p className="px-4 pb-2">&nbsp;</p>
                          </td>
                          <td className="border px-0 py-1">
                            <p className="border-bottom px-4 pb-2">&nbsp;</p>
                            <p className="border-bottom px-4 pb-2">&nbsp;</p>
                            <p className="border-bottom px-4 pb-2"> 4</p>
                            <p className="border-bottom px-4 pb-2">&nbsp;</p>
                            <p className="border-bottom px-4 pb-2"> 1</p>
                            <p className="border-bottom px-4 pb-2"> 3</p>
                            <p className="border-bottom px-4 pb-2"> 15</p>
                            <p className="px-4 pb-2">&nbsp;</p>
                          </td>
                          <td className="border px-0 py-1">
                            <p className="border-bottom px-4 pb-2">&nbsp; </p>
                            <p className="border-bottom px-4 pb-2">
                              27,069,096.00{" "}
                            </p>
                            <p className="border-bottom px-4 pb-2">
                              2,839,501.56
                            </p>
                            <p className="border-bottom px-4 pb-2">
                              {" "}
                              5,961,950.00
                            </p>
                            <p className="border-bottom px-4 pb-2">
                              43,442,970.91
                            </p>
                            <p className="border-bottom px-4 pb-2">
                              119,889,824.00
                            </p>
                            <p className="border-bottom px-4 pb-2">
                              27,105,231.60{" "}
                            </p>
                            <p className="px-4 pb-2">324,987,474.05</p>
                          </td>
                        </tr>
                        <tr>
                          <td className="px-3" colSpan={9}>
                            <div className="d-flex justify-content-between">
                              <h6 className="font-16">Total</h6>
                              <h6 className="font-16">982,709,614.11</h6>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
          <div className="row">
            <div className="col-lg-3">
              <div className="card table-border-radius">
                <Table responsive>
                  <thead>
                    <tr className="bg-table-black">
                      <th colSpan={2} className="text-light">
                         Amount Billed
                      </th>
                    </tr>
                    <tr className="bg-white">
                      <th>Month</th>
                      <th>Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="border-bottom">
                      <td className="border-end">
                        <p> April </p>
                      </td>
                      <td>
                        <p> #REF! </p>
                      </td>
                    </tr>
                    <tr className="border-bottom">
                      <td className="border-end text-danger">
                        <p> May </p>
                      </td>
                      <td>
                        <p> #REF! </p>
                      </td>
                    </tr>
                    <tr className="border-bottom">
                      <td className="border-end text-danger">
                        <p> June </p>
                      </td>
                      <td>
                        <p> #REF! </p>
                      </td>
                    </tr>
                    <tr className="border-bottom">
                      <td className="border-end text-danger">
                        <p> July </p>
                      </td>
                      <td>
                        <p> #REF! </p>
                      </td>
                    </tr>
                    <tr className="border-bottom">
                      <td className="border-end text-danger">
                        <p> August </p>
                      </td>
                      <td>
                        <p> #REF! </p>
                      </td>
                    </tr>
                    <tr className="border-bottom">
                      <td className="border-end text-danger">
                        <p> September </p>
                      </td>
                      <td>
                        <p>&nbsp;</p>
                      </td>
                    </tr>
                    <tr className="border-bottom">
                      <td className="border-end text-danger">
                        <p> October </p>
                      </td>
                      <td>
                        <p></p>
                      </td>
                    </tr>
                    <tr className="border-bottom">
                      <td className="border-end text-danger">
                        <p> November </p>
                      </td>
                      <td>
                        <p></p>
                      </td>
                    </tr>
                    <tr className="border-bottom">
                      <td className="border-end text-danger">
                        <p> December </p>
                      </td>
                      <td>
                        <p></p>
                      </td>
                    </tr>
                    <tr className="border-bottom">
                      <td className="border-end text-danger">
                        <p> January </p>
                      </td>
                      <td>
                        <p></p>
                      </td>
                    </tr>
                    <tr className="border-bottom">
                      <td className="border-end text-danger">
                        <p> February </p>
                      </td>
                      <td>
                        <p></p>
                      </td>
                    </tr>
                    <tr className="border-bottom">
                      <td className="border-end text-danger">
                        <p> March </p>
                      </td>
                      <td>
                        <p></p>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={2} className="px-3">
                        <div className="d-flex justify-content-between">
                          <div className="text-start">
                            <h6 className="font-16">Total</h6>
                            <h6 className="font-16">Target</h6>
                            <h6 className="font-16">Balance Target</h6>
                          </div>
                          <div className="text-end">
                            <h6 className="text-danger font-16">#REF!</h6>
                            <h6 className="text-danger font-16">
                              1,500,000,000.00
                            </h6>
                            <h6 className="text-danger font-16">#REF!</h6>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="card table-border-radius ">
                <Table responsive>
                  <thead>
                    <tr className="bg-table-black">
                      <th colSpan={2} className="text-light">
                        Target
                      </th>
                    </tr>
                    <tr className="bg-white">
                      <th>Month</th>
                      <th>Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="border-bottom">
                      <td className="border-end">
                        <p> April </p>
                      </td>
                      <td>
                        <p> #REF! </p>
                      </td>
                    </tr>
                    <tr className="border-bottom">
                      <td className="border-end text-danger">
                        <p> May </p>
                      </td>
                      <td>
                        <p> #REF! </p>
                      </td>
                    </tr>
                    <tr className="border-bottom">
                      <td className="border-end text-danger">
                        <p> June </p>
                      </td>
                      <td>
                        <p> #REF! </p>
                      </td>
                    </tr>
                    <tr className="border-bottom">
                      <td className="border-end text-danger">
                        <p> July </p>
                      </td>
                      <td>
                        <p> #REF! </p>
                      </td>
                    </tr>
                    <tr className="border-bottom">
                      <td className="border-end text-danger">
                        <p> August </p>
                      </td>
                      <td>
                        <p> #REF! </p>
                      </td>
                    </tr>
                    <tr className="border-bottom">
                      <td className="border-end text-danger">
                        <p> September </p>
                      </td>
                      <td>
                        <p></p>
                      </td>
                    </tr>
                    <tr className="border-bottom">
                      <td className="border-end text-danger">
                        <p> October </p>
                      </td>
                      <td>
                        <p></p>
                      </td>
                    </tr>
                    <tr className="border-bottom">
                      <td className="border-end text-danger">
                        <p> November </p>
                      </td>
                      <td>
                        <p></p>
                      </td>
                    </tr>
                    <tr className="border-bottom">
                      <td className="border-end text-danger">
                        <p> December </p>
                      </td>
                      <td>
                        <p></p>
                      </td>
                    </tr>
                    <tr className="border-bottom">
                      <td className="border-end text-danger">
                        <p> January </p>
                      </td>
                      <td>
                        <p></p>
                      </td>
                    </tr>
                    <tr className="border-bottom">
                      <td className="border-end text-danger">
                        <p> February </p>
                      </td>
                      <td>
                        <p></p>
                      </td>
                    </tr>
                    <tr className="border-bottom">
                      <td className="border-end text-danger">
                        <p> March </p>
                      </td>
                      <td>
                        <p></p>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={2} className="px-3">
                        <div className="d-flex justify-content-between">
                          <h6 className="font-16">Total</h6>
                          <h6 className="text-danger font-16">
                            549,412,589.80
                          </h6>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="card table-border-radius">
                <Table responsive>
                  <thead>
                    <tr className="bg-table-black">
                      <th colSpan={2} className="text-light">
                         Payment Received
                      </th>
                    </tr>
                    <tr className="bg-white">
                      <th>Month</th>
                      <th>Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="border-bottom">
                      <td className="border-end">
                        <p> April </p>
                      </td>
                      <td>
                        <p> #REF! </p>
                      </td>
                    </tr>
                    <tr className="border-bottom">
                      <td className="border-end text-danger">
                        <p> May </p>
                      </td>
                      <td>
                        <p> #REF! </p>
                      </td>
                    </tr>
                    <tr className="border-bottom">
                      <td className="border-end text-danger">
                        <p> June </p>
                      </td>
                      <td>
                        <p> #REF! </p>
                      </td>
                    </tr>
                    <tr className="border-bottom">
                      <td className="border-end text-danger">
                        <p> July </p>
                      </td>
                      <td>
                        <p> #REF! </p>
                      </td>
                    </tr>
                    <tr className="border-bottom">
                      <td className="border-end text-danger">
                        <p> August </p>
                      </td>
                      <td>
                        <p> #REF! </p>
                      </td>
                    </tr>
                    <tr className="border-bottom">
                      <td className="border-end text-danger">
                        <p> September </p>
                      </td>
                      <td>
                        <p>&nbsp;</p>
                      </td>
                    </tr>
                    <tr className="border-bottom">
                      <td className="border-end text-danger">
                        <p> October </p>
                      </td>
                      <td>
                        <p></p>
                      </td>
                    </tr>
                    <tr className="border-bottom">
                      <td className="border-end text-danger">
                        <p> November </p>
                      </td>
                      <td>
                        <p></p>
                      </td>
                    </tr>
                    <tr className="border-bottom">
                      <td className="border-end text-danger">
                        <p> December </p>
                      </td>
                      <td>
                        <p></p>
                      </td>
                    </tr>
                    <tr className="border-bottom">
                      <td className="border-end text-danger">
                        <p> January </p>
                      </td>
                      <td>
                        <p></p>
                      </td>
                    </tr>
                    <tr className="border-bottom">
                      <td className="border-end text-danger">
                        <p> February </p>
                      </td>
                      <td>
                        <p></p>
                      </td>
                    </tr>
                    <tr className="border-bottom">
                      <td className="border-end text-danger">
                        <p> March </p>
                      </td>
                      <td>
                        <p></p>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={2} className="px-3">
                        <div className="d-flex justify-content-between">
                          <div className="text-start">
                            <h6 className="font-16">Total</h6>
                            <h6 className="font-16">Target</h6>
                            <h6 className="font-16">Balance Target</h6>
                          </div>
                          <div className="text-end">
                            <h6 className="text-danger font-16">#REF!</h6>
                            <h6 className="text-danger font-16">
                              1,500,000,000.00
                            </h6>
                            <h6 className="text-danger font-16">#REF!</h6>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="card table-border-radius">
                <Table responsive>
                  <thead>
                    <tr className="bg-table-black">
                      <th colSpan={2} className="text-light">
                         Deduction
                      </th>
                    </tr>
                    <tr className="bg-white">
                      <th>Month</th>
                      <th>Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="border-bottom">
                      <td className="border-end">
                        <p> April </p>
                      </td>
                      <td onClick={() => setShowDeductionModal(true)}>
                        <p className="text-danger cursor-pointer"> #REF! </p>
                      </td>
                    </tr>
                    <tr className="border-bottom">
                      <td className="border-end text-danger">
                        <p> May </p>
                      </td>
                      <td>
                        <p> #REF! </p>
                      </td>
                    </tr>
                    <tr className="border-bottom">
                      <td className="border-end text-danger">
                        <p> June </p>
                      </td>
                      <td>
                        <p> #REF! </p>
                      </td>
                    </tr>
                    <tr className="border-bottom">
                      <td className="border-end text-danger">
                        <p> July </p>
                      </td>
                      <td>
                        <p> #REF! </p>
                      </td>
                    </tr>
                    <tr className="border-bottom">
                      <td className="border-end text-danger">
                        <p> August </p>
                      </td>
                      <td>
                        <p> #REF! </p>
                      </td>
                    </tr>
                    <tr className="border-bottom">
                      <td className="border-end text-danger">
                        <p> September </p>
                      </td>
                      <td>
                        <p>&nbsp;</p>
                      </td>
                    </tr>
                    <tr className="border-bottom">
                      <td className="border-end text-danger">
                        <p> October </p>
                      </td>
                      <td>
                        <p></p>
                      </td>
                    </tr>
                    <tr className="border-bottom">
                      <td className="border-end text-danger">
                        <p> November </p>
                      </td>
                      <td>
                        <p></p>
                      </td>
                    </tr>
                    <tr className="border-bottom">
                      <td className="border-end text-danger">
                        <p> December </p>
                      </td>
                      <td>
                        <p></p>
                      </td>
                    </tr>
                    <tr className="border-bottom">
                      <td className="border-end text-danger">
                        <p> January </p>
                      </td>
                      <td>
                        <p></p>
                      </td>
                    </tr>
                    <tr className="border-bottom">
                      <td className="border-end text-danger">
                        <p> February </p>
                      </td>
                      <td>
                        <p></p>
                      </td>
                    </tr>
                    <tr className="border-bottom">
                      <td className="border-end text-danger">
                        <p> March </p>
                      </td>
                      <td>
                        <p></p>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={2} className="px-3">
                        <div className="d-flex justify-content-between">
                          <div className="text-start">
                            <h6 className="font-16">Total</h6>
                            <h6 className="font-16">Target</h6>
                            <h6 className="font-16">Balance Target</h6>
                          </div>
                          <div className="text-end">
                            <h6 className="text-danger font-16">#REF!</h6>
                            <h6 className="text-danger font-16">
                              1,500,000,000.00
                            </h6>
                            <h6 className="text-danger font-16">#REF!</h6>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </div>
          
          </div>
        </div>
      </div>
      <DashboardDeductionsModal show={showDeductionModal} setShow={setShowDeductionModal}/>
    </div>
  );
}
export default BackOfficeDashboard;
