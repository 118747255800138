import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  financialTargetSchema,
  historicProjectSchema,
} from "../schemas/otherForms";
import { Formik, useFormik } from "formik";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { selectDropdowns } from "../redux/features/allDropdownSlice";

function AddHistoricProjectModal({
  show,
  handleClose,
  getTargetList,
  targetId,
  updateDataStore,
}) {
  const baseUrl = process.env.REACT_APP_DEV_API_KEY_NEW;
  const user = JSON.parse(localStorage.getItem("profile"));
  const user_id = user?.response?.userDetails?.id;
  const getToken = user?.response?.token;
  const bearer_token = `Bearer ${getToken}`;
  const [itemId, setItemId] = useState(null);
  const [yearError, setYearError] = useState(null);
  const [spinner, setSpinner] = useState(false);
  const allDropdowns = useSelector(selectDropdowns);
  const [serverSideValdiation, setServerSideValidation] = useState("");

  const initialValues = {
    project_title: "",
    financial_year: "",
    project_category: "",
    lo_poa_no: "",
    project_value: "",
    status: "",
  };

  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
    resetForm,
    setValues,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: historicProjectSchema,
    onSubmit: (values) => {
      if (targetId) {
        updateData(values);
      } else {
        addTarget(values);
      }
    },
  });

  function addTarget(value) {
    setSpinner(true);
    let url = baseUrl + "storeHistoricalProjectData";
    let data = { ...value, user_id };
    axios
      .post(url, data, {
        headers: {
          Authorization: bearer_token,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setSpinner(false);
        if (response.status === 200) {
          getTargetList();
          handleClose();
          toast.success("Target added successfully");
        }
      })
      .catch((error) => {
        console.log(error);
        if (error?.response?.status === 422) {
          setServerSideValidation(error?.response?.data?.response);
        }
        setSpinner(false);
        toast.error("Something went wrong, please try again later");
        if (error.response.data.message === "Error validation") {
          setYearError(error.response.data.response);
        }
      });
  }

  function updateData(value) {
    setSpinner(true);
    let url = baseUrl + "storeHistoricalProjectData";
    let data = { ...value, user_id, edit_historical_project: targetId };
    axios
      .post(url, data, {
        headers: {
          Authorization: bearer_token,
        },
      })
      .then((response) => {
        setSpinner(false);
        if (response.status === 200) {
          getTargetList();
          handleClose();
          toast.success("Data Updated successfully");
        }
      })
      .catch((error) => {
        setSpinner(false);
        toast.error("Something went wrong, please try again later");
      });
  }

  useEffect(() => {
    resetForm();
    setYearError(null);
    if (targetId) {
      setValues(updateDataStore);
    }
  }, [show]);

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title className="text-uppercase mb-0">Add Project</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="project_title">
              <Form.Label>Project Title</Form.Label>
              <Form.Control
                type="text"
                className="border-secondary"
                name="project_title"
                placeholder="Project Title"
                value={values.project_title}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <p className="text-danger">
                {errors.project_title && touched.project_title
                  ? errors.project_title
                  : serverSideValdiation?.project_title
                  ? serverSideValdiation?.project_title[0]
                  : null}
              </p>
            </Form.Group>
            <Form.Group className="mb-3" controlId="financial_year">
              <Form.Label>Financial Year</Form.Label>
              <select
                className="form-control border-secondary"
                name="financial_year"
                value={values.financial_year}
                onChange={handleChange}
              >
                <option value="" disabled>
                  Select a Target Year
                </option>
                <option value="2013-2014">2013-2014</option>
                <option value="2014-2015">2014-2015</option>
                <option value="2015-2016">2015-2016</option>
                <option value="2016-2017">2016-2017</option>
                <option value="2017-2018">2017-2018</option>
                <option value="2018-2019">2018-2019</option>
                <option value="2019-2020">2019-2020</option>
                <option value="2020-2021">2020-2021</option>
                <option value="2021-2022">2021-2022</option>
                <option value="2022-2023">2022-2023</option>
              </select>
              <p className="text-danger">
                {errors.financial_year && touched.financial_year
                  ? errors.financial_year
                  : serverSideValdiation?.financial_year
                  ? serverSideValdiation?.financial_year[0]
                  : null}
              </p>
            </Form.Group>
            <Form.Group className="mb-3" controlId="lo_poa_no">
              <Form.Label>LO POA No.</Form.Label>
              <Form.Control
                type="text"
                className="border-secondary"
                name="lo_poa_no"
                placeholder="LO POA No."
                value={values.lo_poa_no}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <p className="text-danger">
                {errors.lo_poa_no && touched.lo_poa_no
                  ? errors.lo_poa_no
                  : serverSideValdiation?.lo_poa_no
                  ? serverSideValdiation?.lo_poa_no[0]
                  : null}
              </p>
            </Form.Group>
            <Form.Group className="mb-3" controlId="project_category">
              <Form.Label>Project Category</Form.Label>
              <select
                className="form-control border-secondary"
                placeholder="Enter Project Category"
                name="project_category"
                value={values.project_category}
                onChange={handleChange}
                onBlur={handleBlur}
              >
                <option>Select</option>
                {allDropdowns?.projectCategoryList?.map((element) => (
                  <option value={element.id}>{element.name}</option>
                ))}
              </select>
              <p className="text-danger m-0">
                {touched.project_category && errors.project_category
                  ? errors.project_category
                  : serverSideValdiation?.project_category
                  ? serverSideValdiation?.project_category[0]
                  : null}
              </p>
            </Form.Group>
            <Form.Group className="mb-3" controlId="project_value">
              <Form.Label>Project Value</Form.Label>
              <Form.Control
                type="text"
                className="border-secondary"
                name="project_value"
                placeholder="LO POA Value"
                value={values.project_value}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <p className="text-danger">
                {errors.project_value && touched.project_value
                  ? errors.project_value
                  : serverSideValdiation?.project_value
                  ? serverSideValdiation?.project_value[0]
                  : null}
              </p>
            </Form.Group>
            <Form.Group className="mb-3" controlId="status">
              <Form.Label>Status</Form.Label>
              <select
                type="text"
                className="form-control border-secondary"
                name="status"
                placeholder="Status"
                value={values.status}
                onChange={handleChange}
                onBlur={handleBlur}
              >
                <option>Select</option>
                <option value={1}>Completed</option>
                <option value={2}>Pending</option>
                <option value={3}>Cancelled</option>
              </select>
              <p className="text-danger">
                {errors.status && touched.status
                  ? errors.status
                  : serverSideValdiation?.status
                  ? serverSideValdiation?.status[0]
                  : null}
              </p>
            </Form.Group>

            {spinner ? (
              <div className="">
                <i className="fa fa-spinner fa-spin fa-3x text-danger me-3 mt-3"></i>
                <p>Wait...</p>
              </div>
            ) : (
              <div className="mt-3 d-flex gap-2 justify-content-end">
                <Button variant="secondary" type="button" onClick={handleClose}>
                  Cancel
                </Button>
                <Button variant="primary" type="submit">
                  {targetId ? "Update" : "Add"}
                </Button>
              </div>
            )}
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default AddHistoricProjectModal;
