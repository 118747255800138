import axios from "axios";

const apiurl = process.env.REACT_APP_DEV_API_KEY_NEW;
const API = axios.create({
  baseURL: apiurl,
});

API.interceptors.request.use((req) => {
  if (localStorage.getItem("profile")) {
    req.headers.Authorization = `Bearer ${
      JSON.parse(localStorage.getItem("profile"))?.response?.token
    }`;
  }
  return req;
});

export const addGRN = (data) => API.post("/addpurchaseordergrn", data);
export const getGrnListing = (data) => API.post("/getGrnListing", data);
export const updateGrn = (data) => API.post("/updatePurchaseOrderGrn", data);
export const addDeliveryChallanApi = (data) =>
  API.post("/add-delivery-challan", data);
export const deliveryChallanListApi = (data) =>
  API.post("/list-delivery-challan", data);
export const UpdateDeliveryChallanApi = (data) =>
  API.post("/update-delivery-challan", data);
export const getInventoryListingApi = (data) =>
  API.post("/listinventoryitems", data);
export const getInventoryLogsListingApi = (data) =>
  API.post("/listinventorylogs", data);
export const getRequsitionListApi = (data) =>
  API.post("/listrequisitionform", data);
export const getDeliveryChallanPdf = (data) =>
  API.post("/generate-delivery-challan-pdf", data);
export const addTaxInvoiceApi = (data) => API.post("/add-tax-invoice", data);
export const unnatiProjectDropDownApi = (data) =>
  API.post("/untiprojectlistupdated", data);
export const getTaxInvoiceApi = (data) =>
  API.get(
    `/tax-invoice-list?page=${data?.page}&startDate=${data?.startDate}&endDate=${data?.endDate}&search=${data?.search}`
  );
export const updateTaxInvoiceApi = (data) =>
  API.post(`/update-tax-invoice`, data);
export const railwayZoneList = (data) => API.post(`/getrailwayzonelist`, data);
export const addCustomerApi = (data) => API.post(`/add-customer-details`, data);
export const getCustomerApi = (data) =>
  API.get(`/customer-details-list?page=${data?.page}&search=${data?.search}&railway_zone=${data?.railway_zone}`);
export const updateCustomerApi = (data) =>
  API.post(`/update-customer-details`, data);
export const customerDetailBulkApi = (data) =>
  API.post(`/add-customer-details-excel`, data);
