import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { errorsMessage } from "../../../../constant";
import axios from "axios";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { selectDropdowns } from "../../../../redux/features/allDropdownSlice";
import { getProjectCoachList } from "../../../../redux/api";
import WarrantyTracker from "./WarrantyTracker";
import addwarrantyTrackerSchema from "./WarrantyTrackerSchema";

function AddWarrantyTracker() {
  const [spinner, setSpinner] = useState(false);
  const [validationError, setValidationError] = useState(false);
  const user = JSON.parse(localStorage.getItem("profile"));
  const user_id = user?.response?.userDetails?.id;
  const baseUrl = process.env.REACT_APP_DEV_API_KEY_NEW;
  const getToken = JSON.parse(localStorage.getItem("profile"))?.response?.token;
  const bearer_token = `Bearer ${getToken}`;
  const config = { headers: { Authorization: bearer_token } };
  const navigate = useNavigate();
  const allDropdowns = useSelector(selectDropdowns);
  const [projectDropdown, setProjectDropdown] = useState([]);
  const initialValue = {
    WarrantyRejectionAdviceNo: "",
    WarrantyRejectionAdviceDate: "",
    ComplaintNo: "",
    CoachNo_ProductSN: "",
    DefectType: "",
    Qty: "",
    Status: "",
    WC_CloserDate_AsPerCRN: "",
    AssignedTo: "",
    ClaimAmount_ToDeductFromBill: "",
    ConsigneeToContact: "",
    ContactNo: "",
    LastDateToAttend: "",
    WcCloserCrnNo: "",
    ReportingRly: "",
    PoNo: "",
    WarrantyPeriod: "",
    Make: "",
    DescOfDefect: "",
    DeductionStatus: "",
    deductionStatus: "1",
    WRAdviceFile: "",
    WCCloserCRNFile: "",
    ClaimAmountTBDFile: "",
    DeductionStatusDate: "",
    deductionDetails: "",
    ComplaintDate: "",
    CoachType: "",
    PoDescription: "",
    AttendAddress: "",
  };
  function addWarrantyOnServer(values) {
    const apiUrl = baseUrl + "storewarrantytraker";
    const data = {
      user_id,
      ...values,
    };
    setSpinner(true);
    axios
      .post(apiUrl, data, config)
      .then((response) => {
        if (response.status === 200) {
          resetForm();
          toast.success("Form submit successfully");
          navigate(-1);
        }
      })
      .catch((error) => {
        setValidationError(error?.response?.data?.response);
        errorsMessage(error?.response?.status);
      })
      .finally(() => {
        setSpinner(false);
      });
  }
  function getProjectDropDown() {
    getProjectCoachList({ user_id })
      .then((res) => {
        setProjectDropdown(res?.data?.response?.data);
      })
      .catch((err) => {
        errorsMessage(err?.response?.status);
      })
      .finally(() => {});
  }

  useEffect(() => {
    getProjectDropDown();
  }, []);
  const {
    values,
    handleSubmit,
    handleChange,
    resetForm,
    errors,
    touched,
    setFieldValue,
    handleBlur,
  } = useFormik({
    initialValues: initialValue,
    validationSchema: addwarrantyTrackerSchema,
    onSubmit: (values) => {
      addWarrantyOnServer(values);
    },
  });
  useEffect(() => {
    const parsedDate = new Date(values.ComplaintDate);

    if (isNaN(parsedDate.getTime())) {
      // Handle invalid date here, if necessary
      return;
    }
    const futureDate = new Date(parsedDate);
    futureDate.setDate(futureDate.getDate() + 60);
    const futureDateString = futureDate.toISOString().split("T")[0];
    setFieldValue("LastDateToAttend", futureDateString);
  }, [values.ComplaintDate]);
  const getAttachmentBase64 = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const base64 = reader.result;
        setFieldValue(event.target.name, base64);
      };
    }
  };
  return (
    <div>
      <WarrantyTracker
        handleChange={handleChange}
        values={values}
        handleSubmit={handleSubmit}
        errors={errors}
        touched={touched}
        handleBlur={handleBlur}
        projectDropdown={projectDropdown}
        validationError={validationError}
        allDropdowns={allDropdowns}
        spinner={spinner}
        mode="add"
        setFieldValue={setFieldValue}
        getAttachmentBase64={getAttachmentBase64}
      />
    </div>
  );
}

export default AddWarrantyTracker;
