import React from "react";
import { Spinner } from "react-bootstrap";

function Loader() {
  return (
    <div
      className="d-flex align-items-center justify-content-center pb-5"
      style={{ height: "70vh" }}
    >
      <Spinner animation="grow" variant="danger" />
      <Spinner animation="grow" variant="danger" />
      <Spinner animation="grow" variant="danger" />
    </div>
  );
}

export default Loader;
