export const getCurrentQuarter = (date) => {
    const month = date.getMonth() + 1;
    if (month >= 4 && month <= 6) {
      return "quarter_1"; // Q1: April - June
    } else if (month >= 7 && month <= 9) {
      return "quarter_2"; // Q2: July - September
    } else if (month >= 10 && month <= 12) {
      return "quarter_3"; // Q3: October - December
    } else {
      return "quarter_4"; // Q4: January - March
    }
  };


 export const getCurrentFinancialYear = () => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth() + 1; // getMonth() is zero-based
  
    // If the current month is before April, the financial year starts from the previous year
    const startYear = currentMonth < 4 ? currentYear - 1 : currentYear;
    return `${startYear}-${(startYear + 1).toString().slice(2)}`; // Format: "YYYY-YY"
  };