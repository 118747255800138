import React from "react";
import "rsuite/DateRangePicker/styles/index.css";
import DateRangePicker from "rsuite/DateRangePicker";

const TenderDatePicker = ({ handleChange, startDate = null, endDate = null , setFilters , filters}) => {
  function formatDateToLocal(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  function parseDate(dateString) {
    return dateString ? new Date(dateString) : null;
  }

  const handleDateChange = (value) => {
    if (!value) {
      // handleChange("dateFilter", ["", ""]);
      return;
    }
    const formattedStartDate = formatDateToLocal(value[0]);
    const formattedEndDate = formatDateToLocal(value[1]);
    // handleChange("dateFilter", [formattedStartDate, formattedEndDate]);
    setFilters({
      ...filters,
      startDate: formattedStartDate,
      endDate: formattedEndDate,
    })
  };

  // Parse the startDate and endDate into Date objects
  const parsedStartDate = parseDate(startDate);
  const parsedEndDate = parseDate(endDate);

  return (
    <DateRangePicker
      placeholder="Select Date Range"
      placement="bottomEnd"
      style={{
        border: "solid hsl(0, 0%, 80%) 1px",
        borderRadius: "0.25rem",
      }}
      value={[parsedStartDate, parsedEndDate]} // Pass Date objects
      onChange={handleDateChange}
    />
  );
};

export default TenderDatePicker;
