export default function convertValue(value) {
  if (typeof value !== "number" || isNaN(value) || value < 0) {
    return "Invalid value";
  }
  if (value >= 1e7) {
    return (value / 1e7).toFixed(2) + " crore";
  } else if (value >= 1e5) {
    return (value / 1e5).toFixed(2) + " lakh";
  } else if (value >= 1e3) {
    return (value / 1e3).toFixed(2) + "k";
  } else {
    return value.toString();
  }
}
