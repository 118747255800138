import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { errorsMessage } from "../../../../constant";
import axios from "axios";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { selectDropdowns } from "../../../../redux/features/allDropdownSlice";
import { getProjectCoachList } from "../../../../redux/api";
import WarrantyTracker from "./WarrantyTracker";
import addwarrantyTrackerSchema from "./WarrantyTrackerSchema";
const EditWarrantyTracker = () => {
  const [spinner, setSpinner] = useState(false);
  const [validationError, setValidationError] = useState(false);
  const user = JSON.parse(localStorage.getItem("profile"));
  const user_id = user?.response?.userDetails?.id;
  const baseUrl = process.env.REACT_APP_DEV_API_KEY_NEW;
  const getToken = JSON.parse(localStorage.getItem("profile"))?.response?.token;
  const bearer_token = `Bearer ${getToken}`;
  const config = { headers: { Authorization: bearer_token } };
  const navigate = useNavigate();
  const allDropdowns = useSelector(selectDropdowns);
  const [projectDropdown, setProjectDropdown] = useState([]);
  const { state } = useLocation();
  const attachUrl = process.env.REACT_APP_BASE_URL;

  const initialValue = {
    WarrantyRejectionAdviceNo: state?.WarrantyRejectionAdviceNo,
    WarrantyRejectionAdviceDate: state?.WarrantyRejectionAdviceDate,
    ComplaintNo: state?.ComplaintNo,
    ToAttendAt: state?.ToAttendAt,
    CoachNo_ProductSN: state?.CoachNo_ProductSN,
    DefectType: state?.DefectType,
    Qty: state?.Qty,
    Status: state?.Status,
    WC_CloserDate_AsPerCRN: state?.WC_CloserDate_AsPerCRN,
    AssignedTo: state?.AssignedTo,
    ClaimAmount_ToDeductFromBill: state?.ClaimAmount_ToDeductFromBill,
    ConsigneeToContact: state?.ConsigneeToContact,
    ContactNo: state?.ContactNo,
    LastDateToAttend: state?.LastDateToAttend,
    WcCloserCrnNo: state?.WcCloserCrnNo,
    ReportingRly: state?.ReportingRly,
    PoNo: state?.PoNo,
    WarrantyPeriod: state?.WarrantyPeriod,
    Make: state?.Make,
    DescOfDefect: state?.DescOfDefect,
    DeductionStatus: state?.DeductionStatus,
    WRAdviceFile: state?.WRAdviceFile ? attachUrl + state?.WRAdviceFile : "",
    ClaimAmountTBDFile: state?.ClaimAmountTBDFile
      ? attachUrl + state?.ClaimAmountTBDFile
      : "",
    DeductionStatusDate: state?.DeductionStatusDate,
    JointLetterCrnProof: state?.JointLetterCrnProof
      ? attachUrl + state?.JointLetterCrnProof
      : "",
    deductionDetails: state?.deductionDetails,
    PoDescription: state?.PoDescription,
    CoachType: state?.CoachType,
    ComplaintDate: state?.ComplaintDate,
    AttendAddress: state?.AttendAddress,
  };

  function addWarrantyOnServer(values) {
    const apiUrl = baseUrl + "updatewarrantytraker";
    const data = {
      user_id,
      ...values,
      trackerId: state?.id,
    };
    setSpinner(true);
    axios
      .post(apiUrl, data, config)
      .then((response) => {
        if (response.status === 200) {
          toast.success("Warranty updated successfully");
          navigate(-1);
        }
      })
      .catch((error) => {
        setValidationError(error?.response?.data?.response);
        errorsMessage(error?.response?.status);
      })
      .finally(() => {
        setSpinner(false);
      });
  }
  function getProjectDropDown() {
    getProjectCoachList({ user_id })
      .then((res) => {
        setProjectDropdown(res?.data?.response?.data);
      })
      .catch((err) => {
        errorsMessage(err?.response?.status);
      })
      .finally(() => {
        // setLoading(false);
      });
  }
  useEffect(() => {
    getProjectDropDown();
  }, []);
  const getAttachmentBase64 = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const base64 = reader.result;
        setFieldValue(event.target.name, base64);
      };
    }
  };
  const {
    values,
    handleSubmit,
    handleChange,
    resetForm,
    errors,
    touched,
    setFieldValue,
    handleBlur,
  } = useFormik({
    initialValues: initialValue,
    validationSchema: addwarrantyTrackerSchema,
    onSubmit: (values) => {
      addWarrantyOnServer(values);
    },
  });

  return (
    <div>
      <WarrantyTracker
        handleChange={handleChange}
        values={values}
        handleSubmit={handleSubmit}
        errors={errors}
        touched={touched}
        handleBlur={handleBlur}
        projectDropdown={projectDropdown}
        validationError={validationError}
        allDropdowns={allDropdowns}
        spinner={spinner}
        mode="edit"
        setFieldValue={setFieldValue}
        getAttachmentBase64={getAttachmentBase64}
      />
    </div>
  );
};

export default EditWarrantyTracker;
