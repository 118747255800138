import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import React, { useState, useEffect } from "react";
import axios from "axios";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { selectDropdowns } from "../redux/features/allDropdownSlice";

function AddProjectTargetData({
  show,
  handleClose,
  getTargetList,
  targetId,
  updateDataStore,
}) {
  const baseUrl = process.env.REACT_APP_DEV_API_KEY_NEW;
  const user = JSON.parse(localStorage.getItem("profile"));
  const user_id = user?.response?.userDetails?.id;
  const getToken = user?.response?.token;
  const bearer_token = `Bearer ${getToken}`;
  const [yearError, setYearError] = useState(null);
  const [spinner, setSpinner] = useState(false);
  const [projectCategoryList, setProjectCategoryList] = useState([]);

  const initialValues = {
    project_category: "",
    financial_year: "",
    target_amount: "",
  };
  function getProjectCategory() {
    const data = {
      user_id,
    };
    axios
      .post(baseUrl + "getprojectcategorylist", data, {
        headers: {
          Authorization: bearer_token,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setProjectCategoryList(response.data.response.ProjectCategoryList);
      })
      .catch((error) => {
        toast.error("Something went wrong");
      });
  }

  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
    resetForm,
    setValues,
  } = useFormik({
    initialValues: initialValues,
    //  validationSchema: financialTargetSchema,
    onSubmit: (values) => {
      if (targetId) {
        updateData(values);
      } else {
        addTarget(values);
      }
    },
  });

  function addTarget(value) {
    setSpinner(true);
    let url = baseUrl + "storeHistoricalTargets";
    let data = { ...value, user_id };
    axios
      .post(url, data, {
        headers: {
          Authorization: bearer_token,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setSpinner(false);
        if (response.status === 200) {
          getTargetList();
          handleClose();
          toast.success("Target added successfully");
        }
      })
      .catch((error) => {
        setSpinner(false);
        toast.error("Something went wrong, please try again later");
        if (error.response.data.message === "Error validation") {
          setYearError(error.response.data.response);
        }
      });
  }

  useEffect(() => {
    resetForm();
    setYearError(null);
    if (targetId) {
      setValues(updateDataStore);
    }
  }, [show]);
  useEffect(() => {
    getProjectCategory();
  }, []);

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title className="text-uppercase mb-0">Add Project</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="financial_year">
              <Form.Label>Financial Year</Form.Label>
              <select
                className="form-control border-secondary"
                name="financial_year"
                value={values.financial_year}
                onChange={handleChange}
              >
                <option value="" disabled>
                  Select a Target Year
                </option>
                <option value="2013-2014">2013-2014</option>
                <option value="2014-2015">2014-2015</option>
                <option value="2015-2016">2015-2016</option>
                <option value="2016-2017">2016-2017</option>
                <option value="2017-2018">2017-2018</option>
                <option value="2018-2019">2018-2019</option>
                <option value="2019-2020">2019-2020</option>
                <option value="2020-2021">2020-2021</option>
                <option value="2021-2022">2021-2022</option>
                <option value="2022-2023">2022-2023</option>
              </select>
              <p className="text-danger">
                {errors.financial_year && touched.financial_year
                  ? errors.financial_year
                  : null}
              </p>
            </Form.Group>
            <Form.Group className="mb-3" controlId="project_category">
              <Form.Label>Project Category</Form.Label>
              <select
                type="text"
                className="form-control border-secondary"
                name="project_category"
                placeholder="Project Category"
                value={values.project_category}
                onChange={handleChange}
                onBlur={handleBlur}
              >
                <option>Select</option>
                {projectCategoryList.map((element) => (
                  <option value={element?.id} key={element?.id}>
                    {element?.name}
                  </option>
                ))}
              </select>
              <p className="text-danger">
                {errors.project_category && touched.project_category
                  ? errors.project_category
                  : null}
              </p>
            </Form.Group>
            <Form.Group className="mb-3" controlId="target_amount">
              <Form.Label>Target Amount</Form.Label>
              <Form.Control
                type="number"
                className="border-secondary"
                name="target_amount"
                placeholder="Target Amount"
                value={values.target_amount}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <p className="text-danger">
                {errors.target_value && touched.target_value
                  ? errors.target_value
                  : null}
              </p>
            </Form.Group>

            {spinner ? (
              <div className="">
                <i className="fa fa-spinner fa-spin fa-3x text-danger me-3 mt-3"></i>
                <p>Wait...</p>
              </div>
            ) : (
              <div className="mt-3 d-flex gap-2 justify-content-end">
                <Button variant="secondary" type="button" onClick={handleClose}>
                  Cancel
                </Button>
                <Button variant="primary" type="submit">
                  {targetId ? "Update" : "Add"}
                </Button>
              </div>
            )}
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default AddProjectTargetData;
