import React, { useEffect, useState } from "react";
import { railwayZoneList } from "../commonComponents/storeApi";
import { errorsMessage } from "../../constant";

function CustomerFilter({ filters, handleFilterChange }) {
  const [railwayZone, setRailwayZone] = useState([]);
  const userProfile = JSON.parse(localStorage.getItem("profile"));
  const user_id = userProfile?.response?.userDetails?.id;
  function getRailwayZoneDropDown() {
    railwayZoneList({ user_id })
      .then((res) => {
        setRailwayZone(res?.data?.response?.RailwayZoneList);
      })
      .catch((err) => {
        if (err) {
          errorsMessage(err?.response?.status);
        }
      })
      .finally(() => {
        // setLoading(false);
      });
  }
  useEffect(() => {
    getRailwayZoneDropDown();
  }, []);
  return (
    <div className="col-md-6 ">
      <div className="row">
        <div className="col-5">
          <input
            className="form-control "
            placeholder="Search By Name and GSTIN"
            name="search"
            onChange={handleFilterChange}
            value={filters?.search}
          />
        </div>
        <div className="col-5">
          <select
            className="form-control"
            name="railway_zone"
            value={filters?.railway_zone}
            onChange={handleFilterChange}
          >
            <option value={""}>Select</option>
            {railwayZone?.map((zone) => (
              <option key={zone?.id} value={zone?.id}>
                {zone?.name}
              </option>
            ))}
          </select>
        </div>
      </div>
    </div>
  );
}

export default CustomerFilter;
