import React, { useEffect, useState } from "react";
import LotsLogsTable from "./LotsLogsTable";
import { useParams } from "react-router-dom";
import { getLotsLogsList } from "../../../redux/api";
import { errorsMessage } from "../../../constant";
import LotsLogsDetailPageBreadCrums from "./LotsLogsDetailPageBreadCrums";
import { Spinner } from "react-bootstrap";

function ViewLotsLogs() {
  const params = useParams();
  const [logsListBefore, setLogsListBefore] = useState([]);
  const [loader, setLoader] = useState(false);

  function lotsLogsList() {
    setLoader(true);
    getLotsLogsList({ project_id: params.id })
      .then((res) => {
        const check = Array.isArray(res.data.response.lotAmendmentLogsList);
        setLogsListBefore(check ? res.data.response.lotAmendmentLogsList : []);
      })
      .catch((err) => {
        errorsMessage(err?.response?.status);
      })
      .finally(() => {
        setLoader(false);
      });
  }
  useEffect(() => lotsLogsList(), []);
  return (
    <div className="page-wrapper">
      <div className="page-content-tab ">
        <div className="container-fluid ">
          <LotsLogsDetailPageBreadCrums id={params.id} />
          {loader ? (
            <div
              className="d-flex align-items-center justify-content-center"
              style={{ height: "70vh" }}
            >
              <Spinner animation="grow" variant="danger" />
              <Spinner animation="grow" variant="danger" />
              <Spinner animation="grow" variant="danger" />
            </div>
          ) : (
            <div className="row">
              {logsListBefore?.length ? (
                logsListBefore?.map((beforeData, index) => (
                  <>
                    <div key={index} className="col-md-6">
                      {
                        <LotsLogsTable
                          data={beforeData.before}
                          text={"Previous"}
                        />
                      }
                    </div>
                    <div key={index} className="col-md-6">
                      <LotsLogsTable data={beforeData.after} text={"Update"} />
                    </div>
                  </>
                ))
              ) : (
                <h4 className="text-center mt-2">
                  There is no logs for this project
                </h4>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default ViewLotsLogs;
