import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import CommonInput from "./CommonInput";
import CommonOptions from "./CommonOptions";
import { tdcValidationSchema } from "./TdcEmdSchema";
import { useParams } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { FaEye } from "react-icons/fa";
import Modals from "./Modal";

function TdcDetail({ tdcData, renderState, setRenderState }) {
  const param = useParams();
  const project_id = Number(param.id);
  const [spinner, setSpinner] = useState(false);
  const [data, setData] = useState("");
  const [modalShow, setModalShow] = useState(false);
  const initialValues = {
    tdc_toPay: 0,
    tdc_paidTransactionNo: "",
    tdc_paidTransactionDate: "",
    tdc_paidExempted: "",
    tdc_attachement: [],
  };

  function saveDataOnserver(url, value, project_id) {
    const apiurl = process.env.REACT_APP_DEV_API_KEY_NEW;
    const user = JSON.parse(localStorage.getItem("profile"));
    const user_id = user?.response?.userDetails?.id;
    setSpinner(true);
    const getToken = user.response.token;
    const bearer_token = `Bearer ${getToken}`;
    axios
      .post(
        apiurl + url,
        { ...value, user_id, project_id },
        {
          headers: {
            Authorization: bearer_token,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        if (response.data.success) {
          setSpinner(false);
          setRenderState(!renderState);
          toast.success("Data added successfully");
        }
      })
      .catch((error) => {
        setSpinner(false);
        toast.error("Something went wrong");
      });
    return spinner;
  }

  const handleInputChange = (event) => {
    const updatedValue = event.target.value;
    if (/^\d*\.?\d*$/.test(updatedValue)) {
      handleChange(event);
    }
  };

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    Promise.all(files.map((file) => readFileAsBase64(file))).then(
      (filesArray) => {
        setFieldValue("tdc_attachement", filesArray);
      }
    );
  };

  const readFileAsBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = () => {
        resolve({ fileName: file.name, base64: reader.result });
      };

      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(file);
    });
  };

  const {
    values,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    touched,
    setFieldValue,
    setValues,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: tdcValidationSchema,
    onSubmit: (values) => {
      saveDataOnserver("storeuntiprojecttdc", values, project_id);
    },
  });
  useEffect(() => {
    if (tdcData) {
      setValues({ ...tdcData, tdc_attachement: [] });
      setData(tdcData);
    }
  }, [tdcData]);
  useEffect(() => {
    if (
      values.tdc_paidExempted === "Not Applicable" ||
      values.tdc_paidExempted === "Exempted"
    ) {
      setValues((prevValues) => ({
        ...prevValues,
        tdc_toPay: 0,
        tdc_paidTransactionNo: "",
        tdc_paidTransactionDate: "",
        tdc_attachement: [],
      }));
    }
  }, [values.tdc_paidExempted]);

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div
          className="row"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <div className="form-group mb-12 col">
            <CommonOptions
              handleChange={handleChange}
              values={values.tdc_paidExempted}
              name="tdc_paidExempted"
              labelValue="Paid/Exempted"
              handleBlur={handleBlur}
            />
            <p className="text-danger">
              {touched.tdc_paidExempted && errors.tdc_paidExempted
                ? errors.tdc_paidExempted
                : null}
            </p>
          </div>
          <div className="form-group mb-12 col">
            <CommonInput
              handleChange={handleInputChange}
              values={values.tdc_toPay}
              type="text"
              name="tdc_toPay"
              labelValue="Amount"
              handleBlur={handleBlur}
              touched={touched}
              errors={errors}
              onBlur={handleBlur}
              disabled={
                values.tdc_paidExempted === "Exempted" ||
                values.tdc_paidExempted === "Not Applicable"
              }
            />
          </div>
          <div className="form-group mb-12 col ">
            <CommonInput
              handleChange={handleChange}
              values={values.tdc_paidTransactionNo}
              type="text"
              name="tdc_paidTransactionNo"
              labelValue="Transaction No."
              handleBlur={handleBlur}
              touched={touched}
              errors={errors}
              placeHolder={"TDC Paid Transaction no."}
              disabled={
                values.tdc_paidExempted === "Exempted" ||
                values.tdc_paidExempted === "Not Applicable"
              }
            />
          </div>

          <div className="form-group mb-12 col ">
            <CommonInput
              handleChange={handleChange}
              values={values.tdc_paidTransactionDate}
              type="date"
              name="tdc_paidTransactionDate"
              labelValue="Transaction Date"
              handleBlur={handleBlur}
              touched={touched}
              errors={errors}
              disabled={
                values.tdc_paidExempted === "Exempted" ||
                values.tdc_paidExempted === "Not Applicable"
              }
            />
          </div>
          <div className="form-group mb-12 col ">
            <CommonInput
              handleChange={handleFileChange}
              type="file"
              name="tdc_attachement"
              labelValue="Attachment"
              handleBlur={handleBlur}
              touched={touched}
              errors={errors}
              disabled={
                values.tdc_paidExempted === "Exempted" ||
                values.tdc_paidExempted === "Not Applicable"
              }
              defaultValues={values.tdc_attachement}
            />
          </div>
          <div className="form-group mb-12 col ">
            <label htmlFor="" className="form-label">
              Attachment Preview
            </label>

            <p
              style={{ cursor: "pointer", textAlign: "center", width: "50px" }}
              onClick={() => setModalShow(true)}
            >
              <FaEye />
            </p>
            <Modals
              show={modalShow}
              onHide={() => setModalShow(false)}
              data={data.tdc_attachement}
              renderState={renderState}
              setRenderState={setRenderState}
              url={"setdeletetdcattechment"}
            />
          </div>
        </div>{" "}
        <div className="text-end mt-3">
          {spinner ? (
            <div className="">
              <i className="fa fa-spinner fa-spin fa-3x text-danger me-3 mt-3"></i>
              <p>Updating ...</p>
            </div>
          ) : (
            <button className="btn btn-danger">Save</button>
          )}
        </div>
      </form>
    </div>
  );
}

export default TdcDetail;
